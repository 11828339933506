import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { influencerDetails } from "../../features/influencerRequestSlice/influencerRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../utils/dateUtils";
import { format } from "date-fns";
import useDecodedToken from "../../features/utils/hooks/useDecodedToken";
import { formatAmount } from "../../utils/amountFormat";
// import dayjs from 'dayjs';

const DetailClients = (props) => {
  const decodeToken = useDecodedToken();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const selectedData = location.state;
  const { influencerDataDetails, status, message } = useSelector(
    (state) => state.requestInfluencerDetails
  );
  const [toastDisplayed, setToastDisplayed] = useState(false);
  const [infDetails, setInfDetails] = useState("");

  useEffect(() => {
    console.log("decodeToken ==>", decodeToken);

  }, [decodeToken])

  useEffect(() => {
    if (selectedData?.id) {
      const payload = {
        influencer_id: selectedData?.id
      };
      dispatch(influencerDetails(payload));
    }
  }, [dispatch, selectedData, !toastDisplayed]);

  useEffect(() => {
    setInfDetails(influencerDataDetails?.data);
  }, [influencerDataDetails]);

  const handleBackButton = () => {
    navigate(`/all-influencer`);
  };

  useEffect(() => {
    console.log("influencerDataDetails ==>", influencerDataDetails);

  }, [influencerDataDetails])

  const renderParsedField = (field) => {
    try {
      const parsedField = JSON.parse(JSON.parse(field));
      return parsedField.map((item, index) => (
        <span key={index}>
          {item.label}
          {index < parsedField.length - 1 ? ", " : ""}
        </span>
      ));
    } catch (e) {
      return field;
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="profile-foreground position-relative mx-n4 mt-n4">
          <div className="profile-wid-bg">
            <img
              src="../assets/images/profile-bg.jpg"
              alt=""
              className="profile-wid-img"
            />
          </div>
        </div>
        <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
          <div className="row g-4">
            <div className="col-auto">
              <div className="avatar-lg">
                <img
                  src="../assets/images/channels4_profile.jpg  "
                  alt="user-img"
                  className="img-thumbnail"
                />
              </div>
            </div>
            <div className="col">
              <div className="p-2">
                <h3 className="text-white mb-1">{selectedData?.name}</h3>
                <p className="text-white text-opacity-75">
                  <div
                    className={`badge rounded-pill fs-12 ${selectedData?.status === 0
                      ? 'bg-warning'
                      : selectedData?.status === 1
                        ? 'bg-success'
                        : selectedData?.status === 3
                          ? 'bg-danger'
                          : selectedData?.status === 4
                            ? 'bg-danger'
                            : selectedData?.status === 5
                              ? 'bg-info'
                              : ''
                      }`}
                  >
                    {selectedData?.user_status}
                  </div>
                </p>
                <div className="hstack text-white-50 gap-1">
                  <div className="me-2 hstack gap-3 flex-wrap">
                    <div>
                      Created Date:{" "}
                      <span className="fw-medium">
                        {formatDate(infDetails?.created_at)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div>

              <div className="tab-content pt-4 text-muted">
                <div
                  className="tab-pane active"
                  id="overview-tab"
                  role="tabpanel"
                >
                  <div className="row">
                    <div className="col-xxl-12">
                      <div className="card">
                        <div className="card-header align-items-center d-flex">
                          <h4 className="card-title mb-0  me-2">
                            Basic Details
                          </h4>
                        </div>
                        <div className="card-body">
                          <div className="row">



                            <div className="col-6 col-md-4">
                              <div className="d-flex mt-4">
                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                  <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                    <i className="ri-global-line"></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="mb-1">Contact Person Name :</p>
                                  <h6 className="text-truncate mb-0">
                                    {selectedData?.concern_person_name}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 col-md-4">
                              <div className="d-flex mt-4">
                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                  <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                    <i className="ri-user-2-fill"></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="mb-1">Email id :</p>
                                  <h6 className="text-truncate mb-0">
                                    {selectedData?.email}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 col-md-4">
                              <div className="d-flex mt-4">
                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                  <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                    <i className="ri-global-line"></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="mb-1">Phone no :</p>
                                  <h6 className="text-truncate mb-0">
                                    {selectedData?.mobile_no}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 col-md-4">
                              <div className="d-flex mt-4">
                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                  <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                    <i className="ri-user-2-fill"></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="mb-1">Language :</p>
                                  <h6 className="text-truncate mb-0">{JSON.parse(selectedData?.language)?.label}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="card">
                            <div className="card-header align-items-center d-flex">
                              <h4 className="card-title mb-0  me-2">
                                Channel Details
                              </h4>
                            </div>
                            <div className="card-body">
                              <div className="border-top border-top-dashed">
                                <div className="row gy-3">
                                  <div className="table-responsive">
                                    <table className="table align-middle mb-0">
                                      <thead className="table-light">
                                        <tr>
                                          <th scope="col">Channel</th>
                                          <th scope="col">Channel Links</th>
                                          <th scope="col">
                                            Subscribers/Followers
                                          </th>
                                          <th scope="col">Avg. Views</th>
                                          <th scope="col">Dedicated Cost</th>
                                          <th scope="col">Integrated Cost</th>
                                          <th scope="col">Conceptual Cost</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {influencerDataDetails?.data[0]?.showChannel?.map(
                                          (channel, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>{channel?.channel_name}</td>
                                                <td>
                                                  <Link to={channel?.chUrl} target="_blank" rel="noopener noreferrer" className="fw-semibold">
                                                    {channel?.chUrl || "-"}
                                                  </Link>
                                                </td>
                                                <td>{formatAmount(channel?.chSubscription || "-")}</td>
                                                <td>{formatAmount(channel?.views || "-")}</td>
                                                <td>{formatAmount(channel?.dedicateCost || "-")}</td>
                                                <td>{formatAmount(channel?.integratedCost || "-")}</td>
                                                <td>{formatAmount(channel?.conceptualCost || "-")}</td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DetailClients;
