import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

// Create an async thunk for fetching data
export const fatchVideosList = createAsyncThunk(
  "videos/fatchVideosList",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/video_script/get_video", formData);
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const showRemark = createAsyncThunk(
  'videos/showRemark',
  async (formData, { rejectWithValue }) => {
    try {

      const response = await axiosInstance.post('/remark/show_remark', formData);
      console.log("hello world!", response?.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const remarkDocList = createAsyncThunk(
  'videos/remarkDocList',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/remark/doc_list', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fatchVideosDetails = createAsyncThunk(
  "videos/fatchVideosDetails",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/video_script/video_details", formData);
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const accessReqInfDetails = createAsyncThunk(
  "videos/accessReqInfDetails",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("dm/inf_access_req_list");
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const infAccessReqDetails = createAsyncThunk(
  "videos/infAccessReqDetails",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("dm/approve_inf_access_req", formData);
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);



const initialVideoState = {
  data: [],
  pagination: {
    limit: 0,
    page: 0,
    totalItems: 0,
    totalPages: 0
  }
};

const videosSlice = createSlice({
  name: "videos",
  initialState: {
    accessInfRequestData: { ...infAccessReqDetails },
    accessData: { ...initialVideoState },
    videosData: { ...initialVideoState },
    videosDataDetails: { ...initialVideoState },
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fatchVideosList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fatchVideosList.fulfilled, (state, action) => {
        const { status_id, data } = action.payload;
        state.status = "succeeded";
        state.videosData = data;
      })
      .addCase(fatchVideosList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(accessReqInfDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(accessReqInfDetails.fulfilled, (state, action) => {
        const { status_id, data } = action.payload;
        state.status = "succeeded";
        state.accessData = data;
      })
      .addCase(accessReqInfDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(infAccessReqDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(infAccessReqDetails.fulfilled, (state, action) => {
        const { status_id, data } = action.payload;
        state.status = "succeeded";
        state.accessInfRequestData = data;
      })
      .addCase(infAccessReqDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(fatchVideosDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fatchVideosDetails.fulfilled, (state, action) => {
        const { status_id, data } = action.payload;
        console.log("sadsadsadsadasdsa asdasd ==>", data);

        state.status = "succeeded";
        state.videosDataDetails = data;
      })
      .addCase(fatchVideosDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(remarkDocList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(remarkDocList.fulfilled, (state, action) => {
        const { status_id, data } = action.payload;
        state.status = "succeeded";
        state.remarkDocListDetails = data;
      })
      .addCase(remarkDocList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(showRemark.pending, (state) => {
        state.status = "loading";
      })
      .addCase(showRemark.fulfilled, (state, action) => {
        const { status_id, data } = action.payload;
        state.status = "succeeded";
        state.showRemarkListDetails = data;
      })
      .addCase(showRemark.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      ;
  }
});

export default videosSlice.reducer;
