import React, { useEffect, useState } from "react";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast, ToastContainer } from "react-toastify";
import { clientRequestInf } from "../../features/influencerSlice/influencerSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";


const RequestForInfluencer = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");

    const { showDetailModal, setShowDetailModal, dataRowRecord } = props;
    const { clientInfluencerRequest, status } = useSelector((state) => state.clients);

    const [toastDisplayed, setToastDisplayed] = useState(false);
    const [comleteData, setCompleteData] = useState("")

    const handleClose = () => setShowDetailModal(false);


    useEffect(() => {
        if (status === "succeeded") {
            setCompleteData(clientInfluencerRequest)
        }
    }, [clientInfluencerRequest, status, toastDisplayed])

    const handleConfirmBtn = () => {
        const payload = {
            inf_id: dataRowRecord?.id
        }

        dispatch(clientRequestInf(payload))
            .unwrap()
            .then((result) => {
                if (result.status) {
                    setToastDisplayed(true);
                    toast.success(result.message, {
                        onClose: () => {
                            setToastDisplayed(false);
                        }
                    });
                    handleClose();
                }
            });
    }

    return (
        <>
            <Modal size="lg" show={showDetailModal} onHide={handleClose} backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Conformation Alert!!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to request for Influencer</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary btn-sm btn-light">Cancel</button>
                    <button className="btn btn-success btn-sm" onClick={handleConfirmBtn}>Confirm</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default RequestForInfluencer;
