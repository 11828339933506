import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import {
  fetchClientDeleteData,
  clientCreateVideo
} from "../../features/clientInfluencerRequestSlice/clientInfluencerRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Required for Toastify styles
import FormattedInputYouTube from "./FormattedInputYouTube";
import { fetchCategoryData } from "../../features/commonSlice/commonSlice";

const videoStatusList = [
  { value: 1, label: "Scheduled" },
];
const CreateVideo = (props) => {
  const {
    showDeleteModal,
    selectedRowsData,
    setSelectedRowsData,
    setShowDeleteModal
  } = props;
  const dispatch = useDispatch();

  const { categoryData, status: commonStatus } = useSelector(
    (state) => state.influencer
  );
  const [errorData, setErrorData] = useState(false);

  const [categoryList, setCategoryList] = useState([]);
  const [dataForm, setDataForm] = useState({
    productName: "",
    videoStatus: "",
    tentativeDate: ""
  });

  const [errors, setErrors] = useState("");

  useEffect(() => {
    dispatch(fetchCategoryData());
  }, []);

  useEffect(() => {
    if (commonStatus === "succeeded") {
      setCategoryList(categoryData);
    }
  }, [commonStatus, categoryData]);

  const handleSelectChange = (name, value) => {
    setDataForm({
      ...dataForm,
      [name]: value
    });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setDataForm((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  useEffect(() => {
    console.log("setRequestData ==>", selectedRowsData);
  }, [selectedRowsData]);

  const confirmDelete = () => {
    const payload = {
      inf_req_id: selectedRowsData?.inf_req_id,
      sugg_id: selectedRowsData?.id,
      product: {
        value: dataForm?.productName?.value,
        label: dataForm?.productName?.label
      },
      video_status: {
        value: dataForm?.videoStatus?.value,
        label: dataForm?.videoStatus?.label
      },
      tentative_date: dataForm?.tentativeDate,
      final_video_cost: selectedRowsData?.final_cost,
      //DM Role
      video_link: "", // null value for Client and Sales Role
      scheduled_date: "", // null value for Client and Sales Role
      inf_video_cost: "0"
    };
    console.log("helo ==>", payload);
    console.log("helo dataForm dataForm ==>", dataForm);

    dispatch(clientCreateVideo(payload))
      .unwrap()
      .then((result) => {
        if (result.status) {
          toast.success(result.message, {
            onClose: () => {
            }
          });
          setShowDeleteModal(false);
          setDataForm({
            productName: "",
            videoStatus: "",
            tentativeDate: ""
          });
        } else {
          setErrorData(result.form_err);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
  };

  return (
    <>
      <Modal
        size="lg"
        backdrop="static"
        keyboard={false}
        show={showDeleteModal}
        onHide={cancelDelete}
      >
        <Modal.Header closeButton className=" bg-light p-3">
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row g-3">
            <div className="col-lg-6">
              <div>
                <label for="influencer-name" className="form-label">
                  Influencer Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="influencer-name"
                  className="form-control"
                  placeholder="Enter Influencer Name"
                  required=""
                  value={selectedRowsData?.inf_name}
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <label for="phone-field" className="form-label">
                  Channel <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="channel-type"
                  value={selectedRowsData?.channel_name || ""}
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <label for="leads_score-field" className="form-label">
                  Video Type <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="video-type"
                  value={selectedRowsData?.video_type || ""}
                  disabled
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label for="product-name" className="form-label">
                  Product <span className="text-danger">*</span>
                </label>
                <Select
                  name="productName"
                  options={categoryList?.data}
                  classNamePrefix="select"
                  onChange={(selected) =>
                    handleSelectChange("productName", selected)
                  }
                  value={dataForm.productName}
                />
                <div className="text-danger">
                  {" "}
                  {errors.productName && <> {errors.productName}</>}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <label for="phone-field" className="form-label">
                  Video Status <span className="text-danger">*</span>
                </label>
                <Select
                  name="videoStatus"
                  options={videoStatusList}
                  classNamePrefix="select"
                  onChange={(selected) =>
                    handleSelectChange("videoStatus", selected)
                  }
                  value={dataForm.videoStatus}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <label for="date-field" className="form-label">
                  Tentative Date <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  id="date-field"
                  className="form-control"
                  name="tentativeDate"
                  onChange={handleChange}
                  value={dataForm.tentativeDate}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div>
                <label for="video-cost-field" className="form-label">
                  Video Cost <span className="text-danger">*</span>
                </label>
                <FormattedInputYouTube
                  type="text"
                  className="form-control"
                  name="below_channels.youTube.subscribers"
                  placeholder="Enter Subscribers"
                  value={selectedRowsData?.final_cost}
                  // onChange={handleInputChange}
                  required
                  disabled
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="hstack gap-2 justify-content-end">
            <Button
              variant="danger"
              className="btn btn-light"
              onClick={cancelDelete}
            >
              Close
            </Button>
            <Button
              variant="success"
              className="btn btn-success"
              onClick={confirmDelete}
            >
              Submit
            </Button>{" "}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateVideo;
