import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clientRequestDetails, influencerDetails } from "../../features/influencerRequestSlice/influencerRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../utils/dateUtils";
import { format } from "date-fns";
import useDecodedToken from "../../features/utils/hooks/useDecodedToken";
// import dayjs from 'dayjs';

const DetailRequestClient = (props) => {
  const decodeToken = useDecodedToken();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const selectedData = location.state;
  const { clientsRequestDetails, status, message } = useSelector(
    (state) => state.requestInfluencerDetails
  );
  const [toastDisplayed, setToastDisplayed] = useState(false);
  const [infDetails, setInfDetails] = useState("");

  useEffect(() => {
    if (selectedData?.id) {
      const payload = {
        inf_req_id: selectedData?.id
      };
      dispatch(clientRequestDetails(payload));
    }
  }, [dispatch, selectedData, !toastDisplayed]);

  useEffect(() => {
    setInfDetails(clientsRequestDetails?.data[0]);
  }, [clientsRequestDetails]);

  const handleBackButton = () => {
    navigate(`/all-influencer`);
  };

  useEffect(() => {
    console.log("influencerDataDetails infloddddd ==>", infDetails);

  }, [infDetails])

  return (
    <>
      <div className="container-fluid">
        <div className="profile-foreground position-relative mx-n4 mt-n4">
          <div className="profile-wid-bg">
            <img
              src="../assets/images/profile-bg.jpg"
              alt=""
              className="profile-wid-img"
            />
          </div>
        </div>
        <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
          <div className="row g-4">
            <div className="col-auto">
              <div className="avatar-lg">
                <img
                  src="../assets/images/channels4_profile.jpg  "
                  alt="user-img"
                  className="img-thumbnail"
                />
              </div>
            </div>
            <div className="col">
              <div className="p-2">
                <h3 className="text-white mb-1">{infDetails?.client_name}</h3>
                <p className="text-white text-opacity-75">
                  <div
                    className={`badge rounded-pill fs-12 ${infDetails?.status === 0
                      ? 'bg-warning'
                      : infDetails?.status === 6
                        ? 'bg-success'
                        : infDetails?.status === 12
                          ? 'bg-danger'
                          : ''
                      }`}
                  >
                    {infDetails?.show_status}
                  </div>
                </p>
                <div className="hstack text-white-50 gap-1">
                  <div className="me-2 hstack gap-3 flex-wrap">
                    <div>
                      Created Date:{" "}
                      <span className="fw-medium">
                        {formatDate(infDetails?.created_at)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div>
              <div className="tab-content pt-4 text-muted">
                <div
                  className="tab-pane active"
                  id="overview-tab"
                  role="tabpanel"
                >
                  <div className="row">
                    <div className="col-xxl-12">
                      <div className="card">
                        <div className="card-header align-items-center d-flex">
                          <h4 className="card-title mb-0  me-2">
                            Basic Details
                          </h4>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-6 col-md-4">
                              <div className="d-flex mt-4">
                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                  <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                    <i className="ri-global-line"></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="mb-1">Products :</p>
                                  <h6 className="text-truncate mb-0">
                                    {infDetails?.product}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 col-md-4">
                              <div className="d-flex mt-4">
                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                  <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                    <i className="ri-global-line"></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="mb-1">No of Influencers :</p>
                                  <h6 className="text-truncate mb-0">
                                    {infDetails?.no_of_influencer?.label}
                                  </h6>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="card">
                            <div className="card-header align-items-center d-flex">
                              <h4 className="card-title mb-0  me-2">
                                Channels
                              </h4>
                            </div>
                            <div className="card-body">
                              <div className="border-top border-top-dashed">
                                <div className="row gy-3">
                                  <div className="table-responsive">
                                    <table className="table align-middle mb-0">
                                      <thead className="table-light">
                                        <tr>
                                          <th scope="col">Channel</th>
                                          <th scope="col">
                                            Subscribers/Followers
                                          </th>
                                          <th scope="col">Language</th>
                                          <th scope="col">Minimum Budget</th>
                                          <th scope="col">Maximum Budget</th>
                                          <th scope="col">Video Type</th>
                                          
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {infDetails?.channel_datas?.map(
                                          (channel, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>{channel?.channel_name}</td>

                                                <td>{channel?.follower_subscriber?.label || "-"}</td>
                                                <td>
                                                  {channel?.language && channel?.language.length > 0
                                                    ? channel.language.map((lang, index) => (
                                                      <span key={index}>
                                                        {lang?.label}
                                                        {index < channel.language.length - 1 ? ', ' : ''}
                                                      </span>
                                                    ))
                                                    : "-"}
                                                </td>
                                                <td>{channel?.min_budget || "-"}</td>
                                                <td>{channel?.max_budget || "-"}</td>
                                                <td>
                                                  {channel?.video_type && channel?.video_type.length > 0
                                                    ? channel.video_type.map((lang, index) => (
                                                      <span key={index}>
                                                        {lang?.label}
                                                        {index < channel.video_type.length - 1 ? ', ' : ''}
                                                      </span>
                                                    ))
                                                    : "-"}
                                                </td>
                                                
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailRequestClient;
