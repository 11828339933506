import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import useDecodedToken from "../../features/utils/hooks/useDecodedToken";
import { accessReqInfDetails, infAccessReqDetails } from "../../features/videos/videosSlice";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AccessInfRequest = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const decodeToken = useDecodedToken();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const [toastDisplayed, setToastDisplayed] = useState(false);

  // Destructuring accessData from redux state
  const { accessData, status } = useSelector((state) => state.videosDetails);

  // Set accessDataDetails as an empty array initially
  const [accessDataDetails, setAccessDataDetails] = useState([]);

  // Fetch data when the component mounts
  useEffect(() => {
    dispatch(accessReqInfDetails());
  }, [dispatch, toastDisplayed]);

  // Set accessDataDetails from accessData
  useEffect(() => {
    // Ensure accessData is an array
    setAccessDataDetails(Array.isArray(accessData) ? accessData : []);
  }, [accessData]);

  // Optional: Log the details for debugging
  useEffect(() => {
    console.log("access record ==>", accessDataDetails);
  }, [accessDataDetails]);

  const handleSunmitRequest = (accessreqData) => {
    const payload = {
      inf_access_req_id: accessreqData?.id
    }
    dispatch(infAccessReqDetails(payload))
      .unwrap()
      .then((result) => {
        if (result.status) {
          setToastDisplayed(true);
          toast.success(result.message, {
            onClose: () => {
              setToastDisplayed(false);
            }
          });
        }
      });
  }

  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">All Videos</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a href="#">CRM</a>
                </li>
                <li className="breadcrumb-item active">Active Influencers</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card" id="leadsList">
              <div className="card-header border-0">
                <div className="row g-4 align-items-center">
                  <div className="col-sm-auto ms-auto">
                    <div className="hstack gap-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-info"
                        data-bs-toggle="offcanvas"
                        href="#offcanvasExample"
                      >
                        <i className="ri-filter-3-line align-bottom me-1"></i> Filters
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered table-hover table-striped">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Influencer Name</th>
                        <th>DM Executive Name</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Ensure accessDataDetails is an array */}
                      {accessDataDetails.length > 0 ? (
                        accessDataDetails.map((accessreqData) => {
                          return (
                            <tr key={accessreqData.id}>
                              <td>{accessreqData.id}</td>
                              <td>{accessreqData.InfName}</td>
                              <td>{accessreqData.DMExeName}</td>
                              <td>{accessreqData.status === 0 ? "Inactive" : "Active"}</td>
                              <td>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top">
                                      Request Influences
                                    </Tooltip>
                                  }
                                >
                                  <button type="button" className="btn btn-info btn-sm" onClick={() => handleSunmitRequest(accessreqData)}>
                                    <i className="ri-send-plane-fill align-bottom"></i>
                                  </button>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="5">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessInfRequest;
