import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientData } from "../../features/clientSlice/clientSlice";
import { Button, Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";
import AddNewClients from "./AddNewClients";
import UpdateClients from "./UpdateClients";
import DetailClients from "./DetailClients_2";
import AssigntoSale from "./AssigntoSale";
import UpdateStatus from "./UpdateStatus";
import { format } from "date-fns";
import Select from "react-select";
import { Link } from "react-router-dom";
import AssignedTemp from "./AssignedTemp";
import useDecodedToken from "../../features/utils/hooks/useDecodedToken";
import { formatDate } from "../../utils/dateUtils";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import moment from "moment";
import Filter from "../filter/Filter";


export const headerData = {
  sr_no: "SR No.",
  action: "Action",
  client_name: "Client Name",
  onboard_date: "Onboard Date",
  status: "Status",
  assign_to: "Assigned To",
  tmp_assign_to: "Temporary Assigned To",
  tmp_assign_date: "Temporary Assigned To Date",
  d_video: "Total Dedicated Videos",
  int_video: "Total Integrated Videos",
  s_video: "Scheduled Videos",
  category: "Categories",
  email: "Email id",
  contact_per: "Contact Person",
  mobile: "Mobile Number"
};

const AllClients = () => {
  const decodedToken = useDecodedToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const decodeToken = useDecodedToken();
  const { activeClientData, status } = useSelector((state) => state.clients);
  const [clientsData, setClientsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientsPerPage, setClientsPerPage] = useState(10);
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showAssignSaleModal, setAssignSaleModel] = useState(false);
  const [assignTempModel, setAssignTempModel] = useState(false);
  const [showStatusModal, setStatusModel] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const [formData, setFormData] = useState({
    channel_name: [],
    categories: [],
    languages: [],
    budget: { min: "", max: "" },
    subscribers: { min: "", max: "" },
    views: { min: "", max: "" }
  });

  useEffect(() => {
    const filterPayload = {
      channel_name: formData?.channel_name,
      categories: formData?.categories,
      languages: formData?.languages,
      budget: formData?.budget,
      subscribers: formData?.subscribers,
      views: formData?.views
    }
    dispatch(
      fetchClientData({
        status_id: "1",
        filterPayload,
        page: currentPage,
        limit: clientsPerPage === 2000 ? "All" : clientsPerPage
      })
    );
  }, [dispatch, currentPage, clientsPerPage, decodeToken]);

  useEffect(() => {
    if (status === "succeeded") {
      setClientsData(activeClientData?.data || []);
    }
  }, [status, activeClientData]);

  // Sorting logic
  const sortTable = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Apply sorting
  const sortedData = [...clientsData].sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (aValue === undefined || bValue === undefined) {
      return 0;
    }

    if (sortConfig.direction === "ascending") {
      return aValue.toString().localeCompare(bValue.toString());
    }
    return bValue.toString().localeCompare(aValue.toString());
  });

  

  const clickAddNew = () => {
    setShowModal(true);
  };
  const handleUpdateClient = (rowData) => {
    setShowUpdateModal(true);
    setSelectedData(rowData);
  };
  const handleDetailClient = (rowData) => {
    navigate(`/client-details/${rowData?.id}`, { state: rowData });
  };
  const handleAssigntosaleClient = (rowData) => {
    setAssignSaleModel(true);
    setSelectedData(rowData);
  };
  const handleAssigntosaleTemp = (rowData) => {
    setAssignTempModel(true);
    setSelectedData(rowData);
  };

  const handleChangeStatus = (rowData) => {
    if (decodeToken?.UsrRoleId === 2) {
      setStatusModel(true);
      setSelectedData(rowData);
    }
  };
  const handleClientsPerPageChange = (selectedOption) => {
    setClientsPerPage(selectedOption.value);
    setCurrentPage(1); // Reset to first page whenever per page value changes
  };
  const perPageOptions = [
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 2000, label: "All" } // Add 'All' option
  ];

  const handleFilter = () => {

    const filterPayload = {
      channel_name: formData?.channel_name,
      categories: formData?.categories,
      languages: formData?.languages,
      budget: formData?.budget,
      subscribers: formData?.subscribers,
      views: formData?.views
    }
    dispatch(
      fetchClientData({
        status_id: "99",
        filterPayload,
        page: currentPage,
        limit: clientsPerPage === 2000 ? "All" : clientsPerPage
      })
    );
    setShowFiltersDrawer(false);
    setFormData({
      channel_name: [],
      categories: [],
      languages: [],
      budget: { min: "", max: "" },
      subscribers: { min: "", max: "" },
      views: { min: "", max: "" }
    });

  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">Active Clients</h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a href="javascript: void(0);">CRM</a>
                </li>
                <li className="breadcrumb-item active">Active Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card" id="leadsList">
            <div className="card-header border-0">
              <div className="row g-4 align-items-center">
                <div className="col-sm-1">
                  <label className="small">Select Records</label>
                  <Select
                    className="basic-single small"
                    classNamePrefix="select"
                    name="perPage"
                    options={perPageOptions}
                    defaultValue={perPageOptions[0]}
                    onChange={handleClientsPerPageChange}
                  />
                </div>
                <div className="col-sm-auto ms-auto">
                  <div className="hstack gap-2">
                  <button
                      type="button"
                      className="btn btn-sm btn-info"
                      onClick={() => setShowFiltersDrawer(true)}
                    >
                      <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                      Filters
                    </button>
                    <Button
                      className="btn btn-sm btn-success add-btn"
                      onClick={clickAddNew}
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Add
                      Client
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <table
                id="example"
                className="table table-bordered nowrap table-striped align-middle"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>{headerData.sr_no}</th>
                    <th>{headerData.action}</th>
                    <th onClick={() => sortTable("name")}>
                      {headerData.client_name}
                    </th>
                    <th>{headerData.onboard_date}</th>
                    <th>{headerData.status}</th>
                    <th>{headerData.assign_to}</th>
                    <th>{headerData.tmp_assign_to}</th>
                    <th>{headerData.tmp_assign_date}</th>
                    <th>{headerData.email}</th>
                    <th>{headerData.contact_per}</th>
                    <th>{headerData.mobile}</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData.length > 0 ? (
                    sortedData.map((dataActive, index) => (
                      <tr key={index}>
                        <td>
                          {(currentPage - 1) * clientsPerPage + index + 1}
                        </td>
                        <td>
                          <ul className="list-inline hstack mb-0">
                            <li
                              className="list-inline-item"
                              data-bs-toggle="tooltip"
                              data-bs-trigger="hover"
                              data-bs-placement="top"
                              aria-label="View"
                              data-bs-original-title="View"
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    {dataActive.sales_view_button === '1'
                                      ? "View Details"
                                      : "View Restricted"}

                                  </Tooltip>
                                }
                              >
                                <Button
                                  className={`btn btn-sm btn-info edit-item-btn ${dataActive.sales_view_button === '1'
                                    ? ""
                                    : "not-allowed-cursor"}`}
                                  onClick={
                                    dataActive.sales_view_button === '1'
                                      ? () => handleDetailClient(dataActive)
                                      : (e) => e.preventDefault()
                                  }
                                  style={{
                                    cursor:
                                      dataActive.sales_view_button === '1'
                                        ? "pointer"
                                        : "not-allowed"
                                  }}

                                  disabled={!(dataActive.sales_view_button === '1')}
                                >
                                  <i className="ri-eye-fill align-bottom"></i>
                                </Button>
                              </OverlayTrigger>
                            </li>

                            {/* <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" aria-label="View" data-bs-original-title="View">
                                                            <Button className="btn btn-sm btn-warning edit-item-btn" onClick={() => handleUpdateClient(dataActive)}>
                                                                <i className="ri-edit-fill align-bottom"></i>
                                                            </Button>
                                                        </li> */}

                            {decodeToken?.UsrRoleId !== 4 && (
                              <li
                                className="list-inline-item"
                                data-bs-toggle="tooltip"
                                data-bs-trigger="hover"
                                data-bs-placement="top"
                                aria-label="View"
                                data-bs-original-title="View"
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top">
                                      {dataActive.status === 1
                                        ? "Assign to sales team"
                                        : dataActive.status === 5
                                          ? "Temprary assign to sales team"
                                          : "Assign Restricted"}
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    className={`btn btn-sm btn-warning edit-item-btn ${dataActive.status === 1 ||
                                      dataActive.status === 5
                                      ? ""
                                      : "disabled-btn"
                                      }`}
                                    onClick={
                                      dataActive.status === 1
                                        ? () =>
                                          handleAssigntosaleClient(dataActive)
                                        : dataActive.status === 5
                                          ? () =>
                                            handleAssigntosaleTemp(dataActive)
                                          : (e) => e.preventDefault()
                                    }
                                    style={{
                                      cursor:
                                        dataActive.status === 1 ||
                                          dataActive.status === 5
                                          ? "pointer"
                                          : "not-allowed"
                                    }}
                                  >
                                    <i
                                      className={`ri-${dataActive.status !== 5
                                        ? "share"
                                        : "send-plane"
                                        }-fill align-bottom me-1 ${dataActive.status === 4 ||
                                          dataActive.status === 5
                                          ? ""
                                          : "restricted-icon"
                                        }`}
                                    ></i>
                                  </Button>
                                </OverlayTrigger>
                              </li>
                            )}
                          </ul>
                        </td>
                        <td>{dataActive.name}</td>
                        <td>
                          {formatDate(dataActive.created_at)}
                        </td>
                        <td>
                          <span
                            className={`badge w-100 ${dataActive.user_status === "Active"
                              ? "bg-success-subtle text-success"
                              : dataActive.user_status === "Assigned"
                                ? "bg-info-subtle text-info"
                                : dataActive.user_status === "Inactive"
                                  ? "bg-danger-subtle text-danger"
                                  : dataActive.user_status === "Pending" &&
                                  "bg-warning-subtle text-warning"
                              }`}
                          >

                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top">
                                  {dataActive.user_status}
                                </Tooltip>
                              }
                            >
                              <button
                                style={{ cursor: "pointer" }}
                                onClick={() => handleChangeStatus(dataActive)}
                                className="btn btn-default btn-sm m-0 p-0"
                              >
                                {dataActive.user_status}{" "}
                              </button>
                            </OverlayTrigger>

                          </span>
                        </td>
                        <td>
                          {dataActive.user_status === "Assigned" && dataActive.assigned_to_id > 0 ? (
                            dataActive.assigned_to_id === decodedToken?.UsrId ? (
                              <span className="text-success fw-bold p-2">You</span>
                            ) : (
                              dataActive.assigned_to_name
                            )
                          ) : (
                            "-"
                          )}
                        </td>


                        <td>
                          {dataActive.user_status === "Assigned" && dataActive.tmp_assigned_to_id > 0 ? (
                            dataActive.tmp_assigned_to_id === (decodedToken?.UsrId || 0) ? (
                              <span className="text-success fw-bold p-2">You</span>
                            ) : (
                              dataActive.tmp_assigned_to_name
                            )
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          {`${dataActive.to_date != "" &&
                            dataActive.to_date != null
                            ? formatDate(dataActive.to_date)
                            : "-"
                            }`}
                        </td>
                        <td>{dataActive.email}</td>
                        <td>{dataActive.concern_person_name}</td>
                        <td>{dataActive.mobile_no}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="row">
                {clientsPerPage !== "2000" && (
                  <>
                    <div className="col-sm-6">
                      Showing{" "}
                      {Math.min(
                        (currentPage - 1) * clientsPerPage + 1,
                        activeClientData.pagination.totalItems
                      )}{" "}
                      to{" "}
                      {Math.min(
                        currentPage * clientsPerPage,
                        activeClientData.pagination.totalItems
                      )}{" "}
                      of {activeClientData.pagination.totalItems} entries
                    </div>
                    <div className="col-sm-6">
                      <Pagination className="justify-content-end">
                        <Pagination.Prev
                          onClick={() =>
                            setCurrentPage(
                              currentPage > 1 ? currentPage - 1 : currentPage
                            )
                          }
                          disabled={currentPage === 1}
                        />
                        {Array.from(
                          { length: activeClientData.pagination.totalPages },
                          (_, index) => (
                            <Pagination.Item
                              key={index + 1}
                              active={index + 1 === currentPage}
                              onClick={() => setCurrentPage(index + 1)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          )
                        )}
                        <Pagination.Next
                          onClick={() =>
                            setCurrentPage(
                              currentPage < activeClientData.pagination.totalPages
                                ? currentPage + 1
                                : currentPage
                            )
                          }
                          disabled={
                            currentPage === activeClientData.pagination.totalPages
                          }
                        />
                      </Pagination>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddNewClients showModal={showModal} setShowModal={setShowModal} />
      <UpdateClients
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
        selectedData={selectedData}
      />
      <DetailClients
        showModal={showDetailModal}
        setShowModal={setShowDetailModal}
      />
      <AssigntoSale
        showModal={showAssignSaleModal}
        setShowModal={setAssignSaleModel}
        selectedData={selectedData}
      />
      <AssignedTemp
        showModal={assignTempModel}
        setShowModal={setAssignTempModel}
        selectedData={selectedData}
      />
      <UpdateStatus
        showModal={showStatusModal}
        setShowModal={setStatusModel}
        selectedData={selectedData}
      />
      <Filter setShowFiltersDrawer={setShowFiltersDrawer} showFiltersDrawer={showFiltersDrawer} handleFilter={handleFilter} formData={formData} setFormData={setFormData} />
    </>
  );
};

export default AllClients;
