import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

// Create an async thunk for fetching data
export const fetchClientData = createAsyncThunk(
  "clients/fetchClientData",
  async ({ filterPayload, status_id, page, limit }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/sales/client_list_pagination",
        {
          status_id,
          filterPayload,
          page,
          limit
        }
      );
      return { status_id, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchClientsDetails = createAsyncThunk(
  "clients/fetchClientsDetails",
  async (client_id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/sales/client_details", { client_id });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchDmtlList = createAsyncThunk(
  "clients/fetchDmtlList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/master/dm_team_list_with_tl");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




const initialClientState = {
  data: [],
  pagination: {
    limit: 0,
    page: 0,
    totalItems: 0,
    totalPages: 0
  }
};

const clientSlice = createSlice({
  name: "clients",
  initialState: {
    allClientData: { ...initialClientState },
    assignedClientData: { ...initialClientState },
    activeClientData: { ...initialClientState },
    pendingClientData: { ...initialClientState },
    inactiveClientData: { ...initialClientState },
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchClientData.fulfilled, (state, action) => {
        const { status_id, data } = action.payload;
        state.status = "succeeded";
        if (status_id === "1") {
          state.activeClientData = data;
        } else if (status_id === "0") {
          state.pendingClientData = data;
        } else if (status_id === "4") {
          state.inactiveClientData = data;
        } else if (status_id === "5") {
          state.assignedClientData = data;
        } else if (status_id === "99") {
          state.allClientData = data;
        }
      })
      .addCase(fetchClientData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(fetchClientsDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchClientsDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.clientDetailsData = action.payload;
      })
      .addCase(fetchClientsDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchDmtlList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDmtlList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dmTlListData = action.payload;
      })
      .addCase(fetchDmtlList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      ;
  }
});

export default clientSlice.reducer;
