import React, { useEffect, useState } from "react";
import Select from "react-select";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { influencerStatusUpdate } from "../../features/commonSlice/commonSlice";


const statusData = [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 4 },
    { label: "Pending", value: 0 },
];

const UpdateClients = (props) => {
    const { showModal, setShowModal, selectedData } = props;
    const [formData, setFormData] = useState({
        statusData: "",
    });
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [errorData, setErrorData] = useState([]);
    const [loginError, setLoginError] = useState('');

    useEffect(() => {
        if (selectedData) {
            const statusValue = statusData.find(status => status.label.toLowerCase() === selectedData.user_status.toLowerCase());
            setFormData({
                status: statusValue || "",
            });
        }
    }, [selectedData]);

    const handleSelectChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        try {
            const clientStatusData = {
                inf_id: selectedData?.id,
                status: formData.status.value,
            };
            dispatch(influencerStatusUpdate(clientStatusData))
                .unwrap()
                .then((result) => {
                    if (result.status) {
                        toast.success(result.message, {
                            onClose: () => {
                            },
                        });
                        setShowModal(false)

                    } else {
                        setLoginError(result.message);
                        setErrorData(result.form_err);
                    }
                })
                .catch((err) => {
                    setLoginError(err.message);
                    setErrorData(err.form_err || []);
                    toast.error(err.message);
                });
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const mapErrors = (errorData) => {
        const errorMap = {};
        errorData.forEach((error) => {
            if (!errorMap[error.param]) {
                errorMap[error.param] = error.msg;
            }
        });
        return errorMap;
    };

    useEffect(() => {
        const errorsValue = mapErrors(errorData);
        setErrors(errorsValue);
    }, [errorData]);

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Modal
                show={showModal}
                onHide={closeModal}
                aria-labelledby="ModalHeader"
                size="lg"
                backdrop="static" // Prevents closing on backdrop click
                keyboard={false} // Prevents closing on ESC key press
            >
                <Modal.Header closeButton >
                    <Modal.Title>Update Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="p-0">
                                        <table className="table table-striped table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td className="w-25 p-2">  Name</td>
                                                    <td>{selectedData?.name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="w-25 p-3">Status:</td>
                                                    <td >
                                                        <Select
                                                            name="status"
                                                            options={statusData}
                                                            classNamePrefix="select"
                                                            onChange={(selected) => handleSelectChange("status", selected)}
                                                            value={formData.status}
                                                        />
                                                        <div className="text-danger" > {errors.status && <> {errors.status}</>}</div>
                                                    </td>
                                                </tr>

                                            </tbody>

                                        </table>


                                        <p className='text-danger text-center'>{!errorData.length > 0 && loginError}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button type="button" variant="primary" onClick={handleSubmit}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UpdateClients;
