import React from 'react';
import { Link } from 'react-router-dom';

const DmNavbar = () => {
    return (
        <>
            <li className="nav-item">
                <a className="nav-link menu-link" href="#clients" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="clients">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-clients">Clients</span>
                </a>
                <div className="collapse menu-dropdown" id="clients">
                    <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                            <Link to="/active-clients" className="nav-link" data-key="t-analytics">Active</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/assigned-clients" className="nav-link" data-key="t-analytics">Assigned</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/pending-clients" className="nav-link" data-key="t-analytics">Pending</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/inactive-clients" className="nav-link" data-key="t-crm">Inactive</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/all-clients" className="nav-link" data-key="t-ecommerce">All</Link>
                        </li>
                    </ul>
                </div>
            </li>
            <li className="nav-item">
                <a className="nav-link menu-link" href="#clientrequest" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="clientrequest">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-clients">Client Request</span>
                </a>
                <div className="collapse menu-dropdown" id="clientrequest">
                    <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                            <Link to="/client-request-in-sales" className="nav-link" data-key="t-analytics">All Request </Link>
                        </li>

                    </ul>
                </div>
            </li>
            <li className="nav-item">
                <a className="nav-link menu-link" href="#suggestedinf" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="suggestedinf">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-clients">Suggested Influencer</span>
                </a>
                <div className="collapse menu-dropdown" id="suggestedinf">
                    <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                            <Link to="/suggested-influencer-list" className="nav-link" data-key="t-analytics">Suggested Influencer</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/approved-suggested-influencer-list" className="nav-link" data-key="t-analytics">Approved Influencer</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/rejected-suggested-influencer-list" className="nav-link" data-key="t-analytics">Rejected Influencer</Link>
                        </li>

                    </ul>
                </div>
            </li>
            <li className="nav-item">
                <a className="nav-link menu-link" href="#videos" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarVideos">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-videos">Videos</span>
                </a>
                <div className="collapse menu-dropdown" id="videos">
                    <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                            <Link to="/upcoming-videos" className="nav-link" data-key="t-analytics">Upcoming Videos</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/post-videos" className="nav-link" data-key="t-analytics">Post Videos</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/all-videos" className="nav-link" data-key="t-analytics">All</Link>
                        </li>
                    </ul>
                </div>
            </li>
        </>
    );
}

export default DmNavbar;
