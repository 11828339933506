import React from 'react';
import { Link } from 'react-router-dom';

const DmNavbar = () => {
    return (
        <>
            <li className="nav-item">
                <a className="nav-link menu-link" href="#dmexecutive" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="dmexecutive">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-clients">Influencer</span>
                </a>
                <div className="collapse menu-dropdown" id="dmexecutive">
                    <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                            <Link to="/active-influencer" className="nav-link" data-key="t-analytics">Active Influencer </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/assigned-influencer" className="nav-link" data-key="t-ecommerce">Assigned Influencer</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/pending-influencer" className="nav-link" data-key="t-analytics">Pending Influencer  </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/inactive-influencer" className="nav-link" data-key="t-crm">Inactive Influencer </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/all-influencer" className="nav-link" data-key="t-ecommerce">All Influencer</Link>
                        </li>
                    </ul>
                </div>
            </li>

            <li className="nav-item">
                <a className="nav-link menu-link" href="#clientrequest" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="clientrequest">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-clients">Request</span>
                </a>
                <div className="collapse menu-dropdown" id="clientrequest">
                    <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                            <Link to="/client-request-in-dm" className="nav-link" data-key="t-analytics">All Request </Link>
                        </li>
                    </ul>
                </div>
            </li>
            <li className="nav-item">
                <a className="nav-link menu-link" href="#infrequest" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarVideos">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-videos">Sub Request</span>
                </a>
                <div className="collapse menu-dropdown" id="infrequest">
                    <ul className="nav nav-sm flex-column">

                        <li className="nav-item">
                            <Link
                                to="/suggested-influencer"
                                className={`nav-link`}
                                data-key="t-ecommerce"
                            >
                                Influencer Sub Request
                            </Link>
                        </li>
                    </ul>
                </div>
            </li>
            <li className="nav-item">
                <a className="nav-link menu-link" href="#suggestedInf" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="suggestedInf">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-clients">Suggested Influencer</span>
                </a>
                <div className="collapse menu-dropdown" id="suggestedInf">
                    <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                            <Link to="/client-sub-request-in-dm-tl" className="nav-link" data-key="t-analytics">Suggested Influencer</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/approved-client-sub-request-in-dm-tl" className="nav-link" data-key="t-analytics">Approved Influencer</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/rejected-client-sub-request-in-dm-tl" className="nav-link" data-key="t-analytics">Rejected Influencer</Link>
                        </li>
                    </ul>
                </div>
            </li>
            <li className="nav-item">
                <a className="nav-link menu-link" href="#videos" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarVideos">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-videos">Videos</span>
                </a>
                <div className="collapse menu-dropdown" id="videos">
                    <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                            <Link to="/upcoming-videos" className="nav-link" data-key="t-analytics">Upcoming Videos</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/post-videos" className="nav-link" data-key="t-analytics">Post Videos</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/all-videos" className="nav-link" data-key="t-analytics">All</Link>
                        </li>
                    </ul>
                </div>
            </li>
            <li className="nav-item">
                <a className="nav-link menu-link" href="#accessrequest" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="accessrequest">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-clients">Access Request</span>
                </a>
                <div className="collapse menu-dropdown" id="accessrequest">
                    <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                            <Link to="/influencer-access-request-in-dm-tl" className="nav-link" data-key="t-analytics">Influencer Access </Link>
                        </li>
                    </ul>
                </div>
            </li>
        </>
    );
}

export default DmNavbar;
