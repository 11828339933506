import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fatchVideosDetails, showRemark, remarkDocList } from "../../features/videos/videosSlice";
import { Button } from "react-bootstrap";
import VideoRemak from "./VideoRemak";
import { formatDate, formatDateTime } from "../../utils/dateUtils";

const ClientVideoDetails = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [videoData, setVideoData] = useState('');
    const [showRemarkModal, setShowRemarkModal] = useState(false);
    const selectedData = location.state;

    // Fetching state from Redux store
    const { videosDataDetails, showRemarkListDetails, remarkDocListDetails, status, message } = useSelector(
        (state) => state.videosDetails
    );

    // Dispatch actions to fetch video, remark, and documents when the component mounts or when selectedData changes
    useEffect(() => {
        if (selectedData?.id) {
            const payload = { video_id: selectedData.id };
            dispatch(fatchVideosDetails(payload));      // Fetch video details
            dispatch(showRemark(payload));
            dispatch(remarkDocList(payload));           // Fetch documents
        }
    }, [dispatch, selectedData]);

    // Update videoData when video details are fetched
    useEffect(() => {
        if (videosDataDetails.length > 0) {
            setVideoData(videosDataDetails[0]);
        }
    }, [videosDataDetails]);

    // useEffect(() => {
    //     if (selectedData?.id) {
    //         const payload = { video_id: selectedData?.id };
    //         dispatch(showRemark(payload));              // Fetch remarks
    //     }
    // }, [selectedData])

    // Show remark modal
    const handleShowRemark = () => {
        setShowRemarkModal(true);
        setVideoData(videosDataDetails[0]);
    };

    // Utility functions for file handling (not modified)
    const getFileExtension = (fileName) => fileName.split('.').pop();
    const getFileType = (extension) => {
        switch (extension) {
            case 'jpeg':
            case 'jpg': return 'Image';
            case 'mp4':
            case 'mov': return 'Video';
            case 'pdf': return 'PDF';
            case 'zip': return 'Compressed';
            default: return 'Unknown';
        }
    };

    const getFileSize = (sizeInBytes) => {
        const sizeInMB = sizeInBytes / (1024 * 1024); // Convert bytes to MB
        return `${sizeInMB.toFixed(2)} MB`;
    };

    // Handle file download (not modified)
    const handleDownload = (fileName) => {
        const fileUrl = `http://backapi.influenceract.com/uploads/${fileName}`;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // UseEffect for debugging (not necessary for functionality, but useful for inspecting)
    useEffect(() => {
        console.log("showRemarkDetails: ", showRemarkListDetails);
    }, [showRemarkListDetails]);

    return (
        <>
            <div className="container-fluid">
                <div className="profile-foreground position-relative mx-n4 mt-n4">
                    <div className="profile-wid-bg">
                        <img src="../assets/images/profile-bg.jpg" alt="" className="profile-wid-img" />
                    </div>
                </div>
                <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
                    <div className="row g-4">
                        <div className="col-auto">
                            <div className="avatar-lg">
                                <img src="../assets/images/policy-chayan.png" alt="user-img" className="img-thumbnail" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="p-2">
                                <h3 className="text-white mb-1">{videoData?.show_video_id}</h3>
                                <p className="text-white text-opacity-75">
                                    <div className="badge rounded-pill bg-success fs-12">{videoData?.show_video_status}</div>
                                </p>
                                <div className="hstack text-white-50 gap-1">
                                    <div className="me-2 hstack gap-3 flex-wrap">
                                        <div>{videoData?.video_status === 1 ? 'Schedule' : videoData?.video_status === 2 ? 'Live' : ''} Date:
                                            <span className="fw-medium"> {videoData?.video_status === 1 ? videoData?.scheduled_date : videoData?.video_status === 2 ? videoData?.live_date : null}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-12">
                        <div>
                            <div className="d-flex profile-wrapper">
                                <ul className="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link fs-14 active" data-bs-toggle="tab" href="#overview-tab" role="tab">
                                            <i className="ri-airplay-fill d-inline-block d-md-none"></i> <span
                                                className="d-none d-md-inline-block">Overview</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link fs-14" data-bs-toggle="tab" href="#documents" role="tab">
                                            <i className="ri-folder-4-line d-inline-block d-md-none"></i> <span
                                                className="d-none d-md-inline-block">Documents</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link fs-14" data-bs-toggle="tab" href="#remarks" role="tab">
                                            <i className="ri-price-tag-line d-inline-block d-md-none"></i> <span
                                                className="d-none d-md-inline-block">Remarks</span>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                            <div className="tab-content pt-4 text-muted">
                                <div className="tab-pane active" id="overview-tab" role="tabpanel">
                                    <div className="row">
                                        <div className="col-xxl-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-6 col-md-4">
                                                            <div className="d-flex mt-4">
                                                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                        <i className="ri-user-2-fill"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 overflow-hidden">
                                                                    <p className="mb-1">Video Type :</p>
                                                                    <h6 className="text-truncate mb-0">{videoData?.video_type}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4">
                                                            <div className="d-flex mt-4">
                                                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                        <i className="ri-global-line"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 overflow-hidden">
                                                                    <p className="mb-1">Video Id :</p>
                                                                    <h6 className="text-truncate mb-0">{videoData?.show_video_id}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4">
                                                            <div className="d-flex mt-4">
                                                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                        <i className="ri-user-2-fill"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 overflow-hidden">
                                                                    <p className="mb-1">Video Status :</p>
                                                                    <h6 className="text-truncate mb-0">{videoData?.show_video_status}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4">
                                                            <div className="d-flex mt-4">
                                                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                        <i className="ri-global-line"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 overflow-hidden">
                                                                    <p className="mb-1">Video {videoData?.video_status === 1 ? 'Schedule' : videoData?.video_status === 2 ? 'Live' : ''} Date :</p>
                                                                    <h6 className="text-truncate mb-0">{videoData?.video_status === 1 ? videoData?.scheduled_date : videoData?.video_status === 2 ? videoData?.live_date : null}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4">
                                                            <div className="d-flex mt-4">
                                                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                        <i className="ri-user-2-fill"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 overflow-hidden">
                                                                    <p className="mb-1">Channel Type :</p>
                                                                    <h6 className="text-truncate mb-0">{videoData?.channel_name}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4">
                                                            <div className="d-flex mt-4">
                                                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                        <i className="ri-global-line"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 overflow-hidden">
                                                                    <p className="mb-1">Live Link :</p>
                                                                    <h6 className="text-truncate mb-0">{videoData?.video_link || "Not Available"}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4">
                                                            <div className="d-flex mt-4">
                                                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                        <i className="ri-global-line"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 overflow-hidden">
                                                                    <p className="mb-1">Final Price :</p>
                                                                    <h6 className="text-truncate mb-0">{videoData?.final_video_cost}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4">
                                                            <div className="d-flex mt-4">
                                                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                        <i className="ri-global-line"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 overflow-hidden">
                                                                    <p className="mb-1">Influencer Name :</p>
                                                                    <h6 className="text-truncate mb-0">{videoData?.influencer_name}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="remarks" role="tabpanel">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center mb-4">
                                                <h5 className="card-title flex-grow-1 mb-0">Remarks</h5>
                                                <div className="flex-shrink-0">

                                                    <Button className="btn btn-success" onClick={handleShowRemark}><i
                                                        className="ri-add-line align-bottom me-1"></i>
                                                        Add Remarks
                                                    </Button>
                                                </div>
                                            </div>

                                            <div className="acitivity-timeline">
                                                {showRemarkListDetails?.length > 0 ? (
                                                    showRemarkListDetails.map((remarkData, index) => (
                                                        <div className="acitivity-item d-flex" key={index}>
                                                            <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                                                                <div className="avatar-title bg-success-subtle text-success rounded-circle">
                                                                    <i className="ri-user-3-fill"></i>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h6 className="mb-1">
                                                                    From: {remarkData?.sender_name}

                                                                </h6>
                                                                <small className="text-muted">To: {remarkData?.receiver_name}</small>
                                                                <p className="mb-0 text-muted">
                                                                    {remarkData?.remarks || "No remark details available."}
                                                                </p>
                                                                <p className="mt-2 text-muted"><small>{formatDateTime(remarkData?.created_at)}</small></p>

                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div>No remarks available</div>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="documents" role="tabpanel">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center mb-4">
                                                <h5 className="card-title flex-grow-1 mb-0">Documents</h5>

                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="table-responsive">
                                                        <table className="table table-borderless align-middle mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th scope="col">File Name</th>
                                                                    <th scope="col">Type</th>
                                                                    {/* <th scope="col">Size</th> */}
                                                                    <th scope="col">Upload Date</th>
                                                                    <th scope="col">Upload by</th>
                                                                    {/* <th>Status</th> */}
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {remarkDocListDetails?.length > 0 ? (
                                                                    remarkDocListDetails.map((remarkDataDocs, index) => {
                                                                        const fileName = remarkDataDocs?.file_name;
                                                                        const extension = getFileExtension(fileName);
                                                                        const fileType = getFileType(extension);
                                                                        const fileSize = getFileSize(5000000); // Example: assuming 5MB (you can fetch this dynamically)

                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div className="avatar-sm">
                                                                                            <div className="avatar-title bg-primary-subtle text-primary rounded fs-20">
                                                                                                <i className="ri-file-fill"></i>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="ms-3 flex-grow-1">
                                                                                            <h6 className="fs-15 mb-0"><a href="javascript:void(0)">{fileName}</a></h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{fileType}</td>
                                                                                {/* <td>{fileSize}</td> */}
                                                                                <td>{formatDate(remarkDataDocs?.created_at)}</td>
                                                                                <td>{remarkDataDocs?.sender_name}</td>
                                                                                {/* <td><span className="badge bg-info">Pending</span></td> */}
                                                                                <td>
                                                                                    <ul className="list-inline hstack mb-0">
                                                                                        <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                                                            data-bs-placement="top" aria-label="Download"
                                                                                            data-bs-original-title="Download">
                                                                                            <button className="btn btn-sm btn-soft-info" onClick={() => handleDownload(remarkDataDocs?.file_name)}>
                                                                                                <i className="ri-download-2-fill align-bottom fs-16"></i>
                                                                                            </button>
                                                                                        </li>

                                                                                        {/* <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                                                            data-bs-placement="top" aria-label="Approve" data-bs-original-title="Approve">
                                                                                            <button className="btn btn-sm btn-soft-success" data-bs-toggle="modal" data-bs-target="#approveDocumentModal" data-approve-id="14"><i className="ri-checkbox-circle-fill align-bottom fs-16"></i></button>
                                                                                        </li>
                                                                                        <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                                                            data-bs-placement="top" aria-label="Reject" data-bs-original-title="Reject">
                                                                                            <button className="btn btn-sm btn-soft-danger" data-bs-toggle="modal" data-bs-target="#rejectDocumentModal" data-reject-id="14"><i className="ri-close-circle-fill align-bottom fs-16"></i></button>
                                                                                        </li> */}
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <div>No remarks available</div>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {/* <div className="text-center mt-3">
                                                        <a href="javascript:void(0);" className="text-success"><i
                                                            className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> Load more </a>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <script>document.write(new Date().getFullYear())</script> © Influencer Act.
                        </div>
                        <div className="col-sm-6">
                            <div className="text-sm-end d-none d-sm-block">
                                Design & Develop by
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <VideoRemak
                showRemarkModal={showRemarkModal}
                setShowRemarkModal={setShowRemarkModal}
                videoData={videoData
                }
            />
        </>
    );
};

export default ClientVideoDetails;
