// src/App.js
import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Dashboard from './components/dashboard/Dashboard'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './components/ProtectedRoute';
import Profile from './features/profile/Profile';
import LayoutDefault from './layout/LayoutDefault';
import ClientSignUp from './components/clientSignup/ClientSignUp';
import SignUp from './components/signup/SignUp';
import AssignedClients from './components/clientSignup/AssignedClients';
import ActiveClients from './components/clientSignup/ActiveClients';
import InactiveClients from './components/clientSignup/InactiveClients';
import AllClients from './components/clientSignup/AllClients';
import PendingClients from './components/clientSignup/PendingClients';
import { fetchClientData } from "./features/clientSlice/clientSlice";
import Unauthorized from './components/Unauthorized';
import RejectedInfluencers from './components/clientInfluencer/RejectedInfluencers';
import SuggestedInfluencers from './components/clientInfluencer/SuggestedInfluencers';
import ApprovedInfluencers from './components/clientInfluencer/ApprovedInfluencers';
import ActiveInfluencer from './components/dmExecutive/ActiveInfluencer';
import InactiveInfluencer from './components/dmExecutive/InactiveInfluencer';
import AllInfluencer from './components/dmExecutive/AllInfluencer';
import DetailInfluencer from './components/dmExecutive/DetailInfluencer';
import PendingInfluencer from './components/dmExecutive/PendingInfluencer';
import AssignedInfluencer from './components/dmExecutive/AssignedInfluencer';

import ClientRequest from './components/clientRequest/ClientRequest';
import DetailClients from './components/clientSignup/DetailClients';
import InfuencerRequest from './components/dmRequest/InfuencerRequest';
import ChangePassword from './components/profile/ChangePassword';
import ClientInfluencerRequest from './components/clientInfluencerRequest/ClientInfluencerRequest';
import ClientInfluencerRequestDetails from './components/clientInfluencerRequest/ClientInfluencerRequestDetails';
import ClientInfluencerRequestDmTLDetails from './components/clientInfluencerRequest/ClientInfluencerRequestDmTLDetails';
import SugestedInfluencerDetails from './components/clientInfluencerRequest/SugestedInfluencerDetails';
import SugestedInfluencer from './components/clientInfluencerRequest/SugestedInfluencer';
import SuggestedInfluencerBySales from './components/clientInfluencerRequest/SuggestedInfluencerBySales';
import SuggestedInfluencerBySalesDetails from './components/clientInfluencerRequest/SuggestedInfluencerBySalesDetails';
import ClientInfluencerRequestDmTL from './components/clientInfluencerRequest/ClientInfluencerRequestDmTL';
import ApprovedUpdateInfluencers from './components/clientInfluencer/ApprovedUpdateInfluencers';
import DetailRequestClient from './components/dmExecutive/DetailRequestClient';
import SuggestedRequestDetails from './components/dmExecutive/SuggestedRequestDetails';
import RequestInfluencerDetails from './components/dmExecutive/RequestInfluencerDetails';
import UpcomingVideo from './components/videos/UpcomingVideo';
import ClientVideoDetails from './components/videos/ClientVideoDetails';
import DmTlSuggestedInfluencer from './components/clientInfluencerRequest/DmTlSuggestedInfluencer';
import SuggestedDmTlInfluencerDetails from './components/clientInfluencerRequest/SuggestedDmTlInfluencerDetails';
import AccessInfRequest from './components/clientInfluencer/AccessInfRequest';
import InfluencerUpdate from './components/dmExecutive/InfluencerUpdate';


function PageTitleUpdater() {
  const location = useLocation();

  useEffect(() => {
    // Map route path to the title
    const titles = {
      '/': 'Home',
      '/dashboard': 'Dashboard',
      '/profile': 'Profile',
      '/client-sign-up': 'Client Sign Up',
      '/sign-up': 'Sign Up',
      '/client-all-request': 'All Requests',
      '/suggested-influencer-by-sales': 'Suggested Influencers',
      '/approved-suggested-influencer-by-sales': 'Approved Influencers',
      '/rejected-suggested-influencer-by-sales': 'Rejected Influencers',
      '/upcoming-videos': 'Upcoming Videos',
      '/post-videos': 'Post Videos',
      '/all-videos': 'All Videos',
      '/pending-clients': 'Pending Clients',
      '/client-request-in-sales': 'All Clients Request',
      '/suggested-influencer-list': 'Suggested Influencers',
      '/approved-suggested-influencer-list': 'Approved Influencers',
      '/rejected-suggested-influencer-list': 'Rejected Influencers',
      '/assigned-influencer': 'Assigned Influencer',
      '/client-request-in-dm': 'All Client Request',
      '/suggested-influencer': 'Influencer Request List',
      '/client-sub-request-in-dm-tl': 'Suggested Influencer',
      '/approved-client-sub-request-in-dm-tl': 'Approved Influencer',
      '/rejected-client-sub-request-in-dm-tl': 'Rejected Influencer',
      '/influencer-access-request-in-dm-tl': 'All Videos',
      '/assigned-influencer': 'Assigned Influencer',
      '/suggested-influencer': 'Influencer Request List',
      '/rejected-influencers': 'Rejected Influencers',
      '/suggested-influencers': 'Suggested Influencers',
      '/assigned-clients': 'Assigned Clients',
      '/active-clients': 'Active Clients',
      '/inactive-clients': 'Inactive Clients',
      '/all-clients': 'All Clients',
      '/active-influencer': 'Active Influencer',
      '/inactive-influencer': 'Inactive Influencer',
      '/pending-influencer': 'Pending Influencer',
      '/all-influencer': 'All Influencers',
      '/unauthorized': 'Unauthorized',
      // Add more routes as needed...
    };

    // Set the document title based on the current location
    document.title = titles[location.pathname] || 'My Application'; // Default title if no match

  }, [location]);

  return null;
}


function App() {


  return (
    <>

      <ToastContainer />
      <PageTitleUpdater />
      <Routes>
        {/* Login */}
        <Route path="/" element={<LayoutDefault />} />
        {/* New User Register */}
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/client-sign-up" element={<ClientSignUp />} />

        <Route path="/client-sign-up/website" element={<ClientSignUp />} />
        <Route path="/client-sign-up/referal_id/:id" element={<ClientSignUp />} />
        {/* Dashboard */}
        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        {/* Setting */}
        <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
        <Route path="/change-password" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
        {/* Error Page */}
        <Route path="/unauthorized" element={<Unauthorized />} />

        <Route path='/influencer-update-details/:id' element={<ProtectedRoute><InfluencerUpdate /></ProtectedRoute>} />
        {/* Other Page */}
        <Route path="/client-all-request" element={<ProtectedRoute><ApprovedInfluencers /></ProtectedRoute>} />
        <Route path="/client-update-request" element={<ProtectedRoute><ApprovedUpdateInfluencers /></ProtectedRoute>} />
        <Route path="/rejected-influencers" element={<ProtectedRoute><RejectedInfluencers /></ProtectedRoute>} />
        <Route path="/suggested-influencers" element={<ProtectedRoute><SuggestedInfluencers /></ProtectedRoute>} />
        <Route path="/assigned-clients" element={<ProtectedRoute><AssignedClients /></ProtectedRoute>} />
        <Route path="/active-clients" element={<ProtectedRoute><ActiveClients /></ProtectedRoute>} />
        <Route path="/pending-clients" element={<ProtectedRoute><PendingClients /></ProtectedRoute>} />
        <Route path="/inactive-clients" element={<ProtectedRoute><InactiveClients /></ProtectedRoute>} />
        <Route path="/all-clients" element={<ProtectedRoute><AllClients /></ProtectedRoute>} />
        <Route path="/active-influencer" element={<ProtectedRoute><ActiveInfluencer /></ProtectedRoute>} />
        <Route path="/inactive-influencer" element={<ProtectedRoute><InactiveInfluencer /></ProtectedRoute>} />
        <Route path="/pending-influencer" element={<ProtectedRoute><PendingInfluencer /></ProtectedRoute>} />
        <Route path="/all-influencer" element={<ProtectedRoute><AllInfluencer /></ProtectedRoute>} />
        <Route path="/assigned-influencer" element={<ProtectedRoute><AssignedInfluencer /></ProtectedRoute>} />
        <Route path="/client-request-in-sales" element={<ProtectedRoute><ClientRequest /></ProtectedRoute>} />
        <Route path="/client-details/:id" element={<ProtectedRoute><DetailClients /></ProtectedRoute>} />
        <Route path="/influencer-details/:id" element={<ProtectedRoute><DetailInfluencer /></ProtectedRoute>} />
        <Route path="/client-request-details/:id" element={<ProtectedRoute><DetailRequestClient /></ProtectedRoute>} />
        <Route path="/client-request-in-dm" element={<ProtectedRoute><InfuencerRequest /></ProtectedRoute>} />

        <Route path="/suggested-influencer" element={<ProtectedRoute><ClientInfluencerRequest /></ProtectedRoute>} />
        <Route path="/approved-influencer" element={<ProtectedRoute><ClientInfluencerRequest /></ProtectedRoute>} />
        <Route path="/rejected-influencer" element={<ProtectedRoute><ClientInfluencerRequest /></ProtectedRoute>} />

        <Route path="/client-influencer-request-details/:id" element={<ProtectedRoute><ClientInfluencerRequestDetails /></ProtectedRoute>} />

        <Route path="/client-sub-request-in-dm-tl" element={<ProtectedRoute><ClientInfluencerRequestDmTL /></ProtectedRoute>} />
        <Route path="/approved-client-sub-request-in-dm-tl" element={<ProtectedRoute><ClientInfluencerRequestDmTL /></ProtectedRoute>} />
        <Route path="/rejected-client-sub-request-in-dm-tl" element={<ProtectedRoute><ClientInfluencerRequestDmTL /></ProtectedRoute>} />
        <Route path="/client-influencer-request-details-dm-tl/:id" element={<ProtectedRoute><ClientInfluencerRequestDmTLDetails /></ProtectedRoute>} />
        <Route path="/approved-client-influencer-request-details-dm-tl/:id" element={<ProtectedRoute><ClientInfluencerRequestDmTLDetails /></ProtectedRoute>} />
        <Route path="/rejected-client-influencer-request-details-dm-tl/:id" element={<ProtectedRoute><ClientInfluencerRequestDmTLDetails /></ProtectedRoute>} />



        <Route path="/suggested-influencer-list" element={<ProtectedRoute><SugestedInfluencer /></ProtectedRoute>} />
        <Route path="/approved-suggested-influencer-list" element={<ProtectedRoute><SugestedInfluencer /></ProtectedRoute>} />
        <Route path="/rejected-suggested-influencer-list" element={<ProtectedRoute><SugestedInfluencer /></ProtectedRoute>} />


        <Route path="/suggested-influencer-details/:id" element={<ProtectedRoute><SugestedInfluencerDetails /></ProtectedRoute>} />
        <Route path="/approved-suggested-influencer-details/:id" element={<ProtectedRoute><SugestedInfluencerDetails /></ProtectedRoute>} />
        <Route path="/rejected-suggested-influencer-details/:id" element={<ProtectedRoute><SugestedInfluencerDetails /></ProtectedRoute>} />

        <Route path="/suggested-dmtl-influencer-list" element={<ProtectedRoute><DmTlSuggestedInfluencer /></ProtectedRoute>} />
        <Route path="/approved-dmtl-suggested-influencer-list" element={<ProtectedRoute><DmTlSuggestedInfluencer /></ProtectedRoute>} />
        <Route path="/rejected-dmtl-suggested-influencer-list" element={<ProtectedRoute><DmTlSuggestedInfluencer /></ProtectedRoute>} />
        <Route path="/suggested-dmtl-influencer-details/:id" element={<ProtectedRoute><SuggestedDmTlInfluencerDetails /></ProtectedRoute>} />
        <Route path="/approved-dmtl-suggested-influencer-details/:id" element={<ProtectedRoute><SuggestedDmTlInfluencerDetails /></ProtectedRoute>} />
        <Route path="/rejected-dmtl-suggested-influencer-details/:id" element={<ProtectedRoute><SuggestedDmTlInfluencerDetails /></ProtectedRoute>} />

        <Route path="/suggested-influencer-details_by_unique/:id" element={<ProtectedRoute><SuggestedRequestDetails /></ProtectedRoute>} />
        <Route path="/approved-suggested-influencer-details_by_unique/:id" element={<ProtectedRoute><SuggestedRequestDetails /></ProtectedRoute>} />
        <Route path="/rejected-suggested-influencer-details_by_unique/:id" element={<ProtectedRoute><SuggestedRequestDetails /></ProtectedRoute>} />

        <Route path="/influencer-request-details" element={<ProtectedRoute><RequestInfluencerDetails /></ProtectedRoute>} />


        <Route path="/suggested-influencer-by-sales" element={<ProtectedRoute><SuggestedInfluencerBySales /></ProtectedRoute>} />
        <Route path="/approved-suggested-influencer-by-sales" element={<ProtectedRoute><SuggestedInfluencerBySales /></ProtectedRoute>} />
        <Route path="/rejected-suggested-influencer-by-sales" element={<ProtectedRoute><SuggestedInfluencerBySales /></ProtectedRoute>} />
        <Route path="/suggested-influencer-by-sales-details/:id" element={<ProtectedRoute><SuggestedInfluencerBySalesDetails /></ProtectedRoute>} />
        <Route path="/approved-suggested-influencer-by-sales-details/:id" element={<ProtectedRoute><SuggestedInfluencerBySalesDetails /></ProtectedRoute>} />
        <Route path="/rejected-suggested-influencer-by-sales-details/:id" element={<ProtectedRoute><SuggestedInfluencerBySalesDetails /></ProtectedRoute>} />

        <Route path="/suggested-influencer-by-sales-details_by_unique/:id" element={<ProtectedRoute><SuggestedRequestDetails /></ProtectedRoute>} />
        <Route path="/approved-suggested-influencer-by-sales-details_by_unique/:id" element={<ProtectedRoute><SuggestedRequestDetails /></ProtectedRoute>} />
        <Route path="/rejected-suggested-influencer-by-sales-details_by_unique/:id" element={<ProtectedRoute><SuggestedRequestDetails /></ProtectedRoute>} />

        <Route path="/upcoming-videos" element={<ProtectedRoute><UpcomingVideo /></ProtectedRoute>} />
        <Route path="/post-videos" element={<ProtectedRoute><UpcomingVideo /></ProtectedRoute>} />
        <Route path="/all-videos" element={<ProtectedRoute><UpcomingVideo /></ProtectedRoute>} />
        <Route path="/upcoming-video-details/:id" element={<ProtectedRoute><ClientVideoDetails /></ProtectedRoute>} />
        <Route path="/post-video-details/:id" element={<ProtectedRoute><ClientVideoDetails /></ProtectedRoute>} />
        <Route path="/all-video-details/:id" element={<ProtectedRoute><ClientVideoDetails /></ProtectedRoute>} />


        <Route path="/influencer-access-request-in-dm-tl" element={<ProtectedRoute><AccessInfRequest /></ProtectedRoute>} />



      </Routes>
    </>
  );
}

export default App;
