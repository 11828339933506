import React, { useEffect } from "react";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer } from "react-toastify";

const ViewRequestDetails = (props) => {
    const { showDetailModal, setShowDetailModal, dataRowRecord } = props;


    const handleClose = () => setShowDetailModal(false);


    return (
        <>
            <Modal size="lg" show={showDetailModal} onHide={handleClose} backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Details Display For Request ID: {dataRowRecord?.req_code}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-bordered table-hover table-striped">
                        <thead>
                            <tr>
                                <th>Channel Type</th>
                                <th>Subscriber / Followers</th>
                                <th>Language</th>
                                <th>Min Budget</th>
                                <th>Max Budget</th>
                                <th>Vedio Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataRowRecord?.channel_data_show?.map((channelData, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{channelData?.ch_type}</td>
                                        <td>{channelData?.follower_subscriber?.label}</td>
                                        <td>{channelData?.language?.map(lang => lang?.label).join(", ")}</td>
                                        <td>{channelData?.min_budget}</td>
                                        <td>{channelData?.max_budget}</td>
                                        <td>{channelData?.video_type?.map(vid => vid?.label).join(", ")}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Modal.Body>

            </Modal>
        </>
    );
};

export default ViewRequestDetails;
