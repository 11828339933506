import React, { useEffect, useState } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ClientAssigntosale } from "../../features/commonSlice/commonSlice";
import { fetchSalesteam } from "../../features/commonSlice/commonSlice";

const Assigntosale = (props) => {
  const { showModal, setShowModal, selectedData } = props;
  const [formData, setFormData] = useState({
    assigned_to: []
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { salesData, status: commonStatus } = useSelector(
    (state) => state.influencer
  );
  const [salesList, setSalesList] = useState([]);
  const [errors, setErrors] = useState({});
  const [errorData, setErrorData] = useState([]);
  const [loginError, setLoginError] = useState("");

  useEffect(() => {
    if (selectedData) {
      setFormData({
        assigned_to:
          selectedData?.salesteam?.map((steam) => ({
            label: steam.label,
            value: steam.value
          })) || []
      });
    }
  }, [selectedData]);

  useEffect(() => {
    dispatch(fetchSalesteam());
  }, []);

  useEffect(() => {
    if (commonStatus === "succeeded") {
      setSalesList(salesData?.data || []);
    }
  }, [commonStatus, salesData]);

  const handleSelectChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: null // Clear the error for the field being changed
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      const salesTeamData = {
        client_id: selectedData?.id,
        assigned_to: formData.assigned_to
      };
      console.log("clientData ==>", salesTeamData);
      dispatch(ClientAssigntosale(salesTeamData))
        .unwrap()
        .then((result) => {
          if (result.status) {
            toast.success(result.message, {
              onClose: () => {
              }
            });
            setShowModal(false);
          } else {
            setLoginError(result.message);
            setErrorData(result.form_err);
          }
        })
        .catch((err) => {
          setLoginError(err.message);
          setErrorData(err.form_err || []);
          toast.error(err.message);
        });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const mapErrors = (errorData) => {
    const errorMap = {};
    errorData.forEach((error) => {
      if (!errorMap[error.param]) {
        errorMap[error.param] = error.msg;
      }
    });
    return errorMap;
  };

  useEffect(() => {
    const errorsValue = mapErrors(errorData);
    setErrors(errorsValue);
  }, [errorData]);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="ModalHeader"
        size="lg"
        backdrop="static" // Prevents closing on backdrop click
        keyboard={false} // Prevents closing on ESC key press
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign to Sale12345</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="p-0">
                    <table className="table table-striped table-bordered">
                      <tbody>
                        <tr>
                          <td className="w-25 p-3">Client Name </td>
                          <td>{selectedData?.company_name} </td>
                        </tr>
                        <tr>
                          <td className="w-25 p-3">Sales Teams</td>
                          <td>
                            <Select
                              name="assigned_to"
                              options={salesList}
                              classNamePrefix="select"
                              onChange={(selected) =>
                                handleSelectChange("assigned_to", selected)
                              }
                              value={formData.assigned_to}
                            />
                            <div className="text-danger">
                              {" "}
                              {errors.assigned_to && <> {errors.assigned_to}</>}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="button" variant="primary" onClick={handleSubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Assigntosale;
