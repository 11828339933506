import React, { useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import {
  Button,
  Container,
  Row,
  Col,
  Offcanvas,
  Form,
  InputGroup
} from "react-bootstrap";
import {
  fetchCategoryData,
  fetchLanguageData
} from "../../features/commonSlice/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Filter = (props) => {
  

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showFiltersDrawer, setShowFiltersDrawer, formData, setFormData, handleFilter } = props;
  const [showFilters, setShowFilters] = useState(false);
  const [langList, setLangList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const {
    categoryData,
    languageData,
    status: commonStatus
  } = useSelector((state) => state.influencer);

  useEffect(() => {
    dispatch(fetchCategoryData());
    dispatch(fetchLanguageData());
  }, [dispatch]);

  useEffect(() => {
    if (commonStatus === "succeeded") {
      setCategoryList(categoryData);
      setLangList(languageData);
    }
  }, [commonStatus, categoryData, languageData]);

  const handleSelectChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleInputChange = (e, key, type) => {
    const { value } = e.target;
    if (type === "number") {
      setFormData({
        ...formData,
        [key]: value
      });
    } else if (type === "range") {
      setFormData({
        ...formData,
        [key]: {
          ...formData[key],
          [e.target.dataset.range]: value
        }
      });
    }
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      channel_name: checked
        ? [...prev.channel_name, value]
        : prev.channel_name.filter((item) => item !== value)
    }));
  };


  
  return (
    <Offcanvas
      show={showFiltersDrawer}
      onHide={() => setShowFiltersDrawer(false)}
      placement="end"
      style={{ width: "500px" }}
      className="m-0"
    >
      <Offcanvas.Header closeButton className="border-bottom">
        <h5>Active Influencers Filters</h5>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <form
          action="#"
          className="d-flex flex-column justify-content-end h-100"
        >
          <div className="offcanvas-body">
            <div className="mb-1">
              <label
                htmlFor="language-select"
                className="form-label text-muted text-uppercase fw-semibold mb-2 small"
              >
                Language
              </label>
              <Select
                isMulti
                name="languages"
                options={langList?.data}
                classNamePrefix="select"
                onChange={(selected) =>
                  handleSelectChange("languages", selected)
                }
                value={formData.languages}
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="status-select"
                className="form-label text-muted text-uppercase fw-semibold mb-2 small"
              >
                Channel
              </label>
              <div className="row g-2">
                {["Youtube", "Instagram", "Facebook", "Linkedin"].map((channel, index) => (
                  <div className="col-lg-6" key={index}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`inlineCheckbox${index}`}
                        value={channel}
                        checked={formData.channel_name.includes(channel)}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor={`inlineCheckbox${index}`}>
                        {channel}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="budget"
                className="form-label text-muted text-uppercase fw-semibold mb-2  small"
              >
                Budget
              </label>
              <div className="row g-2 align-items-center">
                <div className="col-lg">
                  <input
                    type="number"
                    className="form-control"
                    id="budget-min"
                    placeholder="0"
                    data-range="min"
                    value={formData.budget.min}
                    onChange={(e) => handleInputChange(e, "budget", "range")}
                  />
                </div>
                <div className="col-lg-auto">To</div>
                <div className="col-lg">
                  <input
                    type="number"
                    className="form-control"
                    id="budget-max"
                    placeholder="0"
                    data-range="max"
                    value={formData.budget.max}
                    onChange={(e) => handleInputChange(e, "budget", "range")}
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="select-categories"
                className="form-label text-muted text-uppercase fw-semibold mb-2 small"
              >
                Categories
              </label>
              <Select
                isMulti
                name="categories"
                options={categoryList?.data}
                classNamePrefix="select"
                onChange={(selected) =>
                  handleSelectChange("categories", selected)
                }
                value={formData.categories}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="subscribers"
                className="form-label text-muted text-uppercase fw-semibold mb-2 small"
              >
                Subscribers
              </label>
              <div className="row g-2 align-items-center">
                <div className="col-lg">
                  <input
                    type="number"
                    className="form-control"
                    id="subscribers-min"
                    placeholder="0"
                    data-range="min"
                    value={formData.subscribers.min}
                    onChange={(e) => handleInputChange(e, "subscribers", "range")}
                  />
                </div>
                <div className="col-lg-auto">To</div>
                <div className="col-lg">
                  <input
                    type="number"
                    className="form-control"
                    id="subscribers-max"
                    placeholder="0"
                    data-range="max"
                    value={formData.subscribers.max}
                    onChange={(e) => handleInputChange(e, "subscribers", "range")}
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="views"
                className="form-label text-muted text-uppercase fw-semibold mb-2 small"
              >
                Views
              </label>
              <div className="row g-2 align-items-center">
                <div className="col-lg">
                  <input
                    type="number"
                    className="form-control"
                    id="views-min"
                    placeholder="0"
                    data-range="min"
                    value={formData.views.min}
                    onChange={(e) => handleInputChange(e, "views", "range")}
                  />
                </div>
                <div className="col-lg-auto">To</div>
                <div className="col-lg">
                  <input
                    type="number"
                    className="form-control"
                    id="views-max"
                    placeholder="0"
                    data-range="max"
                    value={formData.views.max}
                    onChange={(e) => handleInputChange(e, "views", "range")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 offcanvas-footer border-top text-center hstack gap-2 small">
            <button
              type="button"
              className="btn btn-light w-100 small"
              onClick={() => setFormData({
                channel_name: [],
                categories: [],
                languages: [],
                budget: { min: "", max: "" },
                subscribers: { min: "", max: "" },
                views: { min: "", max: "" }
              })}
            >
              Clear Filter
            </button>
            <button type="button" className="btn btn-success w-100  small" onClick={handleFilter}>
              Filters
            </button>
          </div>
        </form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Filter;
