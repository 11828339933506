import React from "react";
import { Button, Modal } from "react-bootstrap";
import { fetchClientDeleteData } from "../../features/clientInfluencerRequestSlice/clientInfluencerRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Required for Toastify styles

const CancelledRequest = (props) => {
  const { showDeleteModal, selectedData, setSelectedData, setShowDeleteModal } = props;
  const dispatch = useDispatch();
  const { clientDataRequest, status, error } = useSelector(
    (state) => state.influencerRequest
  );

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDelete = () => {
    // Validate if selectedData is valid
    if (!selectedData?.id) {
      toast.error("Invalid data selected for deletion.");
      return;
    }

    const payload = {
      inf_req_id: selectedData.id,
      status: "12",
    };

    dispatch(fetchClientDeleteData(payload))
      .unwrap()
      .then((result) => {
        if (result?.data?.status) {
          toast.success(result.message || "Item cancelled successfully!", {
            onClose: () => { }, // Close modal on success
          });
          closeModal()
        } else {
          toast.error(result.message || "Failed to canceled item.");
        }
      })
      .catch((err) => {
        console.error("Error occurred:", err);
        toast.error(err.message || "An unexpected error occurred.");
      });
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setSelectedData(null);
  };

  return (
    <>
      <Modal show={showDeleteModal} onHide={cancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={cancelDelete}>
            No
          </Button>
          <Button variant="success" onClick={confirmDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CancelledRequest;
