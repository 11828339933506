import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clientRequestDetails, influencerDetails } from "../../features/influencerRequestSlice/influencerRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../utils/dateUtils";
import { format } from "date-fns";
import useDecodedToken from "../../features/utils/hooks/useDecodedToken";
// import dayjs from 'dayjs';

const RequestInfluencerDetails = (props) => {
  const decodeToken = useDecodedToken();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const selectedData = location.state;
  console.log("selectedDataselectedDataselectedDataselectedData clell ==>", selectedData);

  const { clientsRequestDetails, status, message } = useSelector(
    (state) => state.requestInfluencerDetails
  );
  const [toastDisplayed, setToastDisplayed] = useState(false);
  const [infDetails, setInfDetails] = useState("");


  useEffect(() => {
    if (selectedData?.id) {
      const payload = {
        inf_req_id: selectedData?.id
      };
      dispatch(clientRequestDetails(payload));
    }
  }, [dispatch, selectedData, !toastDisplayed]);

  useEffect(() => {
    setInfDetails(selectedData);
  }, [selectedData]);

  const handleBackButton = () => {
    navigate(`/all-influencer`);
  };

  useEffect(() => {
    console.log("influencerDataDetails ==>", clientsRequestDetails);

  }, [clientsRequestDetails])

  return (
    <>
      <div className="container-fluid">
        <div className="profile-foreground position-relative mx-n4 mt-n4">
          <div className="profile-wid-bg">
            <img
              src="../assets/images/profile-bg.jpg"
              alt=""
              className="profile-wid-img"
            />
          </div>
        </div>
        <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
          <div className="row g-4">
            <div className="col-auto">
              <div className="avatar-lg">
                <img
                  src="../assets/images/channels4_profile.jpg  "
                  alt="user-img"
                  className="img-thumbnail"
                />
              </div>
            </div>
            <div className="col">
              <div className="p-2">
                <h3 className="text-white mb-1">Invest Today</h3>
                <p className="text-white text-opacity-75">
                  <div className="badge rounded-pill bg-success fs-12">
                    Active
                  </div>
                </p>
                <div className="hstack text-white-50 gap-1">
                  <div className="me-2 hstack gap-3 flex-wrap">
                    <div>
                      Created Date:{" "}
                      <span className="fw-medium">
                        {formatDate(infDetails?.created_at)}
                      </span>
                    </div>
                  </div>
                  <div className="me-2">
                    <div>
                      Onboarded Date:{" "}
                      <span className="fw-medium">29 Dec, 2025</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div>
              <div className="d-flex profile-wrapper">
                <ul
                  className="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link fs-14 active"
                      data-bs-toggle="tab"
                      href="#overview-tab"
                      role="tab"
                    >
                      <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
                      <span className="d-none d-md-inline-block">Overview</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fs-14" data-bs-toggle="tab" href="#remarks" role="tab">
                      <i className="ri-price-tag-line d-inline-block d-md-none"></i> <span
                        className="d-none d-md-inline-block">Remarks</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content pt-4 text-muted">
                <div
                  className="tab-pane active"
                  id="overview-tab"
                  role="tabpanel"
                >
                  <div className="row">
                    <div className="col-xxl-12">
                      <div className="card">
                        <div className="card-header align-items-center d-flex">
                          <h4 className="card-title mb-0  me-2">
                            Basic Details 
                          </h4>
                        </div>
                        <div className="card-body">
                          <div className="row">
                          <div className="col-6 col-md-4">
                              <div className="d-flex mt-4">
                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                  <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                    <i className="ri-user-2-fill"></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="mb-1">Request code :</p>
                                  <h6 className="text-truncate mb-0">
                                   {selectedData?.req_code}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 col-md-4">
                              <div className="d-flex mt-4">
                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                  <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                    <i className="ri-user-2-fill"></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="mb-1">Client Name :</p>
                                  <h6 className="text-truncate mb-0">
                                   {selectedData?.ClientName}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 col-md-4">
                              <div className="d-flex mt-4">
                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                  <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                    <i className="ri-global-line"></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="mb-1">No of Influencer :</p>
                                  <h6 className="text-truncate mb-0">
                                    {selectedData?.no_of_influencer}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 col-md-4">
                              <div className="d-flex mt-4">
                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                  <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                    <i className="ri-global-line"></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="mb-1">Product :</p>
                                  <h6 className="text-truncate mb-0">
                                  {selectedData?.product}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 col-md-4">
                              <div className="d-flex mt-4">
                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                  <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                    <i className="ri-user-2-fill"></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="mb-1">StatusL :</p>
                                  <h6 className="text-truncate mb-0">
                                    {selectedData?.show_status}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 col-md-4">
                              <div className="d-flex mt-4">
                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                  <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                    <i className="ri-global-line"></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="mb-1">Budget :</p>
                                  <h6 className="text-truncate mb-0">
                                    {selectedData?.budget}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Scheduled Videos</h5>
                          <div className="swiper project-swiper mt-n4">
                            <div className="d-flex justify-content-end gap-2 mb-2">
                              <div className="slider-button-prev">
                                <div className="avatar-title fs-18 rounded px-1">
                                  <i className="ri-arrow-left-s-line"></i>
                                </div>
                              </div>
                              <div className="slider-button-next">
                                <div className="avatar-title fs-18 rounded px-1">
                                  <i className="ri-arrow-right-s-line"></i>
                                </div>
                              </div>
                            </div>
                            <div className="swiper-wrapper">

                              <div className="swiper-slide">
                                <div className="card profile-project-card shadow-none profile-project-warning mb-0">
                                  <div className="card-body p-4">
                                    <div className="d-flex">
                                      <div className="flex-grow-1 text-muted overflow-hidden">
                                        <h5 className="fs-14 text-truncate mb-1">

                                          NA
                                        </h5>

                                      </div>

                                    </div>

                                    <div className="d-flex mt-4">
                                      <div className="flex-grow-1">
                                        <div className="d-flex align-items-center gap-2">
                                          <div>
                                            <h5 className="fs-12 text-muted mb-0">
                                              {" "}
                                              Client :
                                            </h5>
                                          </div>
                                          NA
                                        </div>
                                        <div className="d-flex align-items-center gap-2">
                                          <div>
                                            <h5 className="fs-12 text-muted mb-0">
                                              {" "}
                                              Video id :
                                            </h5>
                                          </div>
                                          NA
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="remarks" role="tabpanel">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-4">
                        <h5 className="card-title flex-grow-1 mb-0">Remarks</h5>
                        <div className="flex-shrink-0">

                          <a href="#" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#remarksModal"><i
                            className="ri-add-line align-bottom me-1"></i> Add
                            Remarks</a>
                        </div>
                      </div>

                      <div className="acitivity-timeline">
                        <div className="acitivity-item d-flex">
                          <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                            <div className="avatar-title bg-success-subtle text-success rounded-circle">
                              <i className="ri-user-3-fill"></i>
                            </div>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h6 className="mb-1">From: Oliver Phillips <span
                              className="badge bg-primary-subtle text-primary align-middle">New</span></h6>
                            <small className="text-muted">To: Invests Today</small>
                            <p className="text-muted mb-2">We talked about a project on linkedin.</p>
                            <small className="mb-0 text-muted">Today</small>
                            <ul className="list-inline hstack gap-2 mt-2 remarks-action">

                              <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                                data-bs-placement="top" aria-label="Approve" data-bs-original-title="Approve">
                                <button className="btn btn-sm btn-soft-success" data-bs-toggle="modal"
                                  data-bs-target="#approveDocumentModal" data-approve-id="14"><i
                                    className="ri-checkbox-circle-fill align-bottom fs-16"></i></button>
                              </li>
                              <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                                data-bs-placement="top" aria-label="Reject" data-bs-original-title="Reject">
                                <button className="btn btn-sm btn-soft-danger" data-bs-toggle="modal"
                                  data-bs-target="#rejectDocumentModal" data-reject-id="14"><i
                                    className="ri-close-circle-fill align-bottom fs-16"></i></button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="acitivity-item py-3 d-flex">
                          <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                            <div className="avatar-title bg-success-subtle text-success rounded-circle">
                              <i className="ri-user-3-fill"></i>
                            </div>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h6 className="mb-1">From: Invests Today</h6>
                            <small className="text-muted">To: PolicyChayan</small>
                            <p className="text-muted mb-2"><i className="ri-file-text-line align-middle ms-2"></i> Create
                              new
                              project Buildng product</p>
                            <small className="mb-0 text-muted">Yesterday</small>
                          </div>
                        </div>
                        <div className="acitivity-item py-3 d-flex">
                          <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                            <div className="avatar-title bg-success-subtle text-success rounded-circle">
                              <i className="ri-user-3-fill"></i>
                            </div>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h6 className="mb-1">From: Invests Today
                            </h6>
                            <small className="text-muted">To: Anna Adame</small>
                            <p className="text-muted mb-2">Adding a new event with attachments</p>
                            <small className="mb-0 text-muted">25 Nov</small>
                          </div>
                        </div>
                        <div className="acitivity-item py-3 d-flex">
                          <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                            <div className="avatar-title bg-success-subtle text-success rounded-circle">
                              <i className="ri-user-3-fill"></i>
                            </div>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h6 className="mb-1">From: PolicyChayan</h6>
                            <small className="text-muted">To: Sales team</small>
                            <p className="text-muted mb-2">added a new member to velzon dashboard</p>
                            <small className="mb-0 text-muted">19 Nov</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RequestInfluencerDetails;
