import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fatchVideosList } from "../../features/videos/videosSlice";
import { ToastContainer, toast } from "react-toastify";
import { formatDate } from "../../utils/dateUtils";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import CreateVideoDM from "../common/CreateVideoDM";
import useDecodedToken from "../../features/utils/hooks/useDecodedToken";

const UpcomingVideo = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const decodeToken = useDecodedToken();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");

  const { videosData, status, message } = useSelector(
    (state) => state.videosDetails
  );

  useEffect(() => {
    console.log("decodeToken ==>", decodeToken);
  }, [decodeToken]);

  const [showEditModal, setShowEditModal] = useState(false);
  const [videosDataDetails, setVideoDataDetails] = useState("");

  const urlSegment = location.pathname.split("/")[1];

  useEffect(() => {
    const payload = {
      status:
        urlSegment === "upcoming-videos"
          ? "1"
          : urlSegment === "post-videos"
            ? "2"
            : urlSegment === "all-videos"
              ? "99"
              : null,
    };

    if (payload.status) {
      dispatch(fatchVideosList(payload));
    } else {
      toast.error("Invalid URL segment, unable to fetch videos.");
    }
  }, [location.pathname, dispatch]);

  const handleShowDetails = (data) => {
    urlSegment === "upcoming-videos"
      ? navigate(`/upcoming-video-details/${data.id}`, { state: data })
      : urlSegment === "post-videos"
        ? navigate(`/post-video-details/${data.id}`, { state: data })
        : urlSegment === "all-videos" &&
        navigate(`/all-video-details/${data.id}`, { state: data });
  };

  const formatCost = (cost) => {
    // Ensure that cost is a valid number, and provide default value if not
    if (cost == null || isNaN(cost)) return "-"; // Return "-" if cost is invalid

    if (cost >= 1e6) {
      return (cost / 1e6).toFixed(1) + "M";
    } else if (cost >= 1e3) {
      return (cost / 1e3).toFixed(1) + "k";
    } else {
      return cost !== null && cost !== undefined ? cost.toString() : "-"; // Safe conversion to string
    }
  };

  const handleEditBtn = (dataRow) => {
    setShowEditModal(true);
    setVideoDataDetails(dataRow);
  };

  useEffect(() => {
    console.log("videosData ==>", videosData);
  }, [videosData]);

  // Function to safely parse and extract data from product JSON string
  const parseProduct = (product) => {
    try {
      return product ? JSON.parse(product) : null;
    } catch (error) {
      console.error("Error parsing product:", error);
      return null;
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">
              {urlSegment === "upcoming-videos"
                ? "Upcoming Videos"
                : urlSegment === "post-videos"
                  ? "Post Videos"
                  : urlSegment === "all-videos" && "All Videos "}
            </h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a href="#">CRM</a>
                </li>
                <li className="breadcrumb-item active">Active Influencers</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card" id="leadsList">
              <div className="card-header border-0">
                <div className="row g-4 align-items-center">
                  <div className="col-sm-auto ms-auto">
                    <div className="hstack gap-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-info"
                        data-bs-toggle="offcanvas"
                        href="#offcanvasExample"
                      >
                        <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                        Filters
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table
                    id="scroll-horizontal"
                    className="table table-bordered nowrap table-striped align-middle"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>SR No.</th>
                        <th>Action</th>
                        <th>Video ID</th>
                        <th>Video Type</th>
                        <th>Status</th>
                        <th>Channel</th>
                        <th>Influencer Name</th>
                        <th>Final Price</th>
                        <th>Video Link</th>
                        <th>Tentative Date</th>
                        <th>Scheduled Date</th>
                        <th>Live Date</th>
                        <th>Product</th> {/* Add product column */}
                      </tr>
                    </thead>
                    <tbody>
                      {videosData && videosData.length > 0 ? (
                        videosData.map((video, index) => {
                          const productData = parseProduct(video.product);
                          return (
                            <tr key={video.id}>
                              <td>{index + 1}</td>
                              <td>
                                <ul className="list-inline hstack mb-0">
                                  <li className="list-inline-item">
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="tooltip-top">
                                          View Details
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        className="btn btn-sm btn-info"
                                        onClick={() => handleShowDetails(video)}
                                      >
                                        <i className="ri-eye-fill align-bottom"></i>
                                      </Button>
                                    </OverlayTrigger>
                                  </li>
                                  <li className="list-inline-item">
                                    {(video.video_id !== "2" &&
                                      (decodeToken?.UsrRoleId === 3 ||
                                        decodeToken?.UsrRoleId === 5)) && (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id="tooltip-top">
                                              Edit Video
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            className="btn btn-sm btn-info"
                                            onClick={() => handleEditBtn(video)}
                                          >
                                            <i className="ri-edit-fill align-bottom"></i>
                                          </Button>
                                        </OverlayTrigger>
                                      )}
                                  </li>
                                </ul>
                              </td>
                              <td>{video.show_video_id}</td>
                              <td>{video.video_type}</td>
                              <td>
                                <span
                                  className={`badge ${video.show_video_status === "Live"
                                    ? "bg-success"
                                    : video.show_video_status === "Scheduled"
                                      ? "bg-info"
                                      : "bg-danger"
                                    }`}
                                >
                                  {video.show_video_status}
                                </span>
                              </td>
                              <td>{video.channel_name}</td>
                              <td>{video.influencer_name}</td>
                              <td>{formatCost(video?.final_video_cost) || "-"}</td>
                              <td>{video.video_link || "-"}</td>
                              <td>
                                {video.tentative_date
                                  ? formatDate(video.tentative_date)
                                  : "-"}
                              </td>
                              <td>
                                {video.scheduled_date
                                  ? formatDate(video.scheduled_date)
                                  : "-"}
                              </td>
                              <td>
                                {video.scheduled_date
                                  ? formatDate(video.scheduled_date)
                                  : "-"}
                              </td>
                              <td>
                                {productData
                                  ? `${productData.label} (${productData.value})`
                                  : "-"}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="12" className="text-center">
                            No Videos Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <CreateVideoDM
              selectedRowsData={videosDataDetails}
              setShowEditModal={setShowEditModal}
              showEditModal={showEditModal}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UpcomingVideo;
