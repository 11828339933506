import React, { useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { remarkUserDropdown, addNewRemark } from '../../features/influencerRequestSlice/influencerRequestSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import useDecodedToken from '../../features/utils/hooks/useDecodedToken';

const VideoRemark = (props) => {
    const { videoData, setShowRemarkModal, showRemarkModal } = props;
    const dispatch = useDispatch();
    const decodeToken = useDecodedToken();
    const [toastDisplayed, setToastDisplayed] = useState(false);
    const [userList, setUserList] = useState([]);
    const [isChecked, setIsChecked] = useState(false);

    const { influencerUserDropdown, influencerAddNewRemark, status, message } = useSelector(
        (state) => state.requestInfluencerDetails
    );

    const [formData, setFormData] = useState({
        assignTo: "",
        remarkData: "",
        fileName: null, // File will be stored here
    });

    useEffect(() => {
        const videoId = videoData?.id;
        if (videoId) {
            const payload = { video_id: videoId };
            dispatch(remarkUserDropdown(payload));
        }
    }, [dispatch, videoData, !toastDisplayed]);

    useEffect(() => {
        setUserList(influencerUserDropdown?.data || []);
    }, [influencerUserDropdown]);

    const closeModal = () => {
        setShowRemarkModal(false);
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: files[0], // Save the file object
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value, // Save the text value
            }));
        }
    };

    const handleSelectChange = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            assignTo: selectedOption // Store the selected object for "assignTo"
        }));
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleSubmitRemark = (e) => {
        e.preventDefault();

        if (!formData.remarkData || !formData.assignTo) {
            toast.error("Please fill in all required fields.");
            return;
        }

        const formdata = new FormData();

        // Append the file if present
        if (formData.fileName) {
            formdata.append("fileName", formData.fileName);  // Appending the file object to FormData
        }

        // Prepare payloadData
        const payloadData = {
            inf_req_id: videoData.inf_req_id,
            video_id: videoData.id,
            remarks: formData.remarkData,
            receiver_id: {
                label: formData.assignTo.label,  // Pass the label as well for reference
                value: formData.assignTo.value   // Pass the value of "assignTo"
            }
        };

        // Convert payloadData to string and append it
        formdata.append("payloadData", JSON.stringify(payloadData));

        // Create a FormData object to send the request
        // const form = new FormData();
        // form.append("videoId", videoData.id);
        // form.append("assignTo", formData.assignTo.value); // Send the value of "assignTo"
        // form.append("remarkData", formData.remarkData);

        // // If there is a file, append it as well
        // if (formData.fileName) {
        //     form.append("fileName", formData.fileName);  // Appending the file object to FormData
        // }
        // const form = new FormData();
        // // Prepare payload for addNewRemark action
        // const payload = {
        //     inf_req_id: videoData.inf_req_id,
        //     video_id: videoData.id,
        //     remarks: formData.remarkData,
        //     receiver_id: {
        //         label: formData.assignTo.label,  // Pass the label as well for reference
        //         value: formData.assignTo.value   // Pass the value of "assignTo"
        //     },
        //     form: formData.fileName// Appending the file object to FormData
        // };

        // const newp = {
        //     payload,
        //     form
        // }

        console.log("sdfsdfsd faefsdf ==>", formdata);


        // Dispatch the action
        dispatch(addNewRemark(formdata))
            .unwrap()
            .then((result) => {
                if (result.status) {
                    setToastDisplayed(true);
                    toast.success(result.message, {
                        onClose: () => {
                            setFormData({
                                assignTo: "",
                                remarkData: "",
                                fileName: null, // Reset file
                            });
                        }
                    });
                    closeModal();
                }
            });
    };

    useEffect(() => {
        if (status === 'success') {
            toast.success(message);
            closeModal();
        } else if (status === 'error') {
            toast.error(message);
        }
    }, [status, message]);

    return (
        <>
            <ToastContainer />
            <Modal
                show={showRemarkModal}
                onHide={closeModal}
                aria-labelledby="ModalHeader"
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <form onSubmit={handleSubmitRemark} encType="multipart/form-data">
                    <Modal.Header closeButton>
                        <Modal.Title> Influencer's Details </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-3">
                            <div className="col-lg-12">
                                <div>
                                    <label htmlFor="assignTo" className="form-label">To: <span className="text-danger">*</span></label>
                                    <Select
                                        className="basic-single small"
                                        classNamePrefix="select"
                                        name="assignTo"
                                        options={userList}
                                        onChange={handleSelectChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div>
                                    <label htmlFor="remarksTextArea" className="form-label">Remarks</label>
                                    <textarea
                                        className="form-control"
                                        id="remarksTextArea"
                                        rows="5"
                                        name="remarkData"
                                        value={formData.remarkData}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="flex-shrink-4">
                                    <input
                                        type="file"
                                        name="fileName"
                                        onChange={handleChange}
                                        className="form-control" />
                                    {formData.fileName && <div>Selected file: {formData.fileName.name}</div>} {/* Display file name */}
                                </div>
                            </div>
                            {decodeToken?.UsrRoleId === 2 || decodeToken?.UsrRoleId === 3 || decodeToken?.UsrRoleId === 4 || decodeToken?.UsrRoleId === 5 ? (
                                <div className="form-check m-3 d-flex justify-content-left">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="languages"
                                        value="Typescript"
                                        id="flexCheckDefault"
                                        onChange={handleCheckboxChange}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="flexCheckDefault"
                                    >
                                        &nbsp; Before sharing these files or messages, please ensure you’ve thoroughly reviewed the document, as it may contain personal data.
                                    </label>
                                </div>
                            ) : ""}

                        </div>
                    </Modal.Body>
                    <Modal.Footer className='border-top'>
                        <Button variant="secondary" onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            variant="success"
                            type="submit"
                            disabled={decodeToken?.UsrRoleId === 2 || decodeToken?.UsrRoleId === 3 || decodeToken?.UsrRoleId === 4 || decodeToken?.UsrRoleId === 5 ? !isChecked : false}
                        >
                            Yes
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default VideoRemark;
