import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    clientInfluencerSalesDataRequestDetails,
    clientInfluencerSelesSuggested,
    clientInfluencerClientRequestApprove
} from "../../features/influencerRequestSlice/influencerRequestSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormattedInput from "../common/FormattedInput";
import { Button, Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";
import CreateVideo from "../common/CreateVideo";

export const headerData = {
    channel: "Channels",
    followers_subs: "Required Followers / Subscriber",
    languges: "Language",
    video_type: "Video Type"
};

export const headerDataInfluencer = {
    sugg_id: "Suggest Id",
    reg_id: "Req Id",
    influencer_name: "Influencer Name",
    channel_name: "Channel Name",
    video_type: "Video Type",
    inf_coste: "Influencer Cost",
    FinalCost: "Client Cost",
    editable_inf_coste: "Editable Influencer Cost",
    video_cat: "Video Category",
    channel_link: "Channel Link",
    subscribe: "Subscribers",
    ave_view: "Average Views",
    languges: "Language"
};

const SuggestedDmTlInfluencerDetails = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");

    const [checkedItems, setCheckedItems] = useState({});
    const [inputValues, setInputValues] = useState({});
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [inputErrors, setInputErrors] = useState({}); // Track input errors per row

    const [updatedRequestData, setUpdatedRequestData] = useState([]); // State for updated values
    const [addVideo, setAddVideo] = useState(false)
    const [videoData, setVideoData] = useState("");
    const selectedData = location.state;
    const { clientInfluencerSalesDetails, influencerSuggested, status, message } =
        useSelector((state) => state.requestInfluencerDetails);

    const urlSegment = location.pathname.split("/")[1];

    // const { influencerSuggested, statusData: } = useSelector(
    //   (state) => state.requestInfluencerDetails
    // );

    const [requestData, setRequestData] = useState([]);
    const [channelData, setChannelData] = useState({});
    const [toastDisplayed, setToastDisplayed] = useState(false);

    // Fetch request details when component loads
    useEffect(() => {



        if (selectedData?.id) {
            const payload = {
                inf_req_id: selectedData?.id,
                sugg_status: urlParts[3] === "suggested-dmtl-influencer-details" ? "3"
                    : urlParts[3] === "approved-dmtl-suggested-influencer-details" ? "5"
                        : urlParts[3] === "rejected-dmtl-suggested-influencer-details" && "7"
            };
            dispatch(clientInfluencerSalesDataRequestDetails(payload));
        }
    }, [dispatch, selectedData, !toastDisplayed]);

    // Update request data once the details are fetched
    useEffect(() => {
        if (status === "succeeded") {
            setRequestData(clientInfluencerSalesDetails?.data || []);
            setUpdatedRequestData(clientInfluencerSalesDetails?.data || []); // Initialize updated data
        }
    }, [status, clientInfluencerSalesDetails, toastDisplayed]);

    // useEffect(() => {
    //   if (message === "Success") {
    //     setRequestData(InfluencerSuggested || []);
    //   }
    // }, [message, InfluencerSuggested]);

    // Filter and set channel data
    useEffect(() => {
        if (clientInfluencerSalesDetails?.request_details) {
            const filteredChannelData = Object.entries(
                clientInfluencerSalesDetails.request_details?.channel_details
            )
                .filter(([key, value]) => key.endsWith("_status") && value === "1")
                .map(([key]) => key.replace("_status", ""))
                .reduce((acc, channel) => {
                    acc[channel] =
                        clientInfluencerSalesDetails.request_details?.channel_details[
                        channel
                        ];
                    return acc;
                }, {});
            setChannelData(filteredChannelData);
        }
    }, [clientInfluencerSalesDetails]);

    // Handle selecting all checkboxes

    const handleHeaderCheckboxChange = (checked) => {
        const newCheckedItems = {};
        requestData.forEach((chkData, index) => {
            if (chkData?.SuggestionStatus === 2 || chkData?.SuggestionStatus === 4) {
                newCheckedItems[index] = checked;
            }
        });
        setIsAllChecked(checked);
        setCheckedItems(newCheckedItems);
    };

    // Handle individual checkbox changes
    const handleCheckboxChange = (event, index) => {
        const { checked, name } = event.target;
        setCheckedItems((prevState) => ({
            ...prevState,
            [name]: checked
        }));

        // setIsAllChecked(
        //   Object.values({ ...checkedItems, [index]: checked }).every(Boolean)
        // );
    };

    // Handle input value changes (influencer cost)
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputValues((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAddVideo = (dataRows) => {
        setVideoData(dataRows)
        setAddVideo(true)
    }

    const handleViewDetails = (data) => {
        if (urlParts[3] === "suggested-dmtl-influencer-details") {
            navigate(`/suggested-dmtl-influencer-details_by_unique/${data?.id}`, { state: data })
        } else if (urlParts[3] === "approved-dmtl-suggested-influencer-details") {
            navigate(`/approved-dmtl-suggested-influencer-details_by_unique/${data?.id}`, { state: data })
        } else if (urlParts[3] === "rejected-dmtl-suggested-influencer-details") {
            navigate(`/rejected-dmtl-suggested-influencer-details_by_unique/${data?.id}`, { state: data })
        }

    }

    // Handle form submission
    const handleSubmitBtn = () => {
        const anyChecked = Object.values(checkedItems).some((checked) => checked);

        if (!anyChecked) {
            toast.error("Please select at least one influencer.");
            return; // Stop further execution if no checkbox is checked
        }
        const selectedRows = requestData
            .map((data, index) => {
                if (checkedItems[`${index}`]) {
                    const cost = inputValues[`cost${index}`];
                    if (!cost) {
                        // Show error if the input is empty
                        toast.error(
                            `Please enter cost for ${data?.influencer_info?.name || "the influencer"
                            }.`
                        );
                        return null; // Skip this row
                    }
                    const influencerCost = data?.dm_cost; // Assuming this holds the influencer's cost
                    if (parseFloat(cost) < parseFloat(influencerCost)) {
                        setInputErrors((prev) => ({ ...prev, [index]: true }));
                        toast.error(
                            `The cost for ${data?.influencer_info?.name} must be less than the influencer's cost (${influencerCost}).`
                        );
                        return null; // Skip this row
                    }
                    return {
                        chechedValue: checkedItems[`${index}`] || false,
                        uniqueValue: data?.unique_check,
                        sales_cost: cost // Editable cost
                    };
                }
                return null; // Not checked
            })
            .filter(Boolean);
        if (selectedRows.length > 0) {
            const requestDataToSend = { selectedRows };
            dispatch(clientInfluencerSelesSuggested(requestDataToSend))
                .unwrap()
                .then((result) => {
                    if (result.status) {
                        setToastDisplayed(true);
                        toast.success(result.message, {
                            onClose: () => {
                                setToastDisplayed(false);
                                // Update the requestData and updatedRequestData on toast close
                                setUpdatedRequestData((prevData) =>
                                    prevData.map((item, index) =>
                                        checkedItems[index]
                                            ? { ...item, sales_cost: inputValues[`cost${index}`] }
                                            : item
                                    )
                                );
                                setRequestData(clientInfluencerSalesDetails?.data || []);
                                setCheckedItems([]);
                                setIsAllChecked([]);
                            }
                        });
                    }
                });
        }
    };

    const handleApproveBtn = (rowsData, index) => {

        const requestDataToSend = {
            suggestion_id: rowsData.id,
            bidding_status: "5" //5: Deal Done, 6: Deal Reopen, 7: Deal Rejected, 8: Deal Dormant
        };

        dispatch(clientInfluencerClientRequestApprove(requestDataToSend))
            .unwrap()
            .then((result) => {
                if (result.status) {
                    setToastDisplayed(true);
                    toast.success(result.message, {
                        onClose: () => {
                            setToastDisplayed(false);
                        }
                    });
                }
            });
    };

    // let uniqueIndex = 0;
    /* const isAllCheckboxDisabled = updatedRequestData.every(data => data?.SuggestionStatus !== 2); */
    const isAllCheckboxDisabled = updatedRequestData.every(
        (data) => data?.SuggestionStatus !== 2 && data?.SuggestionStatus !== 4
    );
    return (
        <>
            <ToastContainer />
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">
                            {
                                urlSegment === "suggested-influencer-details" ? "Suggested Influencers"
                                    : urlSegment === "approved-suggested-influencer-details" ? "Approved Influencers"
                                        : urlSegment === "rejected-suggested-influencer-details" && "Rejected Influencers"
                            }

                        </h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript: void(0);">CRM</a>
                                </li>
                                <li className="breadcrumb-item active">Active Influencers</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card" id="leadsList">
                            <div className="card-header border-0">
                                <div className="row g-4 align-items-center">
                                    <div className="col-sm-auto ms-auto">
                                        <div className="hstack gap-2">
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-info"
                                                data-bs-toggle="offcanvas"
                                                href="#offcanvasExample"
                                            >
                                                <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                                                Filters
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-success add-btn"
                                                onClick={handleSubmitBtn}
                                            >
                                                <i className="ri-add-line align-bottom me-1"></i> Send
                                                Selected Influencers
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top">
                                <p>
                                    Client With The Request Id:{" "}
                                    <strong>
                                        {clientInfluencerSalesDetails?.request_details?.req_code}
                                    </strong>{" "}
                                    have requirement of Total Influencer:{" "}
                                    <strong>
                                        {
                                            clientInfluencerSalesDetails?.request_details
                                                ?.no_of_influencer
                                        }
                                    </strong>
                                    , Other details are mentioned below:
                                </p>
                                {Object.keys(channelData).length > 0 && (
                                    <table className="table table-bordered table-striped rounded">
                                        <thead>
                                            <tr>
                                                {Object.keys(headerData).map((key) => (
                                                    <th key={key}>{headerData[key]}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(channelData).map(([channel, data]) => (
                                                <tr key={channel}>
                                                    <td>{channel}</td>
                                                    <td>{data?.follower_subscriber?.label || "-"}</td>
                                                    <td>
                                                        {data?.language
                                                            ?.map((lang) => lang.label)
                                                            .join(", ") || "-"}
                                                    </td>
                                                    <td>
                                                        {data?.video_type
                                                            ?.map((videoType) => videoType.label)
                                                            .join(", ") || "-"}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                            <div className="card-body">
                                <div className="col-sm-12 border rounded px-2 py-3 background-secodary  mb-2 bg-light text-dark">
                                    <p>
                                        <strong>Note:</strong> Please select the required checkboxes
                                        below and then click on "Send Selected Influencers" on the
                                        top right of your screen to submit a request to the Sales
                                        Team for an Influencer Request ID{" "}
                                        {clientInfluencerSalesDetails?.request_details?.req_code}.
                                    </p>
                                </div>

                                <div className="table-responsive">

                                    {updatedRequestData.length > 0 && !toastDisplayed && (
                                        <table className="table table-bordered table-striped rounded">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <input
                                                            type="checkbox"
                                                            checked={isAllChecked}
                                                            onChange={(e) =>
                                                                handleHeaderCheckboxChange(e.target.checked)
                                                            }
                                                            disabled={isAllCheckboxDisabled}
                                                        />
                                                    </th>
                                                    {Object.keys(headerDataInfluencer).map((key) => (
                                                        <th key={key}>{headerDataInfluencer[key]}</th>
                                                    ))}

                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {updatedRequestData.map((data, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                name={`${index}`}
                                                                /* disabled={data?.SuggestionStatus !== 2 ? true : false} */
                                                                disabled={
                                                                    data?.SuggestionStatus !== 2 &&
                                                                        data?.SuggestionStatus !== 4
                                                                        ? true
                                                                        : false
                                                                }
                                                                checked={checkedItems[`${index}`] || false}
                                                                onChange={handleCheckboxChange}
                                                            />
                                                        </td>
                                                        <td>{data?.inf_suggestion_id}</td>
                                                        <td>{data?.inf_req_code}</td>
                                                        <td>{data?.inf_name}</td>
                                                        <td>{data?.req_channel_name}</td>
                                                        <td>{data?.req_video_type || "-"}</td>
                                                        <td>{data?.dm_tl_cost}</td>
                                                        <td>{data?.client_cost}</td>
                                                        <td>
                                                            <FormattedInput
                                                                type="number"
                                                                className={`form-control ${inputErrors[index] ? "is-invalid" : ""
                                                                    }`} // Apply is-invalid conditionally
                                                                disabled={
                                                                    data?.SuggestionStatus !== 2 &&
                                                                        data?.SuggestionStatus !== 4
                                                                        ? true
                                                                        : false
                                                                }
                                                                name={`cost${index}`}
                                                                placeholder="Enter cost"
                                                                value={
                                                                    inputValues[`cost${index}`] || data?.sales_cost
                                                                }
                                                                onChange={handleInputChange}
                                                            />
                                                        </td>
                                                        <td>{data?.inf_video_category || "-"}</td>
                                                        <td>{data?.inf_channel_url || "-"}</td>
                                                        <td>{data?.req_subscriber || "-"}</td>
                                                        <td>{data?.inf_views || "-"}</td>
                                                        <td>{data?.inf_language || ""}</td>
                                                        <td>
                                                            <ul className="list-inline hstack mb-0">
                                                                {urlParts[3] === "suggested-influencer-details" ? <>
                                                                    <li className="list-inline-item">
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip id="tooltip-top">
                                                                                    Approve Influencer
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <Button
                                                                                title="Request send to DM"
                                                                                className="btn btn-sm btn-success edit-item-btn"
                                                                                onClick={() => handleApproveBtn(data)}
                                                                            >
                                                                                <i className="ri-checkbox-circle-fill align-bottom me-1"></i>
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip id="tooltip-top">
                                                                                    View Details
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <Button
                                                                                title="Request send to DM"
                                                                                className="btn btn-sm btn-info edit-item-btn"
                                                                                onClick={() => handleViewDetails(data)}
                                                                            >
                                                                                <i className="ri-eye-fill align-bottom me-1"></i>
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip id="tooltip-top">
                                                                                    Reject Influencer
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <Button
                                                                                title="Request send to DM"
                                                                                className="btn btn-sm btn-danger edit-item-btn"
                                                                            >
                                                                                <i className="ri-close-circle-fill align-bottom me-1"></i>
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    </li>
                                                                </>
                                                                    : urlParts[3] === "approved-suggested-influencer-details" ? <>
                                                                        <li className="list-inline-item">
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={
                                                                                    <Tooltip id="tooltip-top">
                                                                                        Add Video
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <Button
                                                                                    title="Request send to DM"
                                                                                    className="btn btn-sm btn-info edit-item-btn"
                                                                                    onClick={() => handleAddVideo(data)}
                                                                                >
                                                                                    <i className="ri-add-fill align-bottom me-1"></i>
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                        </li>

                                                                        <li className="list-inline-item">
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={
                                                                                    <Tooltip id="tooltip-top">
                                                                                        View Details
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <Button
                                                                                    title="Request send to DM"
                                                                                    className="btn btn-sm btn-info edit-item-btn"
                                                                                    onClick={() => handleViewDetails(data)}
                                                                                >
                                                                                    <i className="ri-eye-fill align-bottom me-1"></i>
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                        </li>
                                                                    </>
                                                                        : urlParts[3] === "rejected-suggested-influencer-details" && <>
                                                                            <li className="list-inline-item">
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip id="tooltip-top">
                                                                                            View Details
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <Button
                                                                                        title="Request send to DM"
                                                                                        className="btn btn-sm btn-info edit-item-btn"
                                                                                        onClick={() => handleViewDetails(data)}
                                                                                    >
                                                                                        <i className="ri-eye-fill align-bottom me-1"></i>
                                                                                    </Button>
                                                                                </OverlayTrigger>
                                                                            </li>
                                                                        </>}
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CreateVideo
                showDeleteModal={addVideo}
                selectedRowsData={videoData}
                setSelectedRowsData={setVideoData}
                setShowDeleteModal={setAddVideo}
            />
        </>
    );
};

export default SuggestedDmTlInfluencerDetails;