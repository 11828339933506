import React, { useEffect, useState } from "react";
import Select from "react-select";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { sendClientRequest } from "../../features/commonSlice/commonSlice";


const AssigntoDm = (props) => {
    const { showModal, setShowModal, selectedData } = props;
    const [formData, setFormData] = useState({
        salesteam: [],
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { salesData, status: commonStatus } = useSelector((state) => state.influencer);
    const [salesList, setSalesList] = useState([]);
    const [errors, setErrors] = useState({});
    const [errorData, setErrorData] = useState([]);
    const [loginError, setLoginError] = useState('');

    useEffect(() => {
        if (selectedData) {
            setFormData({
                salesteam: selectedData?.salesteam?.map(steam => ({ label: steam.label, value: steam.value })) || [],
            });
        }
    }, [selectedData]);



    useEffect(() => {
        if (commonStatus === 'succeeded') {
            setSalesList(salesData?.data || []);
        }
    }, [commonStatus, salesData]);



    const handleSubmit = (event) => {
        event.preventDefault();
        try {
            const salesTeamData = {
                inf_req_id: selectedData?.id,

            };
            console.log("clientData ==>", salesTeamData);
            dispatch(sendClientRequest(salesTeamData))
                .unwrap()
                .then((result) => {
                    if (result.status) {
                        toast.success(result.message, {
                            onClose: () => { },
                        });
                        setShowModal(false)

                    } else {
                        setLoginError(result.message);
                        setErrorData(result.form_err);
                    }
                })
                .catch((err) => {
                    setLoginError(err.message);
                    setErrorData(err.form_err || []);
                    toast.error(err.message);
                });
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };


    const mapErrors = (errorData) => {
        const errorMap = {};
        errorData.forEach((error) => {
            if (!errorMap[error.param]) {
                errorMap[error.param] = error.msg;
            }
        });
        return errorMap;
    };

    useEffect(() => {
        const errorsValue = mapErrors(errorData);
        setErrors(errorsValue);
    }, [errorData]);

    const closeModal = () => {
        setShowModal(false);
    };


    console.log("selectedData ==>", selectedData);


    return (
        <>
            <Modal
                show={showModal}
                onHide={closeModal}
                aria-labelledby="ModalHeader"
                size="lg"
                backdrop="static" // Prevents closing on backdrop click
                keyboard={false} // Prevents closing on ESC key press
            >
                <Modal.Header closeButton >
                    <Modal.Title>Send Request to Digital Marketing Team</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="p-0 table-responsive">
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Channel Type </th>
                                                    <th>Subscriber/Follower</th>
                                                    <th>Language</th>
                                                    <th>Min Budget</th>
                                                    <th>Max Budget</th>
                                                    <th>Video Category</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedData?.channel_data?.youTube_status == 1 ? (
                                                    <tr>
                                                        <td>Youtube</td>
                                                        <td>
                                                            {" "}
                                                            {
                                                                selectedData?.channel_data?.youTube
                                                                    .follower_subscriber.value
                                                            }
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.youTube.language?.map(
                                                                (lang, index) => (
                                                                    <span key={index}>
                                                                        {lang?.label}
                                                                        {index <
                                                                            selectedData.channel_data.youTube.language
                                                                                .length -
                                                                            1
                                                                            ? ", "
                                                                            : ""}
                                                                    </span>
                                                                )
                                                            )}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.youTube.min_budget}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.youTube.max_budget}
                                                        </td>
                                                        <td>
                                                            {selectedData?.channel_data?.youTube.video_type?.map(
                                                                (vtype, index) => (
                                                                    <span key={index}>
                                                                        {vtype?.label}
                                                                        {index <
                                                                            selectedData.channel_data.youTube.video_type
                                                                                .length -
                                                                            1
                                                                            ? ", "
                                                                            : ""}
                                                                    </span>
                                                                )
                                                            )}
                                                        </td>
                                                    </tr>
                                                ) : null}

                                                {selectedData?.channel_data?.instagram_status == 1 ? (
                                                    <tr>
                                                        <td>Instagram</td>
                                                        <td>
                                                            {" "}
                                                            {
                                                                selectedData?.channel_data?.instagram
                                                                    .follower_subscriber.value
                                                            }
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.instagram.language?.map(
                                                                (lang, index) => (
                                                                    <span key={index}>
                                                                        {lang?.label}
                                                                        {index <
                                                                            selectedData.channel_data.instagram.language
                                                                                .length -
                                                                            1
                                                                            ? ", "
                                                                            : ""}
                                                                    </span>
                                                                )
                                                            )}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.instagram.min_budget}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.instagram.max_budget}
                                                        </td>
                                                        <td>
                                                            -
                                                        </td>
                                                    </tr>
                                                ) : null}

                                                {selectedData?.channel_data?.facebook_status == 1 ? (
                                                    <tr>
                                                        <td>Facebook</td>
                                                        <td>
                                                            {" "}
                                                            {
                                                                selectedData?.channel_data?.facebook
                                                                    .follower_subscriber.value
                                                            }
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.facebook.language?.map(
                                                                (lang, index) => (
                                                                    <span key={index}>
                                                                        {lang?.label}
                                                                        {index <
                                                                            selectedData.channel_data.facebook.language
                                                                                .length -
                                                                            1
                                                                            ? ", "
                                                                            : ""}
                                                                    </span>
                                                                )
                                                            )}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.facebook.min_budget}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.facebook.max_budget}
                                                        </td>
                                                        <td>
                                                            -
                                                        </td>
                                                    </tr>
                                                ) : null}

                                                {selectedData?.channel_data?.threads_status == 1 ? (
                                                    <tr>
                                                        <td>Threads</td>
                                                        <td>
                                                            {" "}
                                                            {
                                                                selectedData?.channel_data?.threads
                                                                    .follower_subscriber.value
                                                            }
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.threads.language?.map(
                                                                (lang, index) => (
                                                                    <span key={index}>
                                                                        {lang?.label}
                                                                        {index <
                                                                            selectedData.channel_data.threads.language
                                                                                .length -
                                                                            1
                                                                            ? ", "
                                                                            : ""}
                                                                    </span>
                                                                )
                                                            )}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.threads.min_budget}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.threads.max_budget}
                                                        </td>
                                                        <td>
                                                            -
                                                        </td>
                                                    </tr>
                                                ) : null}

                                                {selectedData?.channel_data?.linkedIn_status == 1 ? (
                                                    <tr>
                                                        <td>LinkedIn</td>
                                                        <td>
                                                            {" "}
                                                            {
                                                                selectedData?.channel_data?.linkedIn
                                                                    .follower_subscriber.value
                                                            }
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.linkedIn.language?.map(
                                                                (lang, index) => (
                                                                    <span key={index}>
                                                                        {lang?.label}
                                                                        {index <
                                                                            selectedData.channel_data.linkedIn.language
                                                                                .length -
                                                                            1
                                                                            ? ", "
                                                                            : ""}
                                                                    </span>
                                                                )
                                                            )}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.linkedIn.min_budget}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.linkedIn.max_budget}
                                                        </td>
                                                        <td>
                                                            -
                                                        </td>
                                                    </tr>
                                                ) : null}

                                                {selectedData?.channel_data?.telegram_status == 1 ? (
                                                    <tr>
                                                        <td>Telegram</td>
                                                        <td>
                                                            {" "}
                                                            {
                                                                selectedData?.channel_data?.telegram
                                                                    .follower_subscriber.value
                                                            }
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.telegram.language?.map(
                                                                (lang, index) => (
                                                                    <span key={index}>
                                                                        {lang?.label}
                                                                        {index <
                                                                            selectedData.channel_data.telegram.language
                                                                                .length -
                                                                            1
                                                                            ? ", "
                                                                            : ""}
                                                                    </span>
                                                                )
                                                            )}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.telegram.min_budget}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.telegram.max_budget}
                                                        </td>
                                                        <td>
                                                            -
                                                        </td>
                                                    </tr>
                                                ) : null}

                                                {selectedData?.channel_data?.whatsapp_status == 1 ? (
                                                    <tr>
                                                        <td>Whatsapp</td>
                                                        <td>
                                                            {" "}
                                                            {
                                                                selectedData?.channel_data?.whatsapp
                                                                    .follower_subscriber.value
                                                            }
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.whatsapp.language?.map(
                                                                (lang, index) => (
                                                                    <span key={index}>
                                                                        {lang?.label}
                                                                        {index <
                                                                            selectedData.channel_data.whatsapp.language
                                                                                .length -
                                                                            1
                                                                            ? ", "
                                                                            : ""}
                                                                    </span>
                                                                )
                                                            )}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.whatsapp.min_budget}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.whatsapp.max_budget}
                                                        </td>
                                                        <td>
                                                            -
                                                        </td>
                                                    </tr>
                                                ) : null}

                                                {selectedData?.channel_data?.twitter_X_status == 1 ? (
                                                    <tr>
                                                        <td>Twitter_X</td>
                                                        <td>
                                                            {" "}
                                                            {
                                                                selectedData?.channel_data?.twitter_X
                                                                    .follower_subscriber.value
                                                            }
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.twitter_X.language?.map(
                                                                (lang, index) => (
                                                                    <span key={index}>
                                                                        {lang?.label}
                                                                        {index <
                                                                            selectedData.channel_data.twitter_X.language
                                                                                .length -
                                                                            1
                                                                            ? ", "
                                                                            : ""}
                                                                    </span>
                                                                )
                                                            )}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.twitter_X.min_budget}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {selectedData?.channel_data?.twitter_X.max_budget}
                                                        </td>
                                                        <td>
                                                            -
                                                        </td>
                                                    </tr>
                                                ) : null}
                                            </tbody>
                                        </table>
                                        <p className='text-danger text-center'>{!errorData.length > 0 && loginError}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="primary" onClick={handleSubmit}>
                        Confirm to Send Request
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AssigntoDm;
