import React, { useEffect, useState } from "react";
import Select from "react-select";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { InfluencerTempAssign } from "../../features/commonSlice/commonSlice";
import { fetchDmTeam } from "../../features/commonSlice/commonSlice";

const AssigntoDmTemp = (props) => {
    const { showModal, setShowModal, selectedData } = props;
    const [formData, setFormData] = useState({
        salesteam: [],
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { salesData, status: commonStatus } = useSelector((state) => state.influencer);
    const [salesList, setSalesList] = useState([]);
    const [errors, setErrors] = useState({});
    const [errorData, setErrorData] = useState([]);
    const [loginError, setLoginError] = useState('');

    useEffect(() => {
        if (selectedData) {
            setFormData({
                salesteam: selectedData?.salesteam?.map(steam => ({ label: steam.label, value: steam.value })) || [],
            });
        }
    }, [selectedData]);

    useEffect(() => {
        if (commonStatus === 'idle') {
            dispatch(fetchDmTeam());
        }
    }, [commonStatus, dispatch]);

    useEffect(() => {
        if (commonStatus === 'succeeded') {
            setSalesList(salesData?.data || []);
        }
    }, [commonStatus, salesData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        try {
            const dmTeamData = {
                inf_id: selectedData?.id,
                tmp_assigned_to: formData.salesteam,
                to_date: formData.to_date
            };
            console.log("clientData ==>", dmTeamData);
            dispatch(InfluencerTempAssign(dmTeamData))
                .unwrap()
                .then((result) => {
                    if (result.status) {
                        toast.success(result.message, {
                            onClose: () => {
                            },
                        });
                        setShowModal(false)

                    } else {
                        setLoginError(result.message);
                        setErrorData(result.form_err);
                    }
                })
                .catch((err) => {
                    setLoginError(err.message);
                    setErrorData(err.form_err || []);
                    toast.error(err.message);
                });
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };


    const mapErrors = (errorData) => {
        const errorMap = {};
        errorData.forEach((error) => {
            if (!errorMap[error.param]) {
                errorMap[error.param] = error.msg;
            }
        });
        return errorMap;
    };

    useEffect(() => {
        const errorsValue = mapErrors(errorData);
        setErrors(errorsValue);
    }, [errorData]);

    const closeModal = () => {
        setShowModal(false);
    };


    return (
        <>
            <Modal
                show={showModal}
                onHide={closeModal}
                aria-labelledby="ModalHeader"
                size="lg"
                backdrop="static" // Prevents closing on backdrop click
                keyboard={false} // Prevents closing on ESC key press
            >
                <Modal.Header closeButton >
                    <Modal.Title>Temporary Assign to DM</Modal.Title>
                </Modal.Header>


                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="p-0">
                                        <table className="table table-striped table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td className="w-25 p-3">  Name  </td>
                                                    <td>{selectedData?.name} </td>
                                                </tr>
                                                <tr>
                                                    <td className="w-25 p-3">Sales Teams:</td>
                                                    <td >
                                                        <Select
                                                            name="salesteam"
                                                            options={salesList}
                                                            classNamePrefix="select"
                                                            onChange={(selected) => handleSelectChange("salesteam", selected)}
                                                            value={formData.salesteam}
                                                        />
                                                        <div className="text-danger"> {errors.salesteam && <> {errors.salesteam}</>}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Assign Date</td>
                                                    <td>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name="to_date"
                                                            value={formData.to_date}
                                                            onChange={handleChange}
                                                        />
                                                        <div className="text-danger">
                                                            {errors.to_date && <> {errors.to_date}</>}
                                                        </div>
                                                    </td>
                                                </tr>


                                            </tbody>

                                        </table>


                                        <p className='text-danger text-center'>{!errorData.length > 0 && loginError}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button type="button" variant="primary" onClick={handleSubmit}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AssigntoDmTemp;
