import React, { useEffect, useState, useSyncExternalStore } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fetchClientsDetails } from "../../features/clientSlice/clientSlice";
import { formatDate } from "../../utils/dateUtils";

const DetailClients = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [clientsData, setClientsData] = useState([]);
  const selectedData = location.state;

  const { clientDetailsData, status } = useSelector((state) => state.clients);

  useEffect(() => {
    if (selectedData?.id) {
      dispatch(fetchClientsDetails(selectedData.id));
    }
  }, [dispatch, selectedData]);

  useEffect(() => {
    if (status === "succeeded" && clientDetailsData) {
      setClientsData(clientDetailsData?.data[0]);
    }
  }, [status, clientDetailsData]);


  const renderParsedField = (field) => {
    try {
      const parsedField = JSON.parse(field);
      return parsedField.map((item, index) => (
        <span key={index}>
          {item.label}
          {index < parsedField.length - 1 ? ", " : ""}
        </span>
      ));
    } catch (e) {
      return field;
    }
  };



  return (
    <>
      <>
        <div className="container-fluid">
          <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg">
              <img
                src="../assets/images/profile-bg.jpg"
                alt=""
                className="profile-wid-img"
              />
            </div>
          </div>
          <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
            <div className="row g-4">
              <div className="col-auto">
                <div className="avatar-lg">
                  <img
                    src="../assets/images/channels4_profile.jpg  "
                    alt="user-img"
                    className="img-thumbnail"
                  />
                </div>
              </div>
              <div className="col">
                <div className="p-2">
                  <h3 className="text-white mb-1">{clientsData?.company_name}</h3>
                  <p className="text-white text-opacity-75">
                    <div
                      className={`badge rounded-pill fs-12 ${clientsData?.status === 0
                        ? 'bg-danger'
                        : clientsData?.status === 1
                          ? 'bg-success'
                          : clientsData?.status === 3
                            ? 'bg-danger'
                            : clientsData?.status === 4
                              ? 'bg-danger'
                              : clientsData?.status === 5
                                ? 'bg-info'
                                : ''
                        }`}
                    >
                      {clientsData?.user_status}
                    </div>
                  </p>
                  <div className="hstack text-white-50 gap-1">
                    <div className="me-2 hstack gap-3 flex-wrap">
                      <div>
                        Created Date:{" "}
                        <span className="fw-medium">
                          {formatDate(clientsData?.created_at)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div>

                <div className="tab-content pt-4 text-muted">
                  <div
                    className="tab-pane active"
                    id="overview-tab"
                    role="tabpanel"
                  >
                    <div className="row">
                      <div className="col-xxl-12">
                        <div className="card">

                          <div className="card-body">
                            <div className="row">
                              <div className="col-6 col-md-4">
                                <div className="d-flex mt-4">
                                  <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                      <i className="ri-user-2-fill"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1 overflow-hidden">
                                    <p className="mb-1">Client Name :</p>
                                    <h6 className="text-truncate mb-0">
                                      {clientsData?.company_name}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 col-md-4">
                                <div className="d-flex mt-4">
                                  <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                      <i className="ri-global-line"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1 overflow-hidden">
                                    <p className="mb-1">Contact Person :</p>
                                    <h6 className="text-truncate mb-0">
                                      {clientsData?.concern_person_name}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 col-md-4">
                                <div className="d-flex mt-4">
                                  <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                      <i className="ri-global-line"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1 overflow-hidden">
                                    <p className="mb-1">Email Id :</p>
                                    <h6 className="text-truncate mb-0">
                                      {clientsData?.email}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 col-md-4">
                                <div className="d-flex mt-4">
                                  <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                      <i className="ri-user-2-fill"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1 overflow-hidden">
                                    <p className="mb-1">Phone No :</p>
                                    <h6 className="text-truncate mb-0">
                                      {clientsData?.mobile_no}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 col-md-4">
                                <div className="d-flex mt-4">
                                  <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                      <i className="ri-global-line"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1 overflow-hidden">
                                    <p className="mb-1">Budget :</p>
                                    <h6 className="text-truncate mb-0">
                                      {clientsData?.budget}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 col-md-4">
                                <div className="d-flex mt-4">
                                  <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                      <i className="ri-user-2-fill"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1 overflow-hidden">
                                    <p className="mb-1">Language :</p>
                                    <h6 className="text-truncate mb-0">{renderParsedField(clientsData?.language)}</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="remarks" role="tabpanel">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-4">
                          <h5 className="card-title flex-grow-1 mb-0">Remarks</h5>
                          <div className="flex-shrink-0">

                            <a href="#" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#remarksModal"><i
                              className="ri-add-line align-bottom me-1"></i> Add
                              Remarks</a>
                          </div>
                        </div>

                        <div className="acitivity-timeline">
                          <div className="acitivity-item d-flex">
                            <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                              <div className="avatar-title bg-success-subtle text-success rounded-circle">
                                <i className="ri-user-3-fill"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">From: Oliver Phillips <span
                                className="badge bg-primary-subtle text-primary align-middle">New</span></h6>
                              <small className="text-muted">To: Invests Today</small>
                              <p className="text-muted mb-2">We talked about a project on linkedin.</p>
                              <small className="mb-0 text-muted">Today</small>
                              <ul className="list-inline hstack gap-2 mt-2 remarks-action">

                                <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                                  data-bs-placement="top" aria-label="Approve" data-bs-original-title="Approve">
                                  <button className="btn btn-sm btn-soft-success" data-bs-toggle="modal"
                                    data-bs-target="#approveDocumentModal" data-approve-id="14"><i
                                      className="ri-checkbox-circle-fill align-bottom fs-16"></i></button>
                                </li>
                                <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                                  data-bs-placement="top" aria-label="Reject" data-bs-original-title="Reject">
                                  <button className="btn btn-sm btn-soft-danger" data-bs-toggle="modal"
                                    data-bs-target="#rejectDocumentModal" data-reject-id="14"><i
                                      className="ri-close-circle-fill align-bottom fs-16"></i></button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                              <div className="avatar-title bg-success-subtle text-success rounded-circle">
                                <i className="ri-user-3-fill"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">From: Invests Today</h6>
                              <small className="text-muted">To: PolicyChayan</small>
                              <p className="text-muted mb-2"><i className="ri-file-text-line align-middle ms-2"></i> Create
                                new
                                project Buildng product</p>
                              <small className="mb-0 text-muted">Yesterday</small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                              <div className="avatar-title bg-success-subtle text-success rounded-circle">
                                <i className="ri-user-3-fill"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">From: Invests Today
                              </h6>
                              <small className="text-muted">To: Anna Adame</small>
                              <p className="text-muted mb-2">Adding a new event with attachments</p>
                              <small className="mb-0 text-muted">25 Nov</small>
                            </div>
                          </div>
                          <div className="acitivity-item py-3 d-flex">
                            <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                              <div className="avatar-title bg-success-subtle text-success rounded-circle">
                                <i className="ri-user-3-fill"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">From: PolicyChayan</h6>
                              <small className="text-muted">To: Sales team</small>
                              <p className="text-muted mb-2">added a new member to velzon dashboard</p>
                              <small className="mb-0 text-muted">19 Nov</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default DetailClients;
