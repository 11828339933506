// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import commonReducer from './features/commonSlice/commonSlice'
import clientReducer from './features/clientSlice/clientSlice';
import videosReducer from './features/videos/videosSlice';
import subrequestreducers from './features/clientRequstSlice/dmSubRequestSlice'
import clientInfluencerRequestSlice from './features/clientInfluencerRequestSlice/clientInfluencerRequestSlice';
import { influencerRequestDetailsReducer, influencerRequestReducer } from './features/influencerRequestSlice/influencerRequestSlice';
const store = configureStore({
  reducer: {
    auth: authReducer,
    influencer: commonReducer,
    clients: clientReducer,
    influencerRequest: clientInfluencerRequestSlice,
    requestInfluencer: influencerRequestReducer,
    requestInfluencerDetails: influencerRequestDetailsReducer,
    subrequest: subrequestreducers,
    videosDetails: videosReducer,
  },
});

export default store;
