import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { clientInfluencerDataRequestDetails, clientInfluencerSuggested } from "../../features/influencerRequestSlice/influencerRequestSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormattedInput from "../common/FormattedInput";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


export const headerData = {
    channel: "Channels",
    followers_subs: "Required Followers / Subscriber",
    languges: "Language",
    video_type: "Video Type"
};

export const headerDataInfluencer = {
    reg_id: "Req Id",
    influencer_name: "Influencer Name",
    channel_name: "Channel Name",
    video_type: "Video Type",
    inf_coste: "Influencer Cost",
    editable_inf_coste: "Editable Influencer Cost",
    video_cat: "Video Category",
    channel_link: "Channel Link",
    subscribe: "Subscribers",
    ave_view: "Average Views",
    languges: "Language"
};

const ClientInfluencerRequestDetails = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [checkedItems, setCheckedItems] = useState({});
    const [inputValues, setInputValues] = useState({});

    const [lastValidValues, setLastValidValues] = useState({});
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [inputErrors, setInputErrors] = useState({}); // Track input errors per row
    const [updatedRequestData, setUpdatedRequestData] = useState([]); // State for updated values


    const selectedData = location.state;
    const { influencerRequestDatadetails, influencerSuggested, status, message } = useSelector(
        (state) => state.requestInfluencerDetails
    );

    // const { influencerSuggested, statusData: } = useSelector(
    //   (state) => state.requestInfluencerDetails
    // );

    const [requestData, setRequestData] = useState([]);
    const [channelData, setChannelData] = useState({});
    const [toastDisplayed, setToastDisplayed] = useState(false);

    // Fetch request details when component loads
    useEffect(() => {
        if (selectedData?.id) {
            const payload = {
                inf_req_id: selectedData?.id
            };
            dispatch(clientInfluencerDataRequestDetails(payload));
        }
    }, [dispatch, selectedData, !toastDisplayed]);

    // Update request data once the details are fetched
    useEffect(() => {
        if (status === "succeeded") {
            setRequestData(influencerRequestDatadetails?.data || []);
            setUpdatedRequestData(influencerRequestDatadetails?.data || []); // Initialize updated data
        }
    }, [status, influencerRequestDatadetails, toastDisplayed]);

    // Filter and set channel data
    useEffect(() => {
        if (influencerRequestDatadetails?.request_details) {
            const filteredChannelData = Object.entries(
                influencerRequestDatadetails.request_details?.channel_details
            )
                .filter(([key, value]) => key.endsWith("_status") && value === "1")
                .map(([key]) => key.replace("_status", ""))
                .reduce((acc, channel) => {
                    acc[channel] =
                        influencerRequestDatadetails.request_details?.channel_details[
                        channel
                        ];
                    return acc;
                }, {});
            setChannelData(filteredChannelData);
        }
    }, [influencerRequestDatadetails]);

    // Handle selecting all checkboxes

    const handleHeaderCheckboxChange = (checked) => {
        const newCheckedItems = {};
        requestData.forEach((chkData, index) => {
            if (chkData?.SuggestionStatus === 0) {
                newCheckedItems[index] = checked;
            }
        });
        setIsAllChecked(checked);
        setCheckedItems(newCheckedItems);
    };

    // Handle individual checkbox changes
    const handleCheckboxChange = (event, index) => {
        const { checked, name } = event.target;
        setCheckedItems((prevState) => ({
            ...prevState,
            [name]: checked
        }));

        // setIsAllChecked(
        //   Object.values({ ...checkedItems, [index]: checked }).every(Boolean)
        // );
    };

    // Handle input value changes (influencer cost)
    const handleInputChange = (event) => {
        const { name, value } = event.target;



        setInputValues((prev) => {
            // If value is empty, use the last valid value or fallback to data.sales_cost
            if (value === "") {
                return {
                    ...prev,
                    [name]: lastValidValues[name]
                };
            }

            // Update last valid values
            setLastValidValues((prevState) => ({
                ...prevState,
                [name]: value
            }));

            return {
                ...prev,
                [name]: value
            };
        });
    };

    // Handle form submission
    const handleSubmitBtn = () => {
        // let uniqueIndex = 0;
        const anyChecked = Object.values(checkedItems).some(checked => checked);

        if (!anyChecked) {
            toast.error("Please select at least one influencer.");
            return; // Stop further execution if no checkbox is checked
        }
        const selectedRows = requestData
            .map((data, index) => {
                if (checkedItems[`${index}`]) {
                    const cost = inputValues[`cost${index}`];
                    setInputErrors((prev) => ({ ...prev, [index]: false }));
                    if (!cost) {
                        setInputErrors((prev) => ({ ...prev, [index]: true }));
                        toast.error(`Please enter cost for ${data?.influencer_info?.name || 'the influencer'}.`);
                        return null; // Skip this row
                    }

                    const influencerCost = data?.influencer_channel_video_cost; // Assuming this holds the influencer's cost
                    if (parseFloat(cost) < parseFloat(influencerCost)) {
                        setInputErrors((prev) => ({ ...prev, [index]: true }));
                        toast.error(`The cost for ${data?.influencer_info?.name} must be less than the influencer's cost (${influencerCost}).`);
                        return null; // Skip this row
                    }
                    return {
                        chechedValue: checkedItems[`${index}`] || false,
                        uniqueValue: data?.uniqueValue,
                        dm_cost: cost // Editable cost
                    };
                }
                return null; // Not checked
            })
            .filter(Boolean);
        if (selectedRows.length > 0) {
            const requestDataToSend = { selectedRows };
            dispatch(clientInfluencerSuggested(requestDataToSend))
                .unwrap()
                .then((result) => {
                    if (result.status) {
                        setToastDisplayed(true)
                        toast.success(result.message, {
                            onClose: () => {
                                setToastDisplayed(false)
                                // Update the requestData and updatedRequestData on toast close
                                setUpdatedRequestData((prevData) =>
                                    prevData.map((item, index) =>
                                        checkedItems[index] ? { ...item, dm_cost: inputValues[`cost${index}`] } : item
                                    )
                                );
                                setRequestData(influencerRequestDatadetails?.data || []);
                                setCheckedItems([]);
                                setIsAllChecked([]);
                            }
                        });
                    }
                });
        }
    };

    const isAllCheckboxDisabled = updatedRequestData.every(data => data?.SuggestionStatus !== 0);
    // let uniqueIndex = 0;

    const formatCost = (cost) => {
        if (cost >= 1e6) {
            return (cost / 1e6).toFixed(1) + "M";  // For values above 1 million
        } else if (cost >= 1e3) {
            return (cost / 1e3).toFixed(1) + "k";  // For values above 1 thousand
        } else {
            return cost.toString();  // For values less than 1 thousand
        }
    };
    const handleBtnSubmit = (dataRows, index) => {

        const cost = inputValues[`cost${index}`];
        console.log("cost ==>", cost);

        setInputErrors((prev) => ({ ...prev, [index]: false }));
        if (!cost) {
            setInputErrors((prev) => ({ ...prev, [index]: true }));
            toast.error(`Please enter cost for ${dataRows?.influencer_info?.name || 'the influencer'}.`);
            return null; // Skip this row
        }

        const influencerCost = dataRows?.influencer_channel_video_cost; // Assuming this holds the influencer's cost
        if (parseFloat(cost) < parseFloat(influencerCost)) {
            setInputErrors((prev) => ({ ...prev, [index]: true }));
            toast.error(`The cost for ${dataRows?.influencer_info?.name} must be less than the influencer's cost (${influencerCost}).`);
            return null; // Skip this row
        }
        const selectedRows = [{
            chechedValue: checkedItems[`${index}`] || false,
            uniqueValue: dataRows?.uniqueValue,
            dm_cost: cost // Editable cost
        }];



        if (selectedRows !== "") {
            const requestDataToSend = { selectedRows };
            dispatch(clientInfluencerSuggested(requestDataToSend))
                .unwrap()
                .then((result) => {
                    if (result.status) {
                        setToastDisplayed(true)
                        toast.success(result.message, {
                            onClose: () => {
                                setToastDisplayed(false)
                                // Update the requestData and updatedRequestData on toast close
                                setUpdatedRequestData((prevData) =>
                                    prevData.map((item, index) =>
                                        checkedItems[index] ? { ...item, dm_cost: inputValues[`cost${index}`] } : item
                                    )
                                );
                                setRequestData(influencerRequestDatadetails?.data || []);
                                setCheckedItems([]);
                                setIsAllChecked([]);
                            }
                        });
                    }
                });
        }
    }

    return (
        <>
            <ToastContainer />
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Suggested Influencer</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript: void(0);">CRM</a>
                                </li>
                                <li className="breadcrumb-item active">Suggested Influencer</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card" id="leadsList">
                            <div className="card-header border-0">
                                <div className="row g-4 align-items-center">
                                    <div className="col-sm-auto ms-auto">
                                        <div className="hstack gap-2">
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-info"
                                                data-bs-toggle="offcanvas"
                                                href="#offcanvasExample"
                                            >
                                                <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                                                Filters
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-success add-btn"
                                                onClick={handleSubmitBtn}
                                            >
                                                <i className="ri-add-line align-bottom me-1"></i> Send Selected Influencers
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top">
                                <p>
                                    Client With The Request Id:{" "}
                                    <strong>
                                        {influencerRequestDatadetails?.request_details?.req_code}
                                    </strong>{" "}
                                    have requirement of Total Influencer:{" "}
                                    <strong>
                                        {
                                            influencerRequestDatadetails?.request_details
                                                ?.no_of_influencer
                                        }
                                    </strong>
                                    , Other details are mentioned below:
                                </p>
                                {Object.keys(channelData).length > 0 && (
                                    <table className="table table-bordered table-striped rounded">
                                        <thead>
                                            <tr>
                                                {Object.keys(headerData).map((key) => (
                                                    <th key={key}>{headerData[key]}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(channelData).map(([channel, data]) => (
                                                <tr key={channel}>
                                                    <td>{channel}</td>
                                                    <td>{data?.follower_subscriber?.label || "-"}</td>
                                                    <td>
                                                        {data?.language
                                                            ?.map((lang) => lang.label)
                                                            .join(", ") || "-"}
                                                    </td>
                                                    <td>
                                                        {data?.video_type
                                                            ?.map((videoType) => videoType.label)
                                                            .join(", ") || "-"}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                            <div className="card-body">
                                <div className="col-sm-12 border rounded px-2 py-3 background-secodary  mb-2 bg-light text-dark">
                                    <p>
                                        <strong>Hello, 😎 Priyanka Rohilla</strong> you have only{" "}
                                        <strong>
                                            {
                                                influencerRequestDatadetails?.request_details
                                                    ?.active_inf
                                            }{" "}
                                            Active Influencers
                                        </strong>
                                        , which is listed below.
                                    </p>
                                    <hr />
                                    <p>
                                        <strong>Note:</strong> Please select the required checkboxes
                                        below and then click on "Send Selected Influencers" on the
                                        top right of your screen to submit a request for an Influencer Request ID{" "}
                                        {influencerRequestDatadetails?.request_details?.req_code}.
                                    </p>
                                </div>

                                <div className="table-responsive">
                                    {updatedRequestData.length > 0 && !toastDisplayed && (
                                        <table className="table table-bordered table-striped rounded">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <input
                                                            type="checkbox"
                                                            checked={isAllChecked}
                                                            onChange={(e) =>
                                                                handleHeaderCheckboxChange(e.target.checked)
                                                            }
                                                            disabled={isAllCheckboxDisabled} // Disable if all rows are not selectable
                                                        />
                                                    </th>
                                                    {Object.keys(headerDataInfluencer).map((key) => (
                                                        <th key={key}>{headerDataInfluencer[key]}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {updatedRequestData.map((data, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                name={`${index}`}
                                                                disabled={data?.SuggestionStatus !== 0 ? true : false}
                                                                checked={checkedItems[`${index}`] || false}
                                                                onChange={handleCheckboxChange}
                                                            />
                                                        </td>
                                                        <td>{influencerRequestDatadetails?.request_details?.req_code}</td>
                                                        <td>{data?.influencer_info?.name}</td>
                                                        <td>{data?.influencer_channel_name}</td>
                                                        <td>{data?.influencer_channel_video_type || "-"}</td>
                                                        <td>{formatCost(data?.influencer_channel_video_cost)}</td>
                                                        <td>
                                                            <ul className="list-inline hstack gap-0 mb-0">
                                                                <li className="list-inline-item m-0">
                                                                    <FormattedInput
                                                                        type="number"
                                                                        className={`form-control ${inputErrors[index] ? 'is-invalid' : ''}`}  // Apply width class
                                                                        disabled={data?.SuggestionStatus !== 0 ? true : false}
                                                                        name={`cost${index}`}
                                                                        placeholder="Enter cost"
                                                                        value={inputValues[`cost${index}`] || data?.dm_cost}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                </li>
                                                                <li className="list-inline-item"
                                                                    data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                                    data-bs-placement="top" aria-label="Submit"
                                                                    data-bs-original-title="Submit">
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id="tooltip-top">
                                                                                Submit
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <button className="btn btn-sm btn-success"
                                                                            onClick={() => handleBtnSubmit(data, index)}
                                                                        ><i
                                                                            className="ri-checkbox-circle-fill align-bottom fs-16"></i></button>
                                                                    </OverlayTrigger>
                                                                </li>
                                                            </ul>

                                                        </td>
                                                        <td>{data?.label || "-"}</td>
                                                        <td>{data?.influencer_channel_url || "-"}</td>
                                                        <td>{formatCost(data?.influencer_channel_subscribers) || "-"}</td>
                                                        <td>
                                                            {formatCost(data?.influencer_channel_average_Views) || "-"}
                                                        </td>
                                                        <td>
                                                            {data?.influencer_channel_language || ""}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientInfluencerRequestDetails;
