import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosInstance';

// Create an async thunk for fetching influencer request data
export const clientInfluencerDataRequest = createAsyncThunk(
  'influencerRequest/clientInfluencerDataRequest',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/dmexe/influencer_sub_req_list', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Create an async thunk for fetching influencer request data
export const clientInfluencerSalesDataRequest = createAsyncThunk(
  'influencerRequest/clientInfluencerSalesDataRequest',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/sales/show_suggested_list', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const suggestedDmTlInfluencer = createAsyncThunk(
  'influencerRequestDetails/suggestedDmTlInfluencer',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/dm/show_suggested_list', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Closed an influencer request data
export const clientInfluencerClosedRequest = createAsyncThunk(
  'influencerRequest/clientInfluencerClosedRequest',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/dm/req_close_action', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// reopen an influencer request data
export const clientInfluencerReopenRequest = createAsyncThunk(
  'influencerRequest/clientInfluencerReopenRequest',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/dm/req_reopen_action', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Create an async thunk for fetching influencer request data
export const clientInfluencerClientDataRequest = createAsyncThunk(
  'influencerRequest/clientInfluencerClientDataRequest',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/client/show_suggested_list', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Create an async thunk for fetching dm exe request data to dm tl
export const clientInfluencerDmTlRequest = createAsyncThunk(
  'influencerRequest/clientInfluencerDmTlRequest',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/dm/show_suggested_list', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


// Create an async thunk for fetching influencer request details
export const clientInfluencerDataRequestDetails = createAsyncThunk(
  'influencerRequestDetails/clientInfluencerDataRequestDetails',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/dmexe/suggest_influencer', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const remarkUserDropdown = createAsyncThunk(
  'influencerRequestDetails/remarkUserDropdown',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/remark/remark_user_dropdown', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// export const addNewRemark = createAsyncThunk(
//   'influencerRequestDetails/addNewRemark',
//   async (formData, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.post('/remark/test_remark', formData);
//       return response.data;
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );

export const addNewRemark = createAsyncThunk(
  'influencerRequestDetails/addNewRemark',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/remark/add_new_remark', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


// Create an async thunk for fetching influencer request details
export const clientInfluencerSalesDataRequestDetails = createAsyncThunk(
  'influencerRequestDetails/clientInfluencerSalesDataRequestDetails',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/sales/show_suggested_inf', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const suggestedInfluencerRequestDetails = createAsyncThunk(
  'influencerRequestDetails/suggestedInfluencerRequestDetails',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/sales/show_suggested_inf', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


// Create an async thunk for fetching influencer request details for dm tl
export const clientInfluencerDmTlRequestDetails = createAsyncThunk(
  'influencerRequestDetails/clientInfluencerDmTlRequestDetails',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/dm/show_suggested_inf', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Create an async thunk for fetching influencer request details for dm tl
export const clientInfluencerClientRequestDetails = createAsyncThunk(
  'influencerRequestDetails/clientInfluencerClientRequestDetails',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/client/show_suggested_inf', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const clientInfluencerUpdateDetails = createAsyncThunk(
  'influencerRequestDetails/clientInfluencerUpdateDetails',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/client/request_details', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Create an async thunk for fetching influencer request details for dm tl store
export const clientInfluencerClientRequestStoreDetails = createAsyncThunk(
  'influencerRequestDetails/clientInfluencerClientRequestStoreDetails',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/client/bidding_amt_update', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


// Approve clients 
export const clientInfluencerClientRequestApprove = createAsyncThunk(
  'influencerRequestDetails/clientInfluencerClientRequestApprove',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/common/bidding_status', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const clientInfluencerSuggested = createAsyncThunk(
  'influencerRequestDetails/clientInfluencerSuggested',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/dmexe/suggest_influencer_store', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const clientInfluencerDmTlSuggested = createAsyncThunk(
  'influencerRequestDetails/clientInfluencerDmTlSuggested',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/dm/suggest_to_sales', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const clientInfluencerSelesSuggested = createAsyncThunk(
  'influencerRequestDetails/clientInfluencerSelesSuggested',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/sales/suggest_to_client', formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const influencerDetails = createAsyncThunk(
  'influencerRequestDetails/influencerDetails',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/dm/influencer_details', formData);
      console.log("response 123456 ==>", response.data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const influencerRemarksDetails = createAsyncThunk(
  'influencerRequestDetails/influencerRemarksDetails',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/remark/show_remark', formData);
      console.log("response 123456 ==>", response.data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const clientRequestDetails = createAsyncThunk(
  'influencerRequestDetails/clientRequestDetails',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/client/request_details', formData);
      console.log("response 123456 ==>", response.data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const influencerDataUpdate = createAsyncThunk(
  'influencerRequestDetails/influencerDataUpdate',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/dmexe/inf_details_for_edit', formData);
      console.log("response 123456 ==>", response.data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialClientState = {
  data: [],
  pagination: {
    limit: 10,
    page: 1,
    totalItems: 0,
    totalPages: 0
  }
};

// Slice for influencer request data
const influencerRequestSlice = createSlice({
  name: 'influencerRequest',
  initialState: {
    influencerRequestData: { ...initialClientState },
    influencerRequestDmTl: { ...initialClientState },
    influencerRequestSales: { ...initialClientState },
    influencerRequestClient: { ...initialClientState },
    influencerClosedRequest: { ...initialClientState },
    influencerRequestReopen: { ...initialClientState },
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clientInfluencerDataRequest.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerDataRequest.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.influencerRequestData = action.payload;
      })
      .addCase(clientInfluencerDataRequest.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(clientInfluencerDmTlRequest.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerDmTlRequest.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.influencerRequestDmTl = action.payload;
      })
      .addCase(clientInfluencerDmTlRequest.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(clientInfluencerClosedRequest.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerClosedRequest.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.influencerClosedRequest = action.payload;
      })
      .addCase(clientInfluencerClosedRequest.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(clientInfluencerReopenRequest.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerReopenRequest.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.influencerRequestReopen = action.payload;
      })
      .addCase(clientInfluencerReopenRequest.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(clientInfluencerSalesDataRequest.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerSalesDataRequest.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.influencerRequestSales = action.payload;
      })
      .addCase(clientInfluencerSalesDataRequest.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(clientInfluencerClientDataRequest.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerClientDataRequest.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.influencerRequestClient = action.payload;
      })
      .addCase(clientInfluencerClientDataRequest.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

// Slice for influencer request details
const influencerRequestDetailsSlice = createSlice({
  name: 'influencerRequestDetails',
  initialState: {
    data: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clientInfluencerDataRequestDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerDataRequestDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.influencerRequestDatadetails = action.payload;
      })
      .addCase(clientInfluencerDataRequestDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(remarkUserDropdown.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(remarkUserDropdown.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.influencerUserDropdown = action.payload;
      })
      .addCase(remarkUserDropdown.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(addNewRemark.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addNewRemark.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.influencerAddNewRemark = action.payload;
      })
      .addCase(addNewRemark.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(clientInfluencerSuggested.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerSuggested.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.influencerSuggested = action.payload;
      })
      .addCase(clientInfluencerSuggested.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(clientInfluencerDmTlSuggested.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerDmTlSuggested.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.influencerSuggestedDmTl = action.payload;
      })
      .addCase(clientInfluencerDmTlSuggested.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(clientInfluencerSelesSuggested.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerSelesSuggested.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.influencerSuggestedSales = action.payload;
      })
      .addCase(clientInfluencerSelesSuggested.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(clientInfluencerDmTlRequestDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerDmTlRequestDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clientInfluencerDmTlDetails = action.payload;
      })
      .addCase(clientInfluencerDmTlRequestDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(clientInfluencerSalesDataRequestDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerSalesDataRequestDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clientInfluencerSalesDetails = action.payload;
      })
      .addCase(clientInfluencerSalesDataRequestDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(suggestedInfluencerRequestDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(suggestedInfluencerRequestDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.suggestedClientInfDetails = action.payload;
      })
      .addCase(suggestedInfluencerRequestDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(suggestedDmTlInfluencer.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(suggestedDmTlInfluencer.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.suggestedDmTlInfluencerDetails = action.payload;
      })
      .addCase(suggestedDmTlInfluencer.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(clientInfluencerClientRequestDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerClientRequestDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clientInfluencerClientDetails = action.payload;
      })
      .addCase(clientInfluencerClientRequestDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(influencerDataUpdate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(influencerDataUpdate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.updateInfluencerData = action.payload;
      })
      .addCase(influencerDataUpdate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(clientInfluencerClientRequestStoreDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerClientRequestStoreDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clientInfluencerClientDetailsStore = action.payload;
      })
      .addCase(clientInfluencerClientRequestStoreDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(clientInfluencerClientRequestApprove.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerClientRequestApprove.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clientInfluencerClientApprove = action.payload;
      })
      .addCase(clientInfluencerClientRequestApprove.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(influencerDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(influencerDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.influencerDataDetails = action.payload;
      })
      .addCase(influencerDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(clientInfluencerUpdateDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerUpdateDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.updateInfluencerDetails = action.payload;
      })
      .addCase(clientInfluencerUpdateDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(clientRequestDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientRequestDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clientsRequestDetails = action.payload;
      })
      .addCase(clientRequestDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const influencerRequestReducer = influencerRequestSlice.reducer;
export const influencerRequestDetailsReducer = influencerRequestDetailsSlice.reducer;
