import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { clientInfluencerDmTlRequestDetails, clientInfluencerDmTlSuggested, clientInfluencerClosedRequest, clientInfluencerReopenRequest, clientInfluencerClientRequestApprove } from "../../features/influencerRequestSlice/influencerRequestSlice";
import { ToastContainer, toast } from 'react-toastify';
import FormattedInput from "../common/FormattedInput";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import CreateVideoDM from "../common/CreateVideoDM";
import CreateVideo from "../common/CreateVideo";
import FormattedInputWidth from "../common/FormattedInputWidth";


export const headerData = {
  channel: "Channels",
  followers_subs: "Required Followers / Subscriber",
  languges: "Language",
  video_type: "Video Type"
};

export const headerDataInfluencer = {
  sugg_id: "Suggest Id",
  reg_id: "Req Id",
  influencer_name: "Influencer Name",
  channel_name: "Channel Name",
  video_type: "Video Type",
  inf_coste: "Influencer Cost",
  DmCost: "DM Exe Cost",
  editable_inf_coste: "Editable Influencer Cost",
  video_cat: "Video Category",
  channel_link: "Channel Link",
  subscribe: "Subscribers",
  ave_view: "Average Views",
  languges: "Language",
  action: "Action"
};

const ClientInfluencerRequestDmTLDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate()

  const [checkedItems, setCheckedItems] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [inputErrors, setInputErrors] = useState({}); // Track input errors per row

  const [updatedRequestData, setUpdatedRequestData] = useState([]); // State for updated values


  const selectedData = location.state;
  const { clientInfluencerDmTlDetails, influencerClosedRequest, influencerRequestReopen, status, message } = useSelector(
    (state) => state.requestInfluencerDetails
  );

  // const { influencerSuggested, statusData: } = useSelector(
  //   (state) => state.requestInfluencerDetails
  // );

  const [requestData, setRequestData] = useState([]);
  const [channelData, setChannelData] = useState({});
  const [toastDisplayed, setToastDisplayed] = useState(false);
  const urlSegment = location.pathname.split("/")[1];

  // Fetch request details when component loads
  useEffect(() => {


    if (selectedData?.id) {
      const payload = {
        inf_req_id: selectedData?.id,
        sugg_status: urlSegment === "client-influencer-request-details-dm-tl" ? "3" : urlSegment === "approved-client-influencer-request-details-dm-tl" ? "5"
          : urlSegment === "rejected-client-influencer-request-details-dm-tl" && "7"
      };
      dispatch(clientInfluencerDmTlRequestDetails(payload));
    }
  }, [dispatch, selectedData, !toastDisplayed]);

  // Update request data once the details are fetched
  useEffect(() => {
    if (status === "succeeded") {
      setRequestData(clientInfluencerDmTlDetails?.data || []);
      setUpdatedRequestData(clientInfluencerDmTlDetails?.data || []); // Initialize updated data
    }
  }, [status, clientInfluencerDmTlDetails, toastDisplayed]);


  // Filter and set channel data
  useEffect(() => {
    if (clientInfluencerDmTlDetails?.request_details) {
      const filteredChannelData = Object.entries(
        clientInfluencerDmTlDetails.request_details?.channel_details
      )
        .filter(([key, value]) => key.endsWith("_status") && value === "1")
        .map(([key]) => key.replace("_status", ""))
        .reduce((acc, channel) => {
          acc[channel] =
            clientInfluencerDmTlDetails.request_details?.channel_details[
            channel
            ];
          return acc;
        }, {});
      setChannelData(filteredChannelData);
    }
  }, [clientInfluencerDmTlDetails]);

  // Handle selecting all checkboxes

  const handleHeaderCheckboxChange = (checked, index) => {
    const newCheckedItems = {};
    requestData.forEach((chkData, ind) => {
      if (chkData?.SuggestionStatus !== 2) {
        newCheckedItems[ind] = checked;
      }
    });
    setIsAllChecked(checked);
    setCheckedItems(newCheckedItems);
  };

  // Handle individual checkbox changes
  const handleCheckboxChange = (event, index) => {
    const { checked, name } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked
    }));

    // setIsAllChecked(
    //   Object.values({ ...checkedItems, [index]: checked }).every(Boolean)
    // );
  };

  // Handle input value changes (influencer cost)
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle form submission
  const handleSubmitBtn = () => {
    const anyChecked = Object.values(checkedItems).some(checked => checked);

    if (!anyChecked) {
      toast.error("Please select at least one influencer.");
      return; // Stop further execution if no checkbox is checked
    }

    const selectedRows = requestData
      .map((data, index) => {
        if (checkedItems[`${index}`]) {
          const cost = inputValues[`cost${index}`];
          if (!cost) {
            // Show error if the input is empty
            toast.error(`Please enter cost for ${data?.inf_name || 'the influencer'}.`);
            return null; // Skip this row
          }
          const influencerCost = data?.dm_cost; // Assuming this holds the influencer's cost
          if (parseFloat(cost) < parseFloat(influencerCost)) {
            setInputErrors((prev) => ({ ...prev, [index]: true }));
            toast.error(`The cost for ${data?.influencer_info?.name} must be less than the influencer's cost (${influencerCost}).`);
            return null; // Skip this row
          }

          return {
            chechedValue: checkedItems[`${index}`] || false,
            uniqueValue: data?.unique_check,
            dm_tl_cost: cost // Editable cost
          };
        }
        return null; // Not checked
      })
      .filter(Boolean);
    if (selectedRows.length > 0) {
      const requestDataToSend = { selectedRows };
      dispatch(clientInfluencerDmTlSuggested(requestDataToSend))
        .unwrap()
        .then((result) => {
          if (result.status) {
            setToastDisplayed(true)
            toast.success(result.message, {
              onClose: () => {
                setToastDisplayed(false)
                // Update the requestData and updatedRequestData on toast close
                setUpdatedRequestData((prevData) =>
                  prevData.map((item, index) =>
                    checkedItems[index] ? { ...item, dm_cost: inputValues[`cost${index}`] } : item
                  )
                );
                setRequestData(clientInfluencerDmTlDetails?.data || []);
                setCheckedItems([]);
                setIsAllChecked([]);
              }
            });
          }
        });
    }
  };


  const handleClosed = () => {
    const requestDataToSend = {
      inf_req_id: selectedData?.id

    };
    dispatch(clientInfluencerClosedRequest(requestDataToSend))
      .unwrap()
      .then((result) => {
        if (result.status) {
          setToastDisplayed(true)
          toast.success(result.message, {
            onClose: () => {
              setToastDisplayed(false)
            }
          });
        }
      });
  }

  const handleReopen = () => {
    const requestDataToSend = {
      inf_req_id: selectedData?.id

    };
    dispatch(clientInfluencerReopenRequest(requestDataToSend))
      .unwrap()
      .then((result) => {
        if (result.status) {
          setToastDisplayed(true)
          toast.success(result.message, {
            onClose: () => {
              setToastDisplayed(false)
            }
          });
        }
      });
  }

  const handleApproveBtn = (rowsData, index) => {

  };

  const formatCost = (cost) => {
    if (cost >= 1e6) {
      return (cost / 1e6).toFixed(1) + "M";  // For values above 1 million
    } else if (cost >= 1e3) {
      return (cost / 1e3).toFixed(1) + "k";  // For values above 1 thousand
    } else {
      return cost.toString();  // For values less than 1 thousand
    }
  };

  const handleViewDetails = (data) => {
    navigate(`/suggested-influencer-details_by_unique/${data?.id}`, { state: data })
  }

  const [videoDmData, setVideoDmData] = useState('')
  const [addDmVideo, setAddDmVideo] = useState(false)

  const handleAddVideo = (dataRows) => {
    setVideoDmData(dataRows)
    setAddDmVideo(true)
  }

  // let uniqueIndex = 0;
  const isAllCheckboxDisabled = updatedRequestData.every(data => (data?.SuggestionStatus === 2 || urlSegment !== "client-influencer-request-details-dm-tl"));
  const PageTitles =
    urlSegment === "client-influencer-request-details-dm-tl" ? "Suggested Influencer"
      : urlSegment === "approved-client-influencer-request-details-dm-tl" ? "Approved Influencer"
        : urlSegment === "rejected-client-influencer-request-details-dm-tl" && "Rejected Influencer";



  const handleBtnSubmit = (dataRows, index) => {


    const cost = inputValues[`cost${index}`];

    // Check if the cost is provided
    if (!cost) {
      // Show error if the input is empty
      toast.error(`Please enter cost for ${dataRows?.inf_name || 'the influencer'}.`);
      return null; // Skip this row
    }

    const influencerCost = dataRows?.dm_cost; // Assuming this holds the influencer's cost
    if (parseFloat(cost) < parseFloat(influencerCost)) {
      setInputErrors((prev) => ({ ...prev, [index]: true }));
      toast.error(`The cost for ${dataRows?.influencer_info?.name} must be greater than the influencer's cost (${influencerCost}).`);
      return null; // Skip this row
    }

    // Prepare the selected row data
    const selectedRows = [{
      checkedValue: checkedItems[`${index}`] || false, // Use the correct key for checked status
      dm_tl_cost: cost, // Set sales_cost with the value from input
      uniqueValue: dataRows?.unique_check // Assuming this is the unique identifier
    }];

    // Final payload
    const finalPayloadData = { selectedRows };

    dispatch(clientInfluencerDmTlSuggested(finalPayloadData))
      .unwrap()
      .then((result) => {
        if (result.status) {
          setToastDisplayed(true)
          toast.success(result.message, {
            onClose: () => {
              setToastDisplayed(false)
              // Update the requestData and updatedRequestData on toast close
              setUpdatedRequestData((prevData) =>
                prevData.map((item, index) =>
                  checkedItems[index] ? { ...item, dm_cost: inputValues[`cost${index}`] } : item
                )
              );
              setRequestData(clientInfluencerDmTlDetails?.data || []);
              setCheckedItems([]);
              setIsAllChecked([]);
            }
          });
        }
      });

  };

  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">{PageTitles}</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a href="javascript: void(0);">CRM</a>
                </li>
                <li className="breadcrumb-item active">{PageTitles}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card" id="leadsList">
              <div className="card-header border-0">
                <div className="row g-4 align-items-center">
                  <div className="col-sm-auto ms-auto">
                    <div className="hstack gap-2">

                      {clientInfluencerDmTlDetails?.request_details?.req_close_open === 0 ?
                        <button type="button"
                          className="btn btn-sm btn-danger"
                          onClick={handleClosed}>Close</button>
                        :
                        <button type="button"
                          className="btn btn-sm btn-danger"
                          onClick={handleReopen}>Reopen</button>

                      }
                      <button
                        type="button"
                        className="btn btn-sm btn-info"
                      >
                        <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                        Filters
                      </button>
                      {PageTitles === "Suggested Influencer" && clientInfluencerDmTlDetails?.request_details?.req_close_open === 0 &&
                        <button
                          type="button"
                          className="btn btn-sm btn-success add-btn"
                          onClick={handleSubmitBtn}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Send Selected Influencers
                        </button>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body border-top">
                <p>
                  Client With The Request Id:{" "}
                  <strong>
                    {selectedData?.req_code}
                  </strong>{" "}
                  have requirement of Total Influencer:{" "}
                  <strong>
                    {
                      selectedData?.no_of_influencer
                    }
                  </strong>
                  , Other details are mentioned below:
                </p>
                {Object.keys(channelData).length > 0 && (
                  <table className="table table-bordered table-striped rounded">
                    <thead>
                      <tr>
                        {Object.keys(headerData).map((key) => (
                          <th key={key}>{headerData[key]}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(channelData).map(([channel, data]) => (
                        <tr key={channel}>
                          <td>{channel}</td>
                          <td>{data?.follower_subscriber?.label || "-"}</td>
                          <td>
                            {data?.language
                              ?.map((lang) => lang.label)
                              .join(", ") || "-"}
                          </td>
                          <td>
                            {data?.video_type
                              ?.map((videoType) => videoType.label)
                              .join(", ") || "-"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
              <div className="card-body">
                <div className="col-sm-12 border rounded px-2 py-3 background-secodary  mb-2 bg-light text-dark">


                  <p>
                    <strong>Note:</strong> Please select the required checkboxes
                    below and then click on "Send Selected Influencers" on the
                    top right of your screen to submit a request to the Sales
                    Team for an Influencer Request ID{" "}
                    {clientInfluencerDmTlDetails?.request_details?.req_code}.
                  </p>
                </div>

                <div className="table-responsive">
                  {updatedRequestData.length > 0 && !toastDisplayed && (
                    <table className="table table-bordered table-striped rounded">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={isAllChecked}
                              onChange={(e) =>
                                handleHeaderCheckboxChange(e.target.checked)
                              }
                              disabled={isAllCheckboxDisabled}
                            />
                          </th>
                          {Object.keys(headerDataInfluencer).map((key) => (
                            <th key={key}>{headerDataInfluencer[key]}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {updatedRequestData.map((data, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="checkbox"
                                name={`${index}`}
                                disabled={data?.SuggestionStatus === 2 || urlSegment === "approved-client-influencer-request-details-dm-tl" || urlSegment === "rejected-client-influencer-request-details-dm-tl" ? true : false}
                                checked={checkedItems[`${index}`] || false}
                                onChange={handleCheckboxChange}
                              />
                            </td>
                            <td>{data?.inf_suggestion_id}</td>
                            <td>{data?.inf_req_code}</td>
                            <td>{data?.inf_name}</td>
                            <td>{data?.req_channel_name}</td>
                            <td>{data?.req_video_type || "-"}</td>
                            <td>{formatCost(data?.influencer_cost)}</td>
                            <td>{formatCost(data?.dm_cost)}</td>
                            <td>
                              <ul className="list-inline hstack gap-0 mb-0">
                                <li className="list-inline-item m-0">
                                  <FormattedInputWidth
                                    type="number"
                                    className={`form-control ${inputErrors[index] ? 'is-invalid' : ''}`}  // Apply is-invalid conditionally
                                    disabled={data?.SuggestionStatus === 2 ? true : false}
                                    name={`cost${index}`}
                                    placeholder="Enter cost"
                                    value={inputValues[`cost${index}`] || data?.dm_tl_cost}
                                    onChange={handleInputChange}
                                  />
                                </li>
                                <li className="list-inline-item"
                                  data-bs-toggle="tooltip" data-bs-trigger="hover"
                                  data-bs-placement="top" aria-label="Submit"
                                  data-bs-original-title="Submit">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip-top">
                                        Submit
                                      </Tooltip>
                                    }
                                  >
                                    <button className="btn btn-sm btn-success"

                                      disabled={data?.SuggestionStatus === 2 || urlSegment === "approved-client-influencer-request-details-dm-tl" || urlSegment === "rejected-client-influencer-request-details-dm-tl" ? true : false}
                                      onClick={() => handleBtnSubmit(data, index)}
                                    ><i
                                      className="ri-checkbox-circle-fill align-bottom fs-16"></i></button>
                                  </OverlayTrigger>
                                </li>
                              </ul>

                            </td>
                            <td>{data?.label || "-"}</td>
                            <td>{data?.inf_channel_url || "-"}</td>
                            <td>{data?.req_subscriber || "-"}</td>
                            <td>
                              {data?.inf_views || "-"}
                            </td>
                            <td>
                              {data?.inf_language || ""}
                            </td>
                            <td>
                              <ul className="list-inline hstack mb-0">
                                {PageTitles === "Approved Influencer" && (

                                  <li className="list-inline-item">
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="tooltip-top">
                                          Add Video
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        title="Request send to DM"
                                        className="btn btn-sm btn-info edit-item-btn"
                                        onClick={() => handleAddVideo(data)}
                                      >
                                        <i className="ri-add-fill align-bottom me-1"></i>
                                      </Button>
                                    </OverlayTrigger>
                                  </li>
                                )}
                                <li className="list-inline-item">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip-top">
                                        View Details
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      title="Request send to DM"
                                      className="btn btn-sm btn-info edit-item-btn"
                                      onClick={() => handleViewDetails(data)}
                                    >
                                      <i className="ri-eye-fill align-bottom me-1"></i>
                                    </Button>
                                  </OverlayTrigger>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateVideo
        showDeleteModal={addDmVideo}
        selectedRowsData={videoDmData}
        setSelectedRowsData={setVideoDmData}
        setShowDeleteModal={setAddDmVideo}
      />
    </>
  );
};

export default ClientInfluencerRequestDmTLDetails;
