import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

// Create an async thunk for fetching data
export const fetchClientData = createAsyncThunk(
  "clients/fetchClientData",
  async ({ status_id, formData, page, limit }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/dm/influencer_list", {
        status_id,
        channel_name: formData?.channel_name,
        categories: formData?.categories,
        languages: formData?.languages,
        budget: formData?.budget,
        subscribers: formData?.subscribers,
        views: formData?.views,
        page,
        limit
      });
      return { status_id, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchClientFilteredData = createAsyncThunk(
  "clients/fetchClientFilteredData",
  async ({ status_id, page, limit }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/dm/influencer_list", {
        status_id,
        page,
        limit
      });
      return { status_id, data: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// export const fetchInfluencerData = createAsyncThunk(
//   "clients/fetchInfluencerData",
//   async (inf_id, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.post("/dmexe/inf_details_for_edit", {
//         inf_id
//       });
//       return response.data;
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );

export const clientRequestInf = createAsyncThunk(
  "clients/clientRequestInf",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/dmexe/request_influencer", formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchInfluencerData = createAsyncThunk(
  "clients/fetchInfluencerData",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/dmexe/inf_details_for_edit", formData);
      console.log("hfdshfsdhfksdh ==>", response.data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


const initialClientState = {
  data: [],
  pagination: {
    limit: 0,
    page: 0,
    totalItems: 0,
    totalPages: 0
  }
};

const influencerSlice = createSlice({
  name: "clients",
  initialState: {
    allClientData: { ...initialClientState },
    activeClientData: { ...initialClientState },
    pendingClientData: { ...initialClientState },
    inactiveClientData: { ...initialClientState },
    assignedClientData: { ...initialClientState },
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchClientData.fulfilled, (state, action) => {
        const { status_id, data } = action.payload;
        state.status = "succeeded";
        if (status_id === "1") {
          state.activeClientData = data;
        } else if (status_id === "0") {
          state.pendingClientData = data;
        } else if (status_id === "4") {
          state.inactiveClientData = data;
        } else if (status_id === "99") {
          state.allClientData = data;
        } else if (status_id === "5") {
          state.assignedClientData = data;
        }
      })
      .addCase(fetchClientData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchClientFilteredData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchClientFilteredData.fulfilled, (state, action) => {
        const { status_id, data } = action.payload;
        state.status = "succeeded";
        if (status_id === "1") {
          state.activeClientData = data;
        } else if (status_id === "0") {
          state.pendingClientData = data;
        } else if (status_id === "4") {
          state.inactiveClientData = data;
        } else if (status_id === "99") {
          state.allClientData = data;
        } else if (status_id === "5") {
          state.assignedClientData = data;
        }
      })
      .addCase(fetchClientFilteredData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(clientRequestInf.pending, (state) => {
        state.status = "loading";
      })
      .addCase(clientRequestInf.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clientInfluencerRequest = action.payload;
      })
      .addCase(clientRequestInf.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  }
});

export default influencerSlice.reducer;
