import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const DmNavbar = () => {
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState(null);

    useEffect(() => {
        // Retrieve the active menu from localStorage on load
        const savedMenu = localStorage.getItem('activeMenu');
        if (savedMenu) {
            setActiveMenu(savedMenu);
        }
    }, []);

    const toggleMenu = (menuId) => {
        if (activeMenu === menuId) {
            // Close the menu if it's already open
            setActiveMenu(null);
            localStorage.removeItem('activeMenu');
        } else {
            // Open the selected menu
            setActiveMenu(menuId);
            localStorage.setItem('activeMenu', menuId);
        }
    };

    return (
        <>
            <li className="nav-item">
                <a className="nav-link menu-link" href="#dmexecutive" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarVideos">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-videos">Influencer</span>
                </a>
                <div className="collapse menu-dropdown" id="dmexecutive">
                    <ul className="nav nav-sm flex-column">

                        <li className="nav-item">
                            <Link
                                to="/active-influencer"
                                className={`nav-link ${location.pathname === '/active-influencer' ? 'active' : ''}`}
                                data-key="t-analytics"
                            >
                                Active Influencer
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/assigned-influencer"
                                className={`nav-link ${location.pathname === '/assigned-influencer' ? 'active' : ''}`}
                                data-key="t-ecommerce"
                            >
                                Assigned Influencer
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/pending-influencer"
                                className={`nav-link ${location.pathname === '/pending-influencer' ? 'active' : ''}`}
                                data-key="t-analytics"
                            >
                                Pending Influencer
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/inactive-influencer"
                                className={`nav-link ${location.pathname === '/inactive-influencer' ? 'active' : ''}`}
                                data-key="t-crm"
                            >
                                Inactive Influencer
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/all-influencer"
                                className={`nav-link ${location.pathname === '/all-influencer' ? 'active' : ''}`}
                                data-key="t-ecommerce"
                            >
                                All Influencer
                            </Link>
                        </li>
                    </ul>
                </div>
            </li>
            <li className="nav-item">
                <a className="nav-link menu-link" href="#infrequest" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarVideos">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-videos">Sub Request</span>
                </a>
                <div className="collapse menu-dropdown" id="infrequest">
                    <ul className="nav nav-sm flex-column">

                        <li className="nav-item">
                            <Link
                                to="/suggested-influencer"
                                className={`nav-link ${location.pathname === '/suggested-influencer' ? 'active' : ''}`}
                                data-key="t-ecommerce"
                            >
                                Influencer Sub Request
                            </Link>
                        </li>
                    </ul>
                </div>
            </li>


            {/* <li className="nav-item">
                <a className="nav-link menu-link" href="#clientinfreq" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarVideos">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-videos">Suggested Influencer</span>
                </a>
                <div className="collapse menu-dropdown" id="clientinfreq">
                    <ul className="nav nav-sm flex-column">

                        <li className="nav-item">
                            <Link
                                to="/suggested-influencer"
                                className={`nav-link ${location.pathname === '/suggested-influencer' ? 'active' : ''}`}
                                data-key="t-ecommerce"
                            >
                                Suggested Influencer
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/approved-influencer"
                                className={`nav-link ${location.pathname === '/approved-influencer' ? 'active' : ''}`}
                                data-key="t-ecommerce1"
                            >
                                Approved Influencer
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/rejected-influencer"
                                className={`nav-link ${location.pathname === '/approved-influencer' ? 'active' : ''}`}
                                data-key="t-ecommerce2"
                            >
                                Rejected Influencer
                            </Link>
                        </li>
                    </ul>
                </div>
            </li> */}

            <li className="nav-item">
                <a className="nav-link menu-link" href="#videos" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarVideos">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-videos">Videos</span>
                </a>
                <div className="collapse menu-dropdown" id="videos">
                    <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                            <Link to="/upcoming-videos" className="nav-link" data-key="t-analytics">Upcoming Videos</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/post-videos" className="nav-link" data-key="t-analytics">Post Videos</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/all-videos" className="nav-link" data-key="t-analytics">All</Link>
                        </li>
                    </ul>
                </div>
            </li>
        </>
    );
};

export default DmNavbar;
