import React from 'react';
import { Link } from 'react-router-dom';

const ClientsNavbar = () => {
    return (
        <>
            <li className="nav-item">
                <a className="nav-link menu-link active" href="#sidebarDashboards" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarDashboards">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-influencers">My Request</span>
                </a>
                <div className="collapse menu-dropdown" id="sidebarDashboards">
                    <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                            <Link to="/client-all-request" className="nav-link" data-key="t-analytics">All Request </Link>

                        </li>
                        {/* <li className="nav-item">
                        <Link to="/rejected-influencers" className="nav-link" data-key="t-analytics">Rejected Influencers</Link>
                            
                        </li>
                        <li className="nav-item">
                        <Link to="/suggested-influencers" className="nav-link" data-key="t-analytics">Suggested Influencers</Link>
                            
                        </li> */}
                    </ul>
                </div>
            </li>
            {/* <li className="nav-item">
                <a className="nav-link menu-link" href="#sidebarVideos" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarVideos">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-videos">Videos</span>
                </a>
                <div className="collapse menu-dropdown" id="sidebarVideos">
                    <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                        <Link to="/upcoming-videos" className="nav-link" data-key="t-analytics">Upcoming Videos</Link>
                           
                        </li>
                        <li className="nav-item">
                        <Link to="/past-videos" className="nav-link" data-key="t-analytics"> Past Videos</Link>
                            
                        </li>
                        <li className="nav-item">
                        <Link to="/all-videos" className="nav-link" data-key="t-analytics">All Videos</Link>
                            
                        </li>
                    </ul>
                </div>
            </li> */}

            <li className="nav-item">
                <a className="nav-link menu-link" href="#salesSuggest" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarVideos">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-videos">Influencer</span>
                </a>
                <div className="collapse menu-dropdown" id="salesSuggest">
                    <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                            <Link to="/suggested-influencer-by-sales" className="nav-link" data-key="t-analytics">Suggested Influencer</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/approved-suggested-influencer-by-sales" className="nav-link" data-key="t-analytics">Approved Influencer</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/rejected-suggested-influencer-by-sales" className="nav-link" data-key="t-analytics">Rejected Influencer</Link>
                        </li>
                    </ul>
                </div>
            </li>
            <li className="nav-item">
                <a className="nav-link menu-link" href="#videos" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarVideos">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-videos">Videos</span>
                </a>
                <div className="collapse menu-dropdown" id="videos">
                    <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                            <Link to="/upcoming-videos" className="nav-link" data-key="t-analytics">Upcoming Videos</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/post-videos" className="nav-link" data-key="t-analytics">Post Videos</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/all-videos" className="nav-link" data-key="t-analytics">All</Link>
                        </li>
                    </ul>
                </div>
            </li>
        </>
    );
}

export default ClientsNavbar;
