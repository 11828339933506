import React, { useEffect, useState } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { clientInfluencerUpdateDetails } from "../../features/influencerRequestSlice/influencerRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import useDecodedToken from "../../features/utils/hooks/useDecodedToken";
import FormattedInput from "../common/FormattedInput";
import { clientUpdateInfluencerRequest } from "../../features/clientInfluencerRequestSlice/clientInfluencerRequestSlice";
import {
  fetchLanguageData,
  fetchGenreData,
} from "../../features/commonSlice/commonSlice";

const budgetData = [
  { label: "0-5", value: "0-5" },
  { label: "10-20", value: "6-10" },
  { label: "20-30", value: "11-15" },
  { label: "30-40", value: "16-20" },
  { label: "40-50", value: "21-50" },
  { label: "50+", value: "50+" },
  { label: "100+", value: "100+" }
];
const videoTypeData = [
  { label: "Dedicated", value: 1 },
  { label: "Integrated", value: 2 },
  { label: "Conceptual", value: 3 }
];

const follower_subscribers = [
  { label: "0-5k", value: "0-5k" },
  { label: "5k-10k", value: "5k-10k" },
  { label: "10k-30k", value: "10k-30k" },
  { label: "30k-50k", value: "30k-50k" },
  { label: "50k-100k", value: "50k-100k" },
  { label: "100k-200k", value: "100k-200k" },
  { label: "200k-300k", value: "200k-300k" },
  { label: "300k-500k", value: "300k-500k" },
  { label: "500k-1M", value: "500k-1M" },
  { label: "1M-2M", value: "1M-2M" },
  { label: "2M-5M", value: "2M-5M" },
  { label: "5M-10M", value: "5M-10M" },
  { label: "10M-20M", value: "10M-20M" },
  { label: "20M-30M", value: "20M-30M" },
  { label: "30M-50M", value: "30M-50M" },
  { label: "50M-100M", value: "50M-100M" },
  { label: "100M-200M", value: "100M-200M" },
  { label: "200M-300M", value: "200M-300M" },
  { label: "300M-400M", value: "300M-400M" },
  { label: "400M-500M", value: "400M-500M" },
  { label: "500M+", value: "500M+" }
];

const ApprovedUpdateInfluencers = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const decodedToken = useDecodedToken();
  const selectedData = location.state;
  const [errors, setErrors] = useState("");
  const [errorData, setErrorData] = useState([]);
  const [langList, setLangList] = useState([]);
  const [loginError, setLoginError] = useState("");
  const [genreList, setGenreList] = useState([]);
  const [showModal, setShowModal] = useState(false)
  const { languageData, genreData, status: commonStatus } = useSelector(
    (state) => state.influencer
  );

  useEffect(() => {
    console.log("selectedData ==>", selectedData);

  }, [selectedData])

  useEffect(() => {
    if (selectedData) {

    }
  })

  useEffect(() => {
    dispatch(fetchLanguageData());
    dispatch(fetchGenreData());
  }, [dispatch]);
  useEffect(() => {
    if (commonStatus === "succeeded") {
      setLangList(languageData);
      setGenreList(genreData);
    }
  }, [commonStatus]);

  const [formData, setFormData] = useState({
    product: "",
    no_of_influencer: "",
    budget: "",
    channel_data: {
      youTube_status: "",
      youTube: {
        language: [],
        min_budget: "",
        max_budget: "",
        genre: "",
        follower_subscriber: "",
        video_type: []
      },
      facebook_status: "",
      facebook: { language: [], min_budget: "", max_budget: "", genre: "", follower_subscriber: "" },
      instagram_status: "",
      instagram: { language: [], min_budget: "", max_budget: "", genre: "", follower_subscriber: "" },
      twitter_X_status: "",
      twitter_X: { language: [], min_budget: "", max_budget: "", genre: "", follower_subscriber: "" },
      linkedIn_status: "",
      linkedIn: { language: [], min_budget: "", max_budget: "", genre: "", follower_subscriber: "" },
      whatsapp_status: "",
      whatsapp: { language: [], min_budget: "", max_budget: "", genre: "", follower_subscriber: "" },
      telegram_status: "",
      telegram: { language: [], min_budget: "", max_budget: "", genre: "", follower_subscriber: "" },
      threads_status: "",
      threads: { language: [], min_budget: "", max_budget: "", genre: "", follower_subscriber: "" }
    }
  });




  useEffect(() => {
    if (selectedData) {
      const payload = { inf_req_id: selectedData };
      dispatch(clientInfluencerUpdateDetails(payload));
    }
  }, [dispatch, selectedData]);

  const { updateInfluencerDetails } = useSelector(
    (state) => state.requestInfluencerDetails
  );
  const [updateInfData, setUpdateInfData] = useState(null);

  // Set updateInfData when data is fetched
  useEffect(() => {
    if (updateInfluencerDetails?.data?.length > 0) {
      setUpdateInfData(updateInfluencerDetails.data[0]);
    }
  }, [updateInfluencerDetails]);

  // Update formData based on updateInfData
  useEffect(() => {
    if (updateInfData) {
      setFormData({
        product: updateInfData.product || "",
        no_of_influencer: updateInfData.no_of_influencer || "",
        budget: updateInfData.budget || "",
        channel_data: {
          youTube_status: updateInfData.channel_data?.youTube_status || "",
          youTube: {
            language: updateInfData.channel_data?.youTube?.language || [],
            min_budget: updateInfData.channel_data?.youTube?.min_budget || "",
            max_budget: updateInfData.channel_data?.youTube?.max_budget || "",
            genre:
              updateInfData.channel_data?.youTube?.genre || "",
            follower_subscriber:
              updateInfData.channel_data?.youTube?.follower_subscriber || "",
            video_type: updateInfData.channel_data?.youTube?.video_type || []
          },
          facebook_status: updateInfData.channel_data?.facebook_status || "",
          facebook: {
            language: updateInfData.channel_data?.facebook?.language || [],
            min_budget: updateInfData.channel_data?.facebook?.min_budget || "",
            max_budget: updateInfData.channel_data?.facebook?.max_budget || "",
            genre: updateInfData.channel_data?.facebook?.genre || "",
            follower_subscriber:
              updateInfData.channel_data?.facebook?.follower_subscriber || ""
          },
          instagram_status: updateInfData.channel_data?.instagram_status || "",
          instagram: {
            language: updateInfData.channel_data?.instagram?.language || [],
            min_budget: updateInfData.channel_data?.instagram?.min_budget || "",
            max_budget: updateInfData.channel_data?.instagram?.max_budget || "",
            genre: updateInfData.channel_data?.instagram?.genre || "",
            follower_subscriber:
              updateInfData.channel_data?.instagram?.follower_subscriber || ""
          },
          twitter_X_status: updateInfData.channel_data?.twitter_X_status || "",
          twitter_X: {
            language: updateInfData.channel_data?.twitter_X?.language || [],
            min_budget: updateInfData.channel_data?.twitter_X?.min_budget || "",
            max_budget: updateInfData.channel_data?.twitter_X?.max_budget || "",
            genre: updateInfData.channel_data?.twitter_X?.genre || "",
            follower_subscriber:
              updateInfData.channel_data?.twitter_X?.follower_subscriber || ""
          },
          linkedIn_status: updateInfData.channel_data?.linkedIn_status || "",
          linkedIn: {
            language: updateInfData.channel_data?.linkedIn?.language || [],
            min_budget: updateInfData.channel_data?.linkedIn?.min_budget || "",
            min_budget: updateInfData.channel_data?.linkedIn?.max_budget || "",
            genre: updateInfData.channel_data?.linkedIn?.genre || "",
            follower_subscriber:
              updateInfData.channel_data?.linkedIn?.follower_subscriber || ""
          },
          whatsapp_status: updateInfData.channel_data?.whatsapp_status || "",
          whatsapp: {
            language: updateInfData.channel_data?.whatsapp?.language || [],
            min_budget: updateInfData.channel_data?.whatsapp?.min_budget || "",
            max_budget: updateInfData.channel_data?.whatsapp?.max_budget || "",
            genre: updateInfData.channel_data?.whatsapp?.genre || "",
            follower_subscriber:
              updateInfData.channel_data?.whatsapp?.follower_subscriber || ""
          },
          telegram_status: updateInfData.channel_data?.telegram_status || "",
          telegram: {
            language: updateInfData.channel_data?.telegram?.language || [],
            min_budget: updateInfData.channel_data?.telegram?.min_budget || "",
            max_budget: updateInfData.channel_data?.telegram?.max_budget || "",
            genre: updateInfData.channel_data?.telegram?.genre || "",
            follower_subscriber:
              updateInfData.channel_data?.telegram?.follower_subscriber || ""
          },
          threads_status: updateInfData.channel_data?.threads_status || "",
          threads: {
            language: updateInfData.channel_data?.threads?.language || [],
            min_budget: updateInfData.channel_data?.linkedIn?.min_budget || "",
            max_budget: updateInfData.channel_data?.linkedIn?.max_budget || "",
            genre: updateInfData.channel_data?.threads?.genre || "",
            follower_subscriber:
              updateInfData.channel_data?.threads?.follower_subscriber || ""
          }
        }
      });
    }
  }, [updateInfData]);

  // Fetch update details when selectedData changes


  // const handleChange = (event) => {
  //   const { name, checked } = event.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     channel_data: {
  //       ...prevState.channel_data,
  //       [name]: checked
  //     }
  //   }));
  // };

  const handleChange = (event, fieldName) => {
    const { checked } = event.target;
    const value = checked ? "1" : "0";

    setFormData((prevState) => ({
      ...prevState,
      channel_data: {
        ...prevState.channel_data,
        [fieldName]: value
      }
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      let field = newFormData;
      const keys = name.split(".");
      keys.slice(0, -1).forEach((key) => {
        if (!field[key]) {
          field[key] = {};
        }
        field = field[key];
      });
      field[keys[keys.length - 1]] = value;
      return newFormData;
    });
  };

  const handleSelectChange = (name, selectedOption) => {
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      let field = newFormData;
      const keys = name.split(".");
      keys.slice(0, -1).forEach((key) => {
        if (!field[key]) {
          field[key] = {};
        }
        field = field[key];
      });
      field[keys[keys.length - 1]] = selectedOption;
      return newFormData;
    });
  };
  // Log the formData for debugging

  const closeModal = () => {
    setShowModal(false);
  };
  const handleUpdate = (event) => {
    event.preventDefault();
    setShowModal(true); // Show confirmation modal on button click
  };


  const handleConfirmUpdate = async (event) => {
    event.preventDefault();
    try {
      const influencerUpdateData = {
        inf_req_id: updateInfData?.id,
        client_id: decodedToken?.UsrId,
        product: formData.product,
        no_of_influencer: formData.no_of_influencer?.label,
        budget: formData.budget,
        channel_data: {
          youTube_status: formData.channel_data.youTube_status,
          youTube: formData.channel_data.youTube,
          facebook_status: formData.channel_data.facebook_status,
          facebook: formData.channel_data.facebook,
          instagram_status: formData.channel_data.instagram_status,
          instagram: formData.channel_data.instagram,
          twitter_X_status: formData.channel_data.twitter_X_status,
          twitter_X: formData.channel_data.twitter_X,
          linkedIn_status: formData.channel_data.linkedIn_status,
          linkedIn: formData.channel_data.linkedIn,
          whatsapp_status: formData.channel_data.whatsapp_status,
          whatsapp: formData.channel_data.whatsapp,
          telegram_status: formData.channel_data.telegram_status,
          telegram: formData.channel_data.telegram,
          threads_status: formData.channel_data.threads_status,
          threads: formData.channel_data.threads
        }
      }
      dispatch(clientUpdateInfluencerRequest(influencerUpdateData))
        .unwrap()
        .then((result) => {
          if (result.status) {
            toast.success(result.message, {
              onClose: () => {
              }
            });
            closeModal(
              navigate(`/client-all-request`)
            );
          } else {
            toast.error(result.message, {
              onClose: () => {
              }
            });
            closeModal()
            setErrorData(result.form_err);
          }
        })
        .catch((err) => {
          setLoginError(err.message);
          setErrorData(err.form_err || []);
          toast.error(err.message);
        });
      console.log("influencerUpdateData ==>", influencerUpdateData);

    } catch (error) {
      console.error("An error occurred:", error);
    }

  }
  const mapErrors = (errorData) => {
    const errorMap = {};
    errorData.forEach((error) => {
      if (!errorMap[error.param]) {
        errorMap[error.param] = error.msg;
      }
    });
    return errorMap;
  };
  useEffect(() => {
    const errorsValue = mapErrors(errorData);
    setErrors(errorsValue);
  }, [errorData]);

  useEffect(() => {
    console.log("FormData errorData :", errorData);
  }, [errorData]);

  return (
    <>

      <div className="row justify-content-center">
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="p-0">
                <div>
                  <>
                    <div className="row">
                      <div>
                        <h5 className="text-primary">Personal Details</h5>
                      </div>

                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="username" className="form-label">
                            Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="influencer_name"
                            readOnly={true}
                            disabled={true}
                            placeholder="Please Enter Name"
                            value={decodedToken?.UsrName}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label htmlFor="concernname" className="form-label">
                            Company name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="concern_name"
                            readOnly={true}
                            disabled={true}
                            placeholder="Please Enter Name"
                            value={decodedToken?.UsrCompName}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 mb-3">
                        <div>
                          <label htmlFor="product" className="form-label">
                            Product <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="product"
                            placeholder="Enter product name"
                            value={formData.product}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="text-danger">
                            {errors.product && <p>{errors.product}</p>}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="no_of_influencer"
                            className="form-label"
                          >
                            No of influencers required{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="no_of_influencer"
                            options={budgetData}
                            classNamePrefix="select"
                            onChange={(selected) =>
                              handleSelectChange("no_of_influencer", selected)
                            }
                            value={formData.no_of_influencer}
                          />
                          <div className="text-danger">
                            {errors.no_of_influencer && (
                              <p>{errors.no_of_influencer}</p>
                            )}
                          </div>
                        </div>
                      </div>


                    </div>
                  </>
                  <hr />

                  <div className="row mt-2 g-3">
                    <h5 className="text-primary">Channel details</h5>

                    <label>
                      Select Below Channels
                      {errors.channel_data && (
                        <p className="text-danger small error">
                          {errors.channel_data}
                        </p>
                      )}
                    </label>
                    <div className="form-check col-lg-3 mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="youTube_status"
                        name="youTube_status"
                        checked={formData.channel_data.youTube_status === "1"}
                        onChange={(event) =>
                          handleChange(event, "youTube_status")
                        }
                      />
                      <label className="form-check-label" for="youtubeCheckbox">
                        {" "}
                        YouTube
                      </label>
                    </div>
                    <div className="form-check col-lg-3 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="facebook_status"
                        checked={formData.channel_data.facebook_status === "1"}
                        onChange={(event) =>
                          handleChange(event, "facebook_status")
                        }
                      // checked={formData.channel_data.facebook_status}
                      // onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        for="facebookCheckbox"
                      >
                        {" "}
                        Facebook
                      </label>
                    </div>
                    <div className="form-check col-lg-3 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="instagram_status"
                        checked={formData.channel_data.instagram_status === "1"}
                        onChange={(event) =>
                          handleChange(event, "instagram_status")
                        }
                      // checked={formData.channel_data.instagram_status}
                      // onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        for="instagramCheckbox"
                      >
                        {" "}
                        Instagram
                      </label>
                    </div>
                    <div className="form-check col-lg-3 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="twitter_X_status"
                        checked={formData.channel_data.twitter_X_status === "1"}
                        onChange={(event) =>
                          handleChange(event, "twitter_X_status")
                        }
                      // checked={formData.channel_data.twitter_X_status}
                      // onChange={handleChange}
                      />
                      <label className="form-check-label" for="twitterCheckbox">
                        {" "}
                        Twitter (X)
                      </label>
                    </div>
                    <div className="form-check col-lg-3 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="linkedIn_status"
                        checked={formData.channel_data.linkedIn_status === "1"}
                        onChange={(event) =>
                          handleChange(event, "linkedIn_status")
                        }
                      // checked={formData.channel_data.linkedIn_status}
                      // onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        for="linkedinCheckbox"
                      >
                        {" "}
                        LinkedIn
                      </label>
                    </div>
                    <div className="form-check col-lg-3 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="whatsapp_status"
                        checked={formData.channel_data.whatsapp_status === "1"}
                        onChange={(event) =>
                          handleChange(event, "whatsapp_status")
                        }
                      // checked={formData.channel_data.whatsapp_status}
                      // onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        for="whatsappCheckbox"
                      >
                        {" "}
                        Whatsapp
                      </label>
                    </div>
                    <div className="form-check col-lg-3 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="telegram_status"
                        checked={formData.channel_data.telegram_status === "1"}
                        onChange={(event) =>
                          handleChange(event, "telegram_status")
                        }
                      // checked={formData.channel_data.telegram_status}
                      // onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        for="telegramCheckbox"
                      >
                        {" "}
                        Telegram
                      </label>
                    </div>
                    <div className="form-check col-lg-3 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="threads_status"
                        checked={formData.channel_data.threads_status === "1"}
                        onChange={(event) =>
                          handleChange(event, "threads_status")
                        }
                      // checked={formData.channel_data.threads_status}
                      // onChange={handleChange}
                      />
                      <label className="form-check-label" for="threadsCheckbox">
                        {" "}
                        Threads
                      </label>
                    </div>
                  </div>
                  {formData.channel_data.youTube_status === "1" && (
                    <div id="youtubeInfo">
                      <div className="row">
                        <div className="mt-2">
                          <h5 className="text-primary">You Tube</h5>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="youtubeUrl">
                            Select language
                            <span className="text-danger">*</span>:
                          </label>
                          <Select
                            isMulti={true}
                            name="channel_data.youTube.language"
                            options={langList?.data}
                            classNamePrefix="select"
                            value={formData.channel_data.youTube.language}
                            onChange={(selected) =>
                              handleSelectChange(
                                "channel_data.youTube.language",
                                selected
                              )
                            }
                          />

                          <div className="text-danger">
                            {errors.channel_data_youTube_language && (
                              <p>{errors.channel_data_youTube_language}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="ygenere">
                            Minimum budget
                            <span className="text-danger">*</span>:
                          </label>
                          <FormattedInput
                            type="text"
                            className="form-control"
                            name="channel_data.youTube.min_budget"
                            placeholder="Enter youTube minimum budget"
                            value={formData.channel_data.youTube.min_budget}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="text-danger">
                            {errors.channel_data_youTube_min_budget && (
                              <p>
                                {errors.channel_data_youTube_min_budget}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="ygenere">
                            Maximum budget
                            <span className="text-danger">*</span>:
                          </label>
                          <FormattedInput
                            type="text"
                            className="form-control"
                            name="channel_data.youTube.max_budget"
                            placeholder="Enter youTube maximum budget"
                            value={formData.channel_data.youTube.max_budget}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="text-danger">
                            {errors.channel_data_youTube_max_budget && (
                              <p>
                                {errors.channel_data_youTube_max_budget}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label htmlFor="fbgenere">
                              Genre<span className="text-danger">*</span>:
                            </label>
                            <Select
                              name="channel_data.youTube.genre"
                              options={genreList?.data?.map((genre) => ({
                                value: genre?.value,
                                label: genre?.label
                              }))}
                              classNamePrefix="select"
                              onChange={(selected) =>
                                handleSelectChange("channel_data.youTube.genre", selected)
                              }
                              value={formData?.channel_data?.youTube?.genre || ""}
                            />
                            <div className="text-danger">
                              {errors.channel_data_youTube_genre && (
                                <p>{errors.channel_data_youTube_genre}</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 mb-3">
                          <label for="subscribers">
                            Followers or subscriber requiremen
                            <span className="text-danger">*</span>:
                          </label>
                          <Select
                            name="channel_data.youTube.follower_subscriber"
                            options={follower_subscribers}
                            classNamePrefix="select"
                            onChange={(selected) =>
                              handleSelectChange(
                                "channel_data.youTube.follower_subscriber",
                                selected
                              )
                            }
                            value={
                              formData.channel_data.youTube.follower_subscriber
                            }
                          />
                          <div className="text-danger">
                            {errors.channel_data_youTube_follower_subscriber && (
                              <p>
                                {
                                  errors.channel_data_youTube_follower_subscriber
                                }
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="subscribers">
                            Video type
                            <span className="text-danger">*</span>:
                          </label>
                          <Select
                            isMulti
                            name="channel_data.youTube.video_type"
                            options={videoTypeData}
                            classNamePrefix="select"
                            onChange={(selected) =>
                              handleSelectChange(
                                "channel_data.youTube.video_type",
                                selected
                              )
                            }
                            value={formData.channel_data.youTube.video_type}
                          />
                          <div className="text-danger">
                            {errors.channel_data_youTube_video_type && (
                              <p>{errors.channel_data_youTube_video_type}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {formData.channel_data.facebook_status === "1" && (
                    <div id="facebookInfo">
                      <div className="row">
                        <div className="mt-2">
                          <h5 className="text-primary">Facebook</h5>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>
                            Select language
                            <span className="text-danger">*</span>:
                          </label>
                          <Select
                            isMulti={true}
                            name="channel_data.facebook.language"
                            options={langList?.data}
                            classNamePrefix="select"
                            value={formData.channel_data.facebook.language}
                            onChange={(selected) =>
                              handleSelectChange(
                                "channel_data.facebook.language",
                                selected
                              )
                            }
                          />
                          <div className="text-danger">
                            {errors.channel_data_facebook_language && (
                              <p>{errors.channel_data_facebook_language}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="ygenere">
                            Minimum budget
                            <span className="text-danger">*</span>:
                          </label>
                          <FormattedInput
                            type="text"
                            className="form-control"
                            name="channel_data.facebook.min_budget"
                            placeholder="Enter facebook minimum budget"
                            value={formData.channel_data.facebook.min_budget}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="text-danger">
                            {errors.channel_data_facebook_min_budget && (
                              <p>
                                {errors.channel_data_facebook_min_budget}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="ygenere">
                            Maximum budget
                            <span className="text-danger">*</span>:
                          </label>
                          <FormattedInput
                            type="text"
                            className="form-control"
                            name="channel_data.facebook.max_budget"
                            placeholder="Enter facebook maximum budget"
                            value={formData.channel_data.facebook.max_budget}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="text-danger">
                            {errors.channel_data_facebook_max_budget && (
                              <p>
                                {errors.channel_data_facebook_max_budget}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label htmlFor="fbgenere">
                              Genre<span className="text-danger">*</span>:
                            </label>
                            <Select
                              name="channel_data.facebook.genre"
                              options={genreList?.data?.map((genre) => ({
                                value: genre?.value,
                                label: genre?.label
                              }))}
                              classNamePrefix="select"
                              onChange={(selected) =>
                                handleSelectChange("channel_data.facebook.genre", selected)
                              }
                              value={formData?.channel_data?.facebook?.genre || ""}
                            />
                            <div className="text-danger">
                              {errors.channel_data_facebook_genre && (
                                <p>{errors.channel_data_facebook_genre}</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 mb-3">
                          <label for="subscribers">
                            Followers or subscriber requiremen
                            <span className="text-danger">*</span>:
                          </label>
                          <Select
                            name="channel_data.facebook.follower_subscriber"
                            options={follower_subscribers}
                            classNamePrefix="select"
                            onChange={(selected) =>
                              handleSelectChange(
                                "channel_data.facebook.follower_subscriber",
                                selected
                              )
                            }
                            value={
                              formData.channel_data.facebook.follower_subscriber
                            }
                          />
                          <div className="text-danger">
                            {errors.channel_data_facebook_follower_subscriber && (
                              <p>
                                {
                                  errors.channel_data_facebook_follower_subscriber
                                }
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {formData.channel_data.instagram_status === "1" && (
                    <div id="facebookInfo">
                      <div className="row">
                        <div className="mt-2">
                          <h5 className="text-primary">Instagram</h5>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>
                            Select language
                            <span className="text-danger">*</span>:
                          </label>
                          <Select
                            isMulti={true}
                            name="channel_data.instagram.language"
                            options={langList?.data}
                            classNamePrefix="select"
                            value={formData.channel_data.instagram.language}
                            onChange={(selected) =>
                              handleSelectChange(
                                "channel_data.instagram.language",
                                selected
                              )
                            }
                          />
                          <div className="text-danger">
                            {errors.channel_data_instagram_language && (
                              <p>{errors.channel_data_instagram_language}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="ygenere">
                            Minimum budget
                            <span className="text-danger">*</span>:
                          </label>
                          <FormattedInput
                            type="text"
                            className="form-control"
                            name="channel_data.instagram.min_budget"
                            placeholder="Enter instagram minimum budget"
                            value={formData.channel_data.instagram.min_budget}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="text-danger">
                            {errors.channel_data_instagram_min_budget && (
                              <p>
                                {errors.channel_data_instagram_min_budget}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="ygenere">
                            Maximum budget
                            <span className="text-danger">*</span>:
                          </label>
                          <FormattedInput
                            type="text"
                            className="form-control"
                            name="channel_data.instagram.max_budget"
                            placeholder="Enter instagram maximum budget"
                            value={formData.channel_data.instagram.max_budget}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="text-danger">
                            {errors.channel_data_instagram_max_budget && (
                              <p>
                                {errors.channel_data_instagram_max_budget}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label htmlFor="fbgenere">
                              Genre<span className="text-danger">*</span>:
                            </label>
                            <Select
                              name="channel_data.facebook.genre"
                              options={genreList?.data?.map((genre) => ({
                                value: genre?.value,
                                label: genre?.label
                              }))}
                              classNamePrefix="select"
                              onChange={(selected) =>
                                handleSelectChange("channel_data.facebook.genre", selected)
                              }
                              value={formData?.channel_data?.facebook?.genre || ""}
                            />
                            <div className="text-danger">
                              {errors.channel_data_facebook_genre && (
                                <p>{errors.channel_data_facebook_genre}</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 mb-3">
                          <label for="subscribers">
                            Followers or subscriber requiremen
                            <span className="text-danger">*</span>:
                          </label>
                          <Select
                            name="channel_data.instagram.follower_subscriber"
                            options={follower_subscribers}
                            classNamePrefix="select"
                            onChange={(selected) =>
                              handleSelectChange(
                                "channel_data.instagram.follower_subscriber",
                                selected
                              )
                            }
                            value={
                              formData.channel_data.instagram
                                .follower_subscriber
                            }
                          />
                          <div className="text-danger">
                            {errors.channel_data_instagram_follower_subscriber && (
                              <p>
                                {
                                  errors.channel_data_instagram_follower_subscriber
                                }
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {formData.channel_data.twitter_X_status === "1" && (
                    <div id="twitter">
                      <div className="row">
                        <div className="mt-2">
                          <h5 className="text-primary">Twitter </h5>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>
                            Select language
                            <span className="text-danger">*</span>:
                          </label>
                          <Select
                            isMulti={true}
                            name="channel_data.twitter_X.language"
                            options={langList?.data}
                            classNamePrefix="select"
                            value={formData.channel_data.twitter_X.language}
                            onChange={(selected) =>
                              handleSelectChange(
                                "channel_data.twitter_X.language",
                                selected
                              )
                            }
                          />
                          <div className="text-danger">
                            {errors.channel_data_twitter_X_language && (
                              <p>{errors.channel_data_twitter_X_language}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-4 mb-3">
                          <label for="ygenere">
                            Minimum budget
                            <span className="text-danger">*</span>:
                          </label>
                          <FormattedInput
                            type="text"
                            className="form-control"
                            name="channel_data.twitter_X.min_budget"
                            placeholder="Enter twitter_X minimum budget"
                            value={formData.channel_data.twitter_X.min_budget}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="text-danger">
                            {errors.channel_data_twitter_X_min_budget && (
                              <p>
                                {errors.channel_data_twitter_X_min_budget}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="ygenere">
                            Maximum budget
                            <span className="text-danger">*</span>:
                          </label>
                          <FormattedInput
                            type="text"
                            className="form-control"
                            name="channel_data.twitter_X.max_budget"
                            placeholder="Enter twitter_X maximum budget"
                            value={formData.channel_data.twitter_X.max_budget}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="text-danger">
                            {errors.channel_data_twitter_X_max_budget && (
                              <p>
                                {errors.channel_data_twitter_X_max_budget}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label htmlFor="fbgenere">
                              Genre<span className="text-danger">*</span>:
                            </label>
                            <Select
                              name="channel_data.twitter_X.genre"
                              options={genreList?.data?.map((genre) => ({
                                value: genre?.value,
                                label: genre?.label
                              }))}
                              classNamePrefix="select"
                              onChange={(selected) =>
                                handleSelectChange("channel_data.twitter_X.genre", selected)
                              }
                              value={formData?.channel_data?.twitter_X?.genre || ""}
                            />
                            <div className="text-danger">
                              {errors.channel_data_twitter_X_genre && (
                                <p>{errors.channel_data_twitter_X_genre}</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 mb-3">
                          <label for="subscribers">
                            Followers or subscriber requiremen
                            <span className="text-danger">*</span>:
                          </label>
                          <Select
                            name="channel_data.twitter_X.follower_subscriber"
                            options={follower_subscribers}
                            classNamePrefix="select"
                            onChange={(selected) =>
                              handleSelectChange(
                                "channel_data.twitter_X.follower_subscriber",
                                selected
                              )
                            }
                            value={
                              formData.channel_data.twitter_X
                                .follower_subscriber
                            }
                          />
                          <div className="text-danger">
                            {errors.channel_data_twitter_X_follower_subscriber && (
                              <p>
                                {
                                  errors.channel_data_twitter_X_follower_subscriber
                                }
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {formData.channel_data.linkedIn_status === "1" && (
                    <div id="facebookInfo">
                      <div className="row">
                        <div className="mt-2">
                          <h5 className="text-primary">LinkedIn</h5>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>
                            Select language
                            <span className="text-danger">*</span>:
                          </label>
                          <Select
                            isMulti={true}
                            name="channel_data.linkedIn.language"
                            options={langList?.data}
                            classNamePrefix="select"
                            value={formData.channel_data.linkedIn.language}
                            onChange={(selected) =>
                              handleSelectChange(
                                "channel_data.linkedIn.language",
                                selected
                              )
                            }
                          />
                          <div className="text-danger">
                            {errors.channel_data_linkedIn_language && (
                              <p>{errors.channel_data_linkedIn_language}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="ygenere">
                            Minimum budget
                            <span className="text-danger">*</span>:
                          </label>
                          <FormattedInput
                            type="text"
                            className="form-control"
                            name="channel_data.linkedIn.min_budget"
                            placeholder="Enter linkedIn minimum budget"
                            value={formData.channel_data.linkedIn.min_budget}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="text-danger">
                            {errors.channel_data_linkedIn_min_budget && (
                              <p>
                                {errors.channel_data_linkedIn_min_budget}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="ygenere">
                            Maximum budget
                            <span className="text-danger">*</span>:
                          </label>
                          <FormattedInput
                            type="text"
                            className="form-control"
                            name="channel_data.linkedIn.max_budget"
                            placeholder="Enter linkedIn maximum budget"
                            value={formData.channel_data.linkedIn.max_budget}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="text-danger">
                            {errors.channel_data_linkedIn_max_budget && (
                              <p>
                                {errors.channel_data_linkedIn_max_budget}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label htmlFor="fbgenere">
                              Genre<span className="text-danger">*</span>:
                            </label>
                            <Select
                              name="channel_data.linkedIn.genre"
                              options={genreList?.data?.map((genre) => ({
                                value: genre?.value,
                                label: genre?.label
                              }))}
                              classNamePrefix="select"
                              onChange={(selected) =>
                                handleSelectChange("channel_data.linkedIn.genre", selected)
                              }
                              value={formData?.channel_data?.linkedIn?.genre || ""}
                            />
                            <div className="text-danger">
                              {errors.channel_data_linkedIn_genre && (
                                <p>{errors.channel_data_linkedIn_genre}</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 mb-3">
                          <label for="subscribers">
                            Followers or subscriber requiremen
                            <span className="text-danger">*</span>:
                          </label>
                          <Select
                            name="channel_data.linkedIn.follower_subscriber"
                            options={follower_subscribers}
                            classNamePrefix="select"
                            onChange={(selected) =>
                              handleSelectChange(
                                "channel_data.linkedIn.follower_subscriber",
                                selected
                              )
                            }
                            value={
                              formData.channel_data.linkedIn.follower_subscriber
                            }
                          />
                          <div className="text-danger">
                            {errors.channel_data_linkedIn_follower_subscriber && (
                              <p>
                                {
                                  errors.channel_data_linkedIn_follower_subscriber
                                }
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {formData.channel_data.whatsapp_status === "1" && (
                    <div id="facebookInfo">
                      <div className="row">
                        <div className="mt-2">
                          <h5 className="text-primary">Whatsapp</h5>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>
                            Select language
                            <span className="text-danger">*</span>:
                          </label>
                          <Select
                            isMulti={true}
                            name="channel_data.whatsapp.language"
                            options={langList?.data}
                            classNamePrefix="select"
                            value={formData.channel_data.whatsapp.language}
                            onChange={(selected) =>
                              handleSelectChange(
                                "channel_data.whatsapp.language",
                                selected
                              )
                            }
                          />
                          <div className="text-danger">
                            {errors.channel_data_whatsapp_language && (
                              <p>{errors.channel_data_whatsapp_language}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="ygenere">
                            Minimum budget
                            <span className="text-danger">*</span>:
                          </label>
                          <FormattedInput
                            type="text"
                            className="form-control"
                            name="channel_data.whatsapp.min_budget"
                            placeholder="Enter whatsapp minimum budget"
                            value={formData.channel_data.whatsapp.min_budget}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="text-danger">
                            {errors.channel_data_whatsapp_min_budget && (
                              <p>
                                {errors.channel_data_whatsapp_min_budget}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="ygenere">
                            Maximum budget
                            <span className="text-danger">*</span>:
                          </label>
                          <FormattedInput
                            type="text"
                            className="form-control"
                            name="channel_data.whatsapp.max_budget"
                            placeholder="Enter whatsapp maximum budget"
                            value={formData.channel_data.whatsapp.max_budget}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="text-danger">
                            {errors.channel_data_whatsapp_max_budget && (
                              <p>
                                {errors.channel_data_whatsapp_max_budget}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label htmlFor="fbgenere">
                              Genre<span className="text-danger">*</span>:
                            </label>
                            <Select
                              name="channel_data.whatsapp.genre"
                              options={genreList?.data?.map((genre) => ({
                                value: genre?.value,
                                label: genre?.label
                              }))}
                              classNamePrefix="select"
                              onChange={(selected) =>
                                handleSelectChange("channel_data.whatsapp.genre", selected)
                              }
                              value={formData?.channel_data?.whatsapp?.genre || ""}
                            />
                            <div className="text-danger">
                              {errors.channel_data_whatsapp_genre && (
                                <p>{errors.channel_data_whatsapp_genre}</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 mb-3">
                          <label for="subscribers">
                            Followers or subscriber requiremen
                            <span className="text-danger">*</span>:
                          </label>
                          <Select
                            name="channel_data.whatsapp.follower_subscriber"
                            options={follower_subscribers}
                            classNamePrefix="select"
                            onChange={(selected) =>
                              handleSelectChange(
                                "channel_data.whatsapp.follower_subscriber",
                                selected
                              )
                            }
                            value={
                              formData.channel_data.whatsapp.follower_subscriber
                            }
                          />
                          <div className="text-danger">
                            {errors.channel_data_whatsapp_follower_subscriber && (
                              <p>
                                {
                                  errors.channel_data_whatsapp_follower_subscriber
                                }
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {formData.channel_data.telegram_status === "1" && (
                    <div id="facebookInfo">
                      <div className="row">
                        <div className="mt-2">
                          <h5 className="text-primary">Telegram</h5>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>
                            Select language
                            <span className="text-danger">*</span>:
                          </label>
                          <Select
                            isMulti={true}
                            name="channel_data.telegram.language"
                            options={langList?.data}
                            classNamePrefix="select"
                            value={formData.channel_data.telegram.language}
                            onChange={(selected) =>
                              handleSelectChange(
                                "channel_data.telegram.language",
                                selected
                              )
                            }
                          />
                          <div className="text-danger">
                            {errors.channel_data_telegram_language && (
                              <p>{errors.channel_data_telegram_language}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="ygenere">
                            Minimum budget
                            <span className="text-danger">*</span>:
                          </label>
                          <FormattedInput
                            type="text"
                            className="form-control"
                            name="channel_data.telegram.min_budget"
                            placeholder="Enter telegram minimum budget"
                            value={formData.channel_data.telegram.min_budget}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="text-danger">
                            {errors.channel_data_telegram_min_budget && (
                              <p>
                                {errors.channel_data_telegram_min_budget}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="ygenere">
                            Maximum budget
                            <span className="text-danger">*</span>:
                          </label>
                          <FormattedInput
                            type="text"
                            className="form-control"
                            name="channel_data.telegram.max_budget"
                            placeholder="Enter telegram maximum budget"
                            value={formData.channel_data.telegram.max_budget}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="text-danger">
                            {errors.channel_data_telegram_max_budget && (
                              <p>
                                {errors.channel_data_telegram_max_budget}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label htmlFor="fbgenere">
                              Genre<span className="text-danger">*</span>:
                            </label>
                            <Select
                              name="channel_data.telegram.genre"
                              options={genreList?.data?.map((genre) => ({
                                value: genre?.value,
                                label: genre?.label
                              }))}
                              classNamePrefix="select"
                              onChange={(selected) =>
                                handleSelectChange("channel_data.telegram.genre", selected)
                              }
                              value={formData?.channel_data?.telegram?.genre || ""}
                            />
                            <div className="text-danger">
                              {errors.channel_data_telegram_genre && (
                                <p>{errors.channel_data_telegram_genre}</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 mb-3">
                          <label for="subscribers">
                            Followers or subscriber requiremen
                            <span className="text-danger">*</span>:
                          </label>
                          <Select
                            name="channel_data.telegram.follower_subscriber"
                            options={follower_subscribers}
                            classNamePrefix="select"
                            onChange={(selected) =>
                              handleSelectChange(
                                "channel_data.telegram.follower_subscriber",
                                selected
                              )
                            }
                            value={
                              formData.channel_data.telegram.follower_subscriber
                            }
                          />
                          <div className="text-danger">
                            {errors.channel_data_telegram_follower_subscriber && (
                              <p>
                                {
                                  errors.channel_data_telegram_follower_subscriber
                                }
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {formData.channel_data.threads_status === "1" && (
                    <div id="facebookInfo">
                      <div className="row">
                        <div className="mt-2">
                          <h5 className="text-primary">Threads</h5>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>
                            Select language
                            <span className="text-danger">*</span>:
                          </label>
                          <Select
                            isMulti={true}
                            name="channel_data.threads.language"
                            options={langList?.data}
                            classNamePrefix="select"
                            value={formData.channel_data.threads.language}
                            onChange={(selected) =>
                              handleSelectChange(
                                "channel_data.threads.language",
                                selected
                              )
                            }
                          />
                          <div className="text-danger">
                            {errors.channel_data_threads_language && (
                              <p>{errors.channel_data_threads_language}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="ygenere">
                            Minimum budget
                            <span className="text-danger">*</span>:
                          </label>
                          <FormattedInput
                            type="text"
                            className="form-control"
                            name="channel_data.threads.min_budget"
                            placeholder="Enter threads minimum budget"
                            value={formData.channel_data.threads.min_budget}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="text-danger">
                            {errors.channel_data_threads_min_budget && (
                              <p>
                                {errors.channel_data_threads_min_budget}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="ygenere">
                            Maximum budget
                            <span className="text-danger">*</span>:
                          </label>
                          <FormattedInput
                            type="text"
                            className="form-control"
                            name="channel_data.threads.max_budget"
                            placeholder="Enter threads maximum budget"
                            value={formData.channel_data.threads.max_budget}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="text-danger">
                            {errors.channel_data_threads_max_budget && (
                              <p>
                                {errors.channel_data_threads_max_budget}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label htmlFor="fbgenere">
                              Genre<span className="text-danger">*</span>:
                            </label>
                            <Select
                              name="channel_data.threads.genre"
                              options={genreList?.data?.map((genre) => ({
                                value: genre?.value,
                                label: genre?.label
                              }))}
                              classNamePrefix="select"
                              onChange={(selected) =>
                                handleSelectChange("channel_data.threads.genre", selected)
                              }
                              value={formData?.channel_data?.threads?.genre || ""}
                            />
                            <div className="text-danger">
                              {errors.channel_data_threads_genre && (
                                <p>{errors.channel_data_threads_genre}</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 mb-3">
                          <label for="subscribers">
                            Followers or subscriber requiremen
                            <span className="text-danger">*</span>:
                          </label>
                          <Select
                            name="channel_data.threads.follower_subscriber"
                            options={follower_subscribers}
                            classNamePrefix="select"
                            onChange={(selected) =>
                              handleSelectChange(
                                "channel_data.threads.follower_subscriber",
                                selected
                              )
                            }
                            value={
                              formData.channel_data.threads.follower_subscriber
                            }
                          />
                          <div className="text-danger">
                            {errors.channel_data_threads_follower_subscriber && (
                              <p>
                                {
                                  errors.channel_data_threads_follower_subscriber
                                }
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="d-flex justify-content-end">
                <Button type="button" variant="primary" onClick={handleUpdate} >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to update the influencer details?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            No
          </Button>
          <Button variant="primary" onClick={handleConfirmUpdate}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ApprovedUpdateInfluencers;
