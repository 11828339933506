/** @format */

import React, { useEffect, useReducer, useState } from "react";
import Select from "react-select";
import BackgroundImg from "../../layout/BackgroundImg";
import Logo from "../../layout/Logo";
// import imgLogo from './assets/images/logo-light.png';
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signUp } from "../../features/auth/authSlice";
import {
  fetchCategoryData,
  fetchLanguageData
} from "../../features/commonSlice/commonSlice";

const budgetData = [
  { label: "0-5Lacs", value: 1 },
  { label: "5-10Lacs", value: 2 },
  { label: "10-15Lacs", value: 3 },
  { label: "15-20Lacs", value: 4 },
  { label: "20-50Lacs", value: 5 },
  { label: "Above 50Lacs", value: 6 }
];

const ClientSignUp = () => {
  const [formData, setFormData] = useState({
    company_name: "",
    concern_person_name: "",
    mobile_no: "",
    email: "",
    password: "",
    confirmPassword: "",
    category: [],
    languages: [],
    budget: "",
    referenceType: "",
    referenceData: ""
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    categoryData,
    languageData,
    status: commonStatus
  } = useSelector((state) => state.influencer);
  const [langList, setLangList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [errors, setErrors] = useState({});

  const [errorData, setErrorData] = useState([]);
  const [loginError, setLoginError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');


  const location = useLocation();
  const urlSegment = location.pathname;
  const urlParts = location.pathname.split("/")[3];
  const urlParts1 = location.pathname.split("/")[2];


  const { id } = useParams();

  useEffect(() => {
    if (urlSegment === '/client-sign-up') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        referenceType: "direct",
        referenceData: ""
      }));
    } else if (urlSegment === '/client-sign-up/website') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        referenceType: "website",
        referenceData: ""
      }));
    } else if (id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        referenceType: "Refer by Sales",  // This will be the referral id
        referenceData: urlParts   // This could be used for any reference data
      }));
    }
  }, [location, urlParts, id]);

  useEffect(() => {
    console.log("dfsfsdfs sdfdsfsd ==>", urlSegment, urlParts, id);

  }, [urlSegment, urlParts, id])

  useEffect(() => {
    dispatch(fetchCategoryData());
    dispatch(fetchLanguageData());
  }, []);

  useEffect(() => {
    if (commonStatus === "succeeded") {
      setCategoryList(categoryData);
      setLangList(languageData);
    }
  }, [commonStatus, categoryData, languageData]);



  const handleChange = (e) => {
    const { name, value } = e.target;

    // Clear the specific error when the user starts typing or interacting
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear any existing error for this field
    }));

    // Handle phoneNo input to allow only numbers
    if (name === "mobile_no") {
      if (value === "" || /^[0-9]{0,10}$/.test(value)) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value
      }));
    }

    // Check if passwords match for both password and confirmPassword
    if (name === "password" || name === "confirmPassword") {
      if (formData.password !== value && name === "confirmPassword") {
        setPasswordError("Passwords do not match");
      } else if (formData.password === value || name === "password") {
        setPasswordError('');
      }
    }
  };


  const handleSelectChange = (name, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error for the specific select field
    }));
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      const clientData = {
        company_name: formData.company_name,
        concern_person_name: formData.concern_person_name,
        mobile_no: formData.mobile_no,
        email: formData.email,
        password: formData.password,
        confirm_password: formData.confirmPassword,
        category: formData.category,
        language: formData.languages,
        budget: formData.budget.label,
        referenceType: formData.referenceType,
        referenceData: formData.referenceData
      };

      dispatch(signUp(clientData))
        .unwrap()
        .then((result) => {
          if (result.status) {
            toast.success(result.message, {
              onClose: () => {
                navigate("/");
              }
            });
            setFormData({
              company_name: "",
              concern_person_name: "",
              mobile_no: "",
              email: "",
              password: "",
              confirmPassword: "",
              category: [],
              languages: [],
              budget: "",
              referenceType: "",
              referenceData: ""
            });
          } else {
            setLoginError(result.message);
            setErrorData(result.form_err);
          }
        })
        .catch((err) => {
          setLoginError(err.message);
          setErrorData(err.form_err || []);
          toast.error(err.message);
        });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const mapErrors = (errorData) => {
    const errorMap = {};
    errorData.forEach((error) => {
      if (!errorMap[error.param]) {
        errorMap[error.param] = error.msg;
      }
    });
    return errorMap;
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    const errorsValue = mapErrors(errorData);
    setErrors(errorsValue);
  }, [errorData]);

  return (
    <>
      <div className="auth-page-wrapper pt-5">
        <BackgroundImg />
        <div className="auth-page-content">
          <div className="container">
            <h4>Hello world</h4>
            {urlSegment === '/client-sign-up' ? <Logo />
              :
              urlSegment === '/client-sign-up/website' ?
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="text-center mt-sm-5 mb-4 text-white-50">
                        <div>
                          <Link className="d-inline-block auth-logo" to="/">
                            <h2 style={{ color: "#fff" }}><strong>Influencer Act</strong></h2>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                id && <>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="text-center mt-sm-5 mb-4 text-white-50">
                        <div>
                          <Link className="d-inline-block auth-logo" to="/">
                            <h2 style={{ color: "#fff" }}><strong>Influencer Act</strong></h2>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
            }

            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="card mt-4">
                  <div className="card-body p-4">
                    <div className="p-2 mt-4">
                      <form
                        className="needs-validation"
                        onSubmit={handleSubmit}
                        noValidate
                      >
                        <div className="row">
                          <div className="mt-2">
                            <h5 className="text-primary">Client Details</h5>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="companyname"
                                className="form-label"
                              >
                                Company Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="company_name"
                                placeholder="Please Enter Company Name"
                                value={formData.company_name}
                                onChange={handleChange}
                              />
                              <div className="text-danger">
                                {errors.company_name && <>{errors.company_name}</>}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="concernname"
                                className="form-label"
                              >
                                Concern Person Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="concern_person_name"
                                placeholder="Enter Concern Person Name"
                                value={formData.concern_person_name}
                                onChange={handleChange}
                              />
                              <div className="text-danger">
                                {" "}
                                {errors.concern_person_name && (
                                  <> {errors.concern_person_name}</>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="phoneno" className="form-label">
                                Phone no <span className="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="mobile_no"
                                placeholder="Enter Phone no"
                                value={formData.mobile_no}
                                onChange={handleChange}
                                maxLength={10}
                              />
                              <div className="text-danger">
                                {" "}
                                {errors.mobile_no && <> {errors.mobile_no}</>}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="useremail" className="form-label">
                                Email <span className="text-danger">*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Enter email address"
                                value={formData.email}
                                onChange={handleChange}
                              />
                              <div className="text-danger">
                                {" "}
                                {errors.email && <> {errors.email}</>}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="password" className="form-label">
                                Enter Password <span className="text-danger">*</span>
                              </label>
                              <input
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                name="password"
                                placeholder="Enter password"
                                value={formData.password}
                                onChange={handleChange}
                              />
                              <button
                                className="btn btn-link position-absolute end-0   text-decoration-none text-muted password-addon"
                                type="button" style={{ top: "28.05px" }}
                                onClick={handleTogglePassword}
                              >
                                <i
                                  className={`ri-${showPassword ? "eye-fill" : "eye-off-fill"
                                    } align-middle`}
                                ></i>
                              </button>
                              <div className="text-danger"> {errors.password && <> {errors.password}</>}</div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="confirmPassword" className="form-label">
                                Enter Confirm Password <span className="text-danger">*</span>
                              </label>
                              <input
                                type={showConfirmPassword ? "text" : "password"}
                                className="form-control"
                                name="confirmPassword"
                                placeholder="Enter Confirm password"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                              />
                              <button
                                className="btn btn-link position-absolute end-0 text-decoration-none text-muted password-addon"
                                type="button"
                                style={{ top: "28.05px" }}
                                onClick={handleToggleConfirmPassword}
                              >
                                <i
                                  className={`ri-${showConfirmPassword ? "eye-fill" : "eye-off-fill"
                                    } align-middle`}
                                ></i>
                              </button>
                              <div className="text-danger">
                                {passwordError && <>{passwordError}</>}
                                {errors.confirm_password && <> {errors.confirm_password}</>}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="categories-select"
                                className="form-label mb-2"
                              >
                                Categories{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                isMulti
                                name="category"
                                options={categoryList?.data}
                                classNamePrefix="select"
                                onChange={(selected) =>
                                  handleSelectChange("category", selected)
                                }
                                value={formData.category}
                              />
                              <div className="text-danger">
                                {" "}
                                {errors.category && <> {errors.category}</>}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="language-select"
                                className="form-label"
                              >
                                Select Language
                              </label>
                              <Select
                                isMulti
                                name="languages"
                                options={langList?.data}
                                classNamePrefix="select"
                                onChange={(selected) =>
                                  handleSelectChange("languages", selected)
                                }
                                value={formData.languages}
                              />
                              <div className="text-danger">
                                {" "}
                                {errors.language && <> {errors.language}</>}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="budget-select"
                                className="form-label"
                              >
                                Select Budget{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                name="budget"
                                options={budgetData}
                                classNamePrefix="select"
                                onChange={(selected) =>
                                  handleSelectChange("budget", selected)
                                }
                                value={formData.budget}
                              />
                              <div className="text-danger">
                                {" "}
                                {errors.budget && <> {errors.budget}</>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-4">
                          <p className="mb-0 fs-12 text-muted fst-italic">
                            By registering you agree to the Influencer Act{" "}
                            <a
                              href="#"
                              className="text-primary text-decoration-underline fst-normal fw-medium"
                            >
                              Terms of Use
                            </a>
                          </p>
                        </div>
                        <p className="text-danger text-center">
                          {!errorData.length > 0 && loginError}
                        </p>
                        <div className="mt-4">
                          <button
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            Sign Up
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientSignUp;
