import React, { useEffect, useState } from "react";
import Select from "react-select";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { sendDmexeClientRequest, fetchDmTeam } from "../../features/commonSlice/commonSlice";

const AssigntoDm = (props) => {
    const { showModal, setShowModal, selectedData } = props;
    const { salesData, status } = useSelector((state) => state.influencer);

    const [formData, setFormData] = useState({
        salesteam: [], // This will now hold an array of selected sales teams
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [salesList, setSalesList] = useState([]);
    const [errors, setErrors] = useState({});
    const [errorData, setErrorData] = useState([]);
    const [loginError, setLoginError] = useState('');

    // Fetch sales team data only when status is 'idle' and not already fetching
    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchDmTeam()); // Fetch sales data only if not already fetching
        }
    }, [dispatch, status, showModal]);

    // Update sales list once data is fetched successfully
    useEffect(() => {
        if (status === 'succeeded') {
            setSalesList(salesData?.data || []); // Update sales list once data is fetched
        }
    }, [status, salesData]);

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        try {
            const salesTeamData = {
                inf_req_id: selectedData?.id,
                assigned_to: formData.salesteam, // Map to only the values of selected options
            };
            dispatch(sendDmexeClientRequest(salesTeamData))
                .unwrap()
                .then((result) => {
                    if (result.status) {
                        toast.success(result.message, {
                            onClose: () => {
                                setShowModal(false)
                            },
                        });
                    } else {
                        setLoginError(result.message);
                        setErrorData(result.form_err);
                    }
                })
                .catch((err) => {
                    setLoginError(err.message);
                    setErrorData(err.form_err || []);
                    toast.error(err.message);
                });
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    // Handle select change for salesteam
    const handleSelectChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Map error data to error messages
    const mapErrors = (errorData) => {
        const errorMap = {};
        errorData.forEach((error) => {
            if (!errorMap[error.param]) {
                errorMap[error.param] = error.msg;
            }
        });
        return errorMap;
    };

    useEffect(() => {
        const errorsValue = mapErrors(errorData);
        setErrors(errorsValue);
    }, [errorData]);

    // Close modal
    const closeModal = () => {
        setShowModal(false);
    };

    // Log selectedData on change (for debugging)
    useEffect(() => {
        console.log("Selected Data:", selectedData);
    }, [selectedData]);

    return (
        <>
            <Modal
                show={showModal}
                onHide={closeModal}
                aria-labelledby="ModalHeader"
                size="lg"
                backdrop="static" // Prevents closing on backdrop click
                keyboard={false} // Prevents closing on ESC key press
            >
                <Modal.Header closeButton >
                    <Modal.Title>Send Request to Digital Marketing Team</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="p-0">
                                        <table className="table table-striped table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td className="w-25 p-3">  Client Name  </td>
                                                    <td>{selectedData?.client_name} </td>
                                                </tr>
                                                <tr>
                                                    <td className="w-25 p-3">  Product  </td>
                                                    <td>{selectedData?.product} </td>
                                                </tr>
                                                <tr>
                                                    <td className="w-25 p-3">  Request Id  </td>
                                                    <td>{selectedData?.req_code} </td>
                                                </tr>
                                                <tr>
                                                    <td className="w-25 p-3">DM Teams:</td>
                                                    <td >
                                                        <Select
                                                            name="salesteam"
                                                            options={salesList}
                                                            isMulti  // Enable multi-selection
                                                            classNamePrefix="select"
                                                            onChange={(selected) => handleSelectChange("salesteam", selected)} // Updated to handle multiple selections
                                                            value={formData.salesteam}  // Value is the array of selected options
                                                        />
                                                        <div className="text-danger"> {errors.salesteam && <> {errors.salesteam}</>}</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p className='text-danger text-center'>{!errorData.length > 0 && loginError}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="primary" onClick={handleSubmit}>
                        Confirm to Send Request
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AssigntoDm;
